import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const ACTIVATE_STAFF_API_ID = 'ACTIVATE_STAFF';

const {
  actionCreator: activateStaffAC,
  dataSelector: activateStaffDataSelector,
  errorSelector: activateStaffErrorSelector,
  isFetchingSelector: isActivatingStaffSelector,
} = makeFetchAction(ACTIVATE_STAFF_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/staffs/${id}/activate`,
  method: 'PATCH',
}));

export {
  ACTIVATE_STAFF_API_ID,
  activateStaffAC,
  activateStaffDataSelector,
  activateStaffErrorSelector,
  isActivatingStaffSelector,
};
