import { makeFetchAction } from 'redux-api-call';
import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_POST_API_ID = 'CREATE_POST';

const {
  actionCreator: createPostAC,
  dataSelector: createdPostRawDataSelector,
  errorSelector: createPostErrorSelector,
  isFetchingSelector: isCreatingPostSelector,
} = makeFetchAction(CREATE_POST_API_ID, post => ({
  endpoint: `${EMARKET_ADMIN_API}/posts`,
  method: 'POST',
  notify: true,
  body: post,
}));

const createdPostDataSelector = createSelector(
  createdPostRawDataSelector,
  get('post')
);

export {
  CREATE_POST_API_ID,
  createPostAC,
  createdPostDataSelector,
  createPostErrorSelector,
  isCreatingPostSelector,
};
