import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 'credit', label: 'Credit' },
  { value: 'payoff', label: 'Credit Return' },
];

const CreditRecordTypeSelect = props => (
  <SelectField placeholder="Select record type" {...props} options={options} />
);

export default CreditRecordTypeSelect;
