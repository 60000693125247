import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const CREATE_CREDIT_TRANSACTION_API_ID = 'CREATE_CREDIT_TRANSACTION';

const {
  actionCreator: createCreditTransactionAC,
  dataSelector: createCreditTransactionDataSelector,
  errorSelector: createCreditTransactionErrorSelector,
  isFetchingSelector: isCreatingTransactionSelector,
} = makeFetchAction(CREATE_CREDIT_TRANSACTION_API_ID, params => ({
  endpoint: `${API_ADMIN_ENDPOINT}/credits`,
  method: 'POST',
  notify: true,
  body: params,
}));

export {
  CREATE_CREDIT_TRANSACTION_API_ID,
  createCreditTransactionAC,
  createCreditTransactionDataSelector,
  createCreditTransactionErrorSelector,
  isCreatingTransactionSelector,
};
