import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_WITHDRAWAL_API_ID = 'UPDATE_WITHDRAWAL';

const {
  actionCreator: updateWithdrawalAC,
  dataSelector: updateWithdrawalDataSelector,
  errorSelector: updateWithdrawalErrorSelector,
  isFetchingSelector: isUpdatingWithdrawalSelector,
} = makeFetchAction(UPDATE_WITHDRAWAL_API_ID, (id, withdrawal) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/withdrawals/${id}`,
  method: 'PATCH',
  notify: true,
  body: withdrawal,
}));

export {
  UPDATE_WITHDRAWAL_API_ID,
  updateWithdrawalAC,
  updateWithdrawalDataSelector,
  updateWithdrawalErrorSelector,
  isUpdatingWithdrawalSelector,
};
