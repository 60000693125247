import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_PRICE_API_ID = 'CREATE_PRICE';

const {
  actionCreator: createPriceAC,
  dataSelector: createdPriceRawDataSelector,
  errorSelector: createPriceErrorSelector,
  isFetchingSelector: isCreatingPriceSelector,
} = makeFetchAction(CREATE_PRICE_API_ID, price => ({
  endpoint: `${EMARKET_ADMIN_API}/prices`,
  method: 'POST',
  notify: true,
  body: price,
}));

const createdPriceDataSelector = createdPriceRawDataSelector;

export {
  CREATE_PRICE_API_ID,
  createPriceAC,
  createdPriceDataSelector,
  createPriceErrorSelector,
  isCreatingPriceSelector,
};
