import React from 'react';
import { Layout } from 'antd';

const Footer = () => (
  <Layout.Footer
    style={{ textAlign: 'center', borderTop: '1px solid #e9e9e9', backgroundColor: '#fff' }}
  >
    <p>© Sing Seng Enterprise Sdn. Bhd.</p>
  </Layout.Footer>
);

export default Footer;
