import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_SHIPPING_PRICE_API_ID = 'UPDATE_SHIPPING_PRICE';

const {
  actionCreator: updateShippingPriceAC,
  dataSelector: updateShippingPriceDataSelector,
  errorSelector: updateShippingPriceErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_SHIPPING_PRICE_API_ID, (id, shippingPrice) => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingprices/${id}`,
  method: 'PATCH',
  notify: true,
  body: shippingPrice,
}));

export {
  UPDATE_SHIPPING_PRICE_API_ID,
  updateShippingPriceAC,
  updateShippingPriceDataSelector,
  updateShippingPriceErrorSelector,
  isUpdatingSelector,
};
