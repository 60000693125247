import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import formatDate from '../../utils/formatDate';
import {
  fetchAdminUtilityBillAC,
  adminUtilityBillDataSelector,
  fetchAdminUtilityBillErrorSelector,
  isFetchingAdminUtilityBillSelector,
} from '../../apiCalls/adminUtilityBill/get';
import {
  approveUtilityBillAC,
  approveUtilityBillErrorSelector,
  isApprovingUtilityBillSelector,
} from '../../apiCalls/adminUtilityBill/approve';
import {
  rejectUtilityBillAC,
  rejectUtilityBillErrorSelector,
  isRejectingUtilityBillSelector,
} from '../../apiCalls/adminUtilityBill/reject';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import Status from '../shared/Status';
import ApprovalButton from '../shared/ApprovalButton';
import PageTitle from '../shared/PageTitle';

const AdminUtilityBillDetail = ({
  utilityBill,
  isApproving,
  isRejecting,
  match,
  approveUtilityBill,
  rejectUtilityBill,
}) => (
  <div id="content">
    <PageTitle content="Utility Bills" />
    {utilityBill && (
      <div className="bill">
        <div className="row">
          <div className="col">
            <div className="tags">
              <span className="_item">Requester</span>
            </div>
            <br />
            <strong>{utilityBill.requester.name}</strong>
            <br />
            {utilityBill.requester.area}
          </div>
        </div>
        <br />

        <div className="bill__block">
          <h5 className="bill__label">ID</h5>
          <strong>{utilityBill.id}</strong>
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Contract number</h5>
          <strong className="font16">{utilityBill.contract_id}</strong>
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Provider</h5>
          <strong className="font16">{utilityBill.provider.name}</strong>
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Amount</h5>
          <strong className="font16">RM{utilityBill.amount}</strong>
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Requested</h5>
          {formatDate(utilityBill.created_at)}
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Invoice</h5>
          <strong className="font16">
            {utilityBill.invoice && utilityBill.invoice.origin_url ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={utilityBill.invoice.origin_url}
              >
                <img
                  height={500}
                  src={utilityBill.invoice.origin_url}
                  alt="invoice"
                />
              </a>
            ) : (
              'N/A'
            )}
          </strong>
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Status</h5>
          <Status status={utilityBill.status} />
        </div>
        <div className="bill__block">
          <h5 className="bill__label">Notes</h5>
          <div>{utilityBill.note}</div>
        </div>
        <div className="bill__buttons row align-items-center">
          <div className="col-md-6" />
          <div className="col-md-6 order-md-2">
            <div className="_button-group text-right">
              <Link
                to={`/billings/utility/${utilityBill.id}/edit`}
                className="button button--primary"
                style={{ textTransform: 'uppercase' }}
              >
                Edit
              </Link>
              {utilityBill.actions.map(action => (
                <ApprovalButton
                  key={action}
                  action={action}
                  objectId={match.params.id}
                  isRejecting={isRejecting}
                  isApproving={isApproving}
                  approve={approveUtilityBill}
                  reject={rejectUtilityBill}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

const enhance = compose(
  connect(
    state => ({
      utilityBill: adminUtilityBillDataSelector(state),
      isFetching: isFetchingAdminUtilityBillSelector(state),
      isRejecting: isRejectingUtilityBillSelector(state),
      isApproving: isApprovingUtilityBillSelector(state),
      error: fetchAdminUtilityBillErrorSelector(state),
      approvedError: approveUtilityBillErrorSelector(state),
      rejectedError: rejectUtilityBillErrorSelector(state),
    }),
    {
      fetchAdminUtilityBillDetail: fetchAdminUtilityBillAC,
      approveUtilityBill: approveUtilityBillAC,
      rejectUtilityBill: rejectUtilityBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchAdminUtilityBillDetail(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(AdminUtilityBillDetail);
