import { Descriptions, Tag, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, orderBy } from 'lodash/fp';
import React from 'react';

import { cancelOrderAC } from '../../apiCalls/orders/cancel';
import { completeOrderAC } from '../../apiCalls/orders/complete';
import {
  fetchOrderAC,
  orderDataSelector,
  isFetchingOrderSelector,
} from '../../apiCalls/orders/get';
import {
  fetchQuotationAC,
  quotationDataSelector,
} from '../../apiCalls/quotations/get';
import {
  fetchShipmentsAC,
  shipmentsDataSelector,
  fetchShipmentsErrorSelector,
  isFetchingShipmentsSelector,
  totalShipmentsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/shipments/list';
import { formatDate, timeAgo } from '../../utils/formatDate';
import { columnsGenerator as paymentColumnsGenerator } from '../AdminPayments';
import {
  quoteStatusColors,
  quoteStatusConfig,
  sizeUnitConfig,
  unitColors,
  shippingMethodConfig,
  METHOD_DOOR_TO_DOOR,
} from '../../utils/constants';
import { columnsGenerator as shipmentColumnsGenerator } from '../Shipment';
import { columnsGenerator as transactionColumnsGenerator } from '../Transactions';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';

const paymentColumns = paymentColumnsGenerator();
const transactionColumns = transactionColumnsGenerator();

export const PaymentData = ({
  payment,
  payment_transactions = [],
  revenue_transactions = [],
}) => {
  const transactions = orderBy(
    ['created_at'],
    ['asc']
  )([...payment_transactions, ...revenue_transactions]);

  return (
    <div>
      <hr />
      <div className="">
        <div className="ant-descriptions-title">Payment Request</div>
        <DataTable dataSource={[payment]} columns={paymentColumns} />
      </div>
      <hr />
      <div className="">
        <div className="ant-descriptions-title">Transactions</div>
        <DataTable dataSource={transactions} columns={transactionColumns} />
      </div>
    </div>
  );
};

const shipmentColumns = shipmentColumnsGenerator();

const AdminOrderDetail = ({
  match,
  order,
  shipments,
  isFetching,
  errors,
  quotation,
  fetchOrder,
  fetchShipments,
  completeOrder,
  cancelOrder,
  fetchQuotation,
  ...others
}) => {
  React.useEffect(() => {
    fetchOrder(match.params.id);
    fetchShipments({ page: match.params.page || 1, order: match.params.id });
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (order) {
      fetchQuotation(order.quotation);
    }
  }, [fetchQuotation, order]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div className="">
      <PageTitle content="Orders" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>View order detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {order && (
          <Descriptions
            title="Order details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{order.id}</Descriptions.Item>
            <Descriptions.Item label="Product">
              {order.product && order.product.name}
            </Descriptions.Item>
            <Descriptions.Item label="Buyer">
              {order.buyer && order.buyer.name}
            </Descriptions.Item>
            <Descriptions.Item label="Seller">
              {order.seller && order.seller.name}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Number">
              {`INV ${order.invoice_number}`}
            </Descriptions.Item>
            <Descriptions.Item label="Post">
              {order.post && (
                <Link to={`/emarket/posts/${order.post}`}>View Post</Link>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Quotation">
              {order.quotation && (
                <Link to={`/emarket/quotations/${order.quotation}`}>
                  View Quotation
                </Link>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Size">{order.size}</Descriptions.Item>
            <Descriptions.Item label="Size unit">
              <Tag color={unitColors[order.size_unit]}>
                {sizeUnitConfig[order.size_unit]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {order.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Price">RM{order.price}</Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {order.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Method">
              {shippingMethodConfig[order.shipping_method]}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Amount">
              RM{order.shipping_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">
              RM{order.total_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              <Tag color={quoteStatusColors[order.status]}>
                {quoteStatusConfig[order.status]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Features">
              {map(feature => (
                <Tag key={feature.id} color="geekblue" style={{ margin: 2 }}>
                  {feature.name}
                </Tag>
              ))(order.feature_data)}
            </Descriptions.Item>
            <Descriptions.Item label="Additional information">
              {order.description}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {formatDate(order.created)}
            </Descriptions.Item>
            <Descriptions.Item label="Last updated">
              {timeAgo(order.modified)}
            </Descriptions.Item>
            <Descriptions.Item label="Action">
              <Button.Group>
                {order.status !== 1001 && order.status !== 1002 && (
                  <Button
                    type="primary"
                    onClick={() =>
                      Modal.confirm({
                        title: 'Are you sure?',
                        onOk: () => completeOrder(order.id),
                      })
                    }
                  >
                    Complete
                  </Button>
                )}
                {order.status !== 1001 && order.status !== 1002 && (
                  <Button
                    type="danger"
                    onClick={() =>
                      Modal.confirm({
                        title: 'Are you sure?',
                        onOk: () => cancelOrder(order.id),
                      })
                    }
                  >
                    Cancel
                  </Button>
                )}
              </Button.Group>
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
      <hr />
      <div className="">
        <div className="ant-descriptions-title">Shipments</div>
        {order && order.shipping_method === METHOD_DOOR_TO_DOOR ? (
          <DataTable
            dataSource={shipments}
            dataFetcher={fetchShipments}
            columns={shipmentColumns}
            {...others}
          />
        ) : (
          'Self pickup'
        )}
      </div>
      {quotation ? <PaymentData {...quotation.payment_data} /> : null}
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    order: orderDataSelector(state),
    isFetching: isFetchingOrderSelector(state),
    shipments: shipmentsDataSelector(state),
    isShipmentsFetching: isFetchingShipmentsSelector(state),
    error: fetchShipmentsErrorSelector(state),
    totalItems: totalShipmentsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    quotation: quotationDataSelector(state),
  }),
  {
    fetchOrder: fetchOrderAC,
    completeOrder: completeOrderAC,
    cancelOrder: cancelOrderAC,
    fetchShipments: fetchShipmentsAC,
    fetchQuotation: fetchQuotationAC,
  }
);

export default connectWithRedux(AdminOrderDetail);
