import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { updateAdminUtilityBillAC } from '../../apiCalls/adminUtilityBill/get';
import { REJECT_UTILITY_BILL_API_ID } from '../../apiCalls/adminUtilityBill/reject';

const handleRejectUtilityBillSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(REJECT_UTILITY_BILL_API_ID)
      )
    ),
    tap(action => {
      console.log(action);
    }),
    map(action => {
      const utility_bill = get('payload.json.utility_bill')(action);
      return updateAdminUtilityBillAC(utility_bill, store$.value);
    })
  );

export default combineEpics(handleRejectUtilityBillSuccess);
