import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const COMPLETE_ORDER_API_ID = 'COMPLETE_ORDER';

const {
  actionCreator: completeOrderAC,
  dataSelector: completeOrderDataSelector,
  errorSelector: completeOrderErrorSelector,
  isFetchingSelector: isSubmittingOrderSelector,
} = makeFetchAction(COMPLETE_ORDER_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/orders/${id}/actions`,
  method: 'PUT',
  notify: true,
  body: { action: 'complete' },
}));

export {
  COMPLETE_ORDER_API_ID,
  completeOrderAC,
  completeOrderDataSelector,
  completeOrderErrorSelector,
  isSubmittingOrderSelector,
};
