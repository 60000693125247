import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createPackagingBoxAC,
  isCreatingPackagingBoxSelector,
  createPackagingBoxErrorSelector,
} from '../../apiCalls/packagingBoxes/create';
import Form from './Form';
import PageTitle from '../shared/PageTitle';

const CreatePackagingBox = ({ handleSubmit, submitting }) => (
  <>
    <PageTitle content="Packaging Boxes" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/packaging_boxes">Packaging Boxes</Link>
        </li>
        <li>Add new packging box</li>
      </ul>
    </div>

    <div className="row align-items-center">
      <div className="col-sm-6">
        <h4 className="page-small-title">Add New Packaging Box</h4>
      </div>
    </div>

    <Form
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
      submitting={submitting}
    />
  </>
);

const enhance = connect(
  state => ({
    createError: createPackagingBoxErrorSelector(state),
    submitting: isCreatingPackagingBoxSelector(state),
  }),
  {
    handleSubmit: createPackagingBoxAC,
  }
);

export default enhance(CreatePackagingBox);
