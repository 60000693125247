import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_UTILITY_BILL_API_ID = 'GET_UTILITY_BILL';

const {
  actionCreator: fetchAdminUtilityBillAC,
  dataSelector: adminUtilityBillRawDataSelector,
  errorSelector: fetchAdminUtilityBillErrorSelector,
  isFetchingSelector: isFetchingAdminUtilityBillSelector,
  updater: updateAdminUtilityBillDataAC,
} = makeFetchAction(GET_UTILITY_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/utility_bills/${id}`,
  method: 'GET',
}));

const adminUtilityBillDataSelector = createSelector(
  adminUtilityBillRawDataSelector,
  get('utility_bill')
);

const updateAdminUtilityBillAC = (utility_bill, state) => {
  const existingData = adminUtilityBillRawDataSelector(state);
  const data = {
    ...existingData,
    utility_bill,
  };
  return updateAdminUtilityBillDataAC(data);
};

export {
  GET_UTILITY_BILL_API_ID,
  fetchAdminUtilityBillAC,
  fetchAdminUtilityBillErrorSelector,
  isFetchingAdminUtilityBillSelector,
  adminUtilityBillDataSelector,
  updateAdminUtilityBillAC,
};
