import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const FETCH_POST_API_ID = 'FETCH_POST';

const {
  actionCreator: fetchPostAC,
  dataSelector: rawPostDataSelector,
  isFetchingSelector: isFetchingPostSelector,
  errorSelector: fetchingPostErrorSelector,
} = makeFetchAction(FETCH_POST_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/posts/${id}`,
  method: 'GET',
}));

const postDataSelector = rawPostDataSelector;

export {
  FETCH_POST_API_ID,
  fetchPostAC,
  postDataSelector,
  isFetchingPostSelector,
  fetchingPostErrorSelector,
};
