import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 1000, label: 'Pick Up' },
  { value: 1001, label: 'Door to Door' },
];

const ShippingMethodSelect = props => (
  <SelectField
    placeholder="Select Shipping Method"
    {...props}
    options={options}
  />
);

export default ShippingMethodSelect;
