import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'antd';
import {
  fetchProductsAC,
  productsDataSelector,
  fetchProductsErrorSelector,
  isFetchingProductsSelector,
  totalProductsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/products/list';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import LoadingSpinner from '../shared/LoadingSpinner';
import { formatDate, timeAgo } from '../../utils/formatDate';
import DeleteButton from './DeleteButton';

const columnsGenerator = () => [
  {
    title: 'Product ID',
    dataIndex: 'id',
    render: value => `EF${value}`,
  },
  {
    title: 'Featured Image',
    dataIndex: 'image',
    render: value => value && <img alt="" width={200} src={value} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Name (Malaysian)',
    dataIndex: 'name_ms',
  },
  {
    title: 'Name (Chinese)',
    dataIndex: 'name_zh',
  },
  {
    title: 'Ordering',
    dataIndex: 'order',
  },
  {
    title: 'Created at',
    dataIndex: 'created',
    render: value => formatDate(value),
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button>
          <Link to={`/emarket/products/${record.id}/edit`}>Edit</Link>
        </Button>
        <DeleteButton id={record.id} />
      </Button.Group>
    ),
  },
];

const Products = ({
  fetchProducts,
  products,
  loading,
  errors,
  history,
  ...others
}) => {
  React.useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  if (loading && !products) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div id="content">
      <PageTitle content="Products" />
      <div style={{ textAlign: 'right' }}>
        <button
          className="button button--primary"
          onClick={() => history.push(`/emarket/products/add`)}
        >
          Add New Product <i className="fas fa-plus" />
        </button>
      </div>
      <DataTable
        columns={columnsGenerator()}
        dataSource={products}
        dataFetcher={fetchProducts}
        {...others}
      />
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    products: productsDataSelector(state),
    loading: isFetchingProductsSelector(state),
    error: fetchProductsErrorSelector(state),
    totalItems: totalProductsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchProducts: fetchProductsAC,
  }
);

export default connectWithRedux(Products);
