import { Descriptions, Tag, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { map, orderBy } from 'lodash/fp';
import React from 'react';

import { cancelOrderAC } from 'apiCalls/ecomOrders/cancel';
import { completeOrderAC } from 'apiCalls/ecomOrders/complete';
import {
  fetchOrderAC,
  orderDataSelector,
  isFetchingOrderSelector,
  fetchingOrderErrorSelector,
} from 'apiCalls/ecomOrders/get';
import {
  fetchOrderItemsAC,
  orderItemsDataSelector,
  fetchOrderItemsErrorSelector,
  isFetchingOrderItemsSelector,
  totalOrderItemsSelector,
  currentPageSelector,
  pageSizeSelector,
} from 'apiCalls/ecomOrderItems/list';
import { formatDate, timeAgo } from 'utils/formatDate';
import {
  orderStatusColors,
  orderStatusConfig,
  shippingMethodConfig,
} from 'utils/constants';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';

const orderItemColumnsGenerator = () => [
  {
    title: 'Product',
    dataIndex: ['product', 'name'],
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: price => `RM${price}`,
    align: 'right',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    render: total_amount => `RM${total_amount}`,
    align: 'right',
  },
];

const AdminOrderDetail = ({
  match,
  order,
  isFetching,
  errors,
  fetchOrder,
  completeOrder,
  cancelOrder,
  orderItems,
  fetchOrderItems,
  isFetchingOrderItems,
  orderItemsErrors,
  ...others
}) => {
  React.useEffect(() => {
    fetchOrder(match.params.id);
    fetchOrderItems(match.params.id, { page: match.params.page || 1 });
  }, []); // eslint-disable-line

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div className="">
      <PageTitle content="Ecom Orders" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>View order detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {order && (
          <Descriptions
            title="Order details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{order.id}</Descriptions.Item>
            <Descriptions.Item label="Buyer">
              {order.buyer?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice Number">
              {`INV ${order.id}`}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Method">
              {shippingMethodConfig[order.shipping_method]}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Amount">
              RM{order.shipping_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">
              RM{order.total_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              <Tag color={orderStatusColors[order.status]}>
                {orderStatusConfig[order.status]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Additional information">
              {order.description}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {formatDate(order.created)}
            </Descriptions.Item>
            <Descriptions.Item label="Last updated">
              {timeAgo(order.modified)}
            </Descriptions.Item>
            <Descriptions.Item label="Action">
              <Button.Group>
                {order.status !== 1001 && order.status !== 1002 && (
                  <Button
                    type="primary"
                    onClick={() =>
                      Modal.confirm({
                        title: 'Are you sure?',
                        onOk: () => completeOrder(order.id),
                      })
                    }
                  >
                    Complete
                  </Button>
                )}
                {order.status !== 1001 && order.status !== 1002 && (
                  <Button
                    type="danger"
                    onClick={() =>
                      Modal.confirm({
                        title: 'Are you sure?',
                        onOk: () => cancelOrder(order.id),
                      })
                    }
                  >
                    Cancel
                  </Button>
                )}
              </Button.Group>
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
      <hr />
      <div className="">
        <div className="ant-descriptions-title">Order Items</div>
        {isFetchingOrderItems
          ? '...'
          : orderItems && (
              <DataTable
                dataSource={orderItems}
                dataFetcher={fetchOrderItems}
                columns={orderItemColumnsGenerator()}
                {...others}
              />
            )}
        {!isFetchingOrderItems &&
          orderItems &&
          console.log('orderItems', orderItems)}
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    order: orderDataSelector(state),
    isFetching: isFetchingOrderSelector(state),
    errors: fetchingOrderErrorSelector(state),

    orderItems: orderItemsDataSelector(state),
    isFetchingOrderItems: isFetchingOrderItemsSelector(state),
    totalItems: totalOrderItemsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    orderItemsErrors: fetchOrderItemsErrorSelector(state),
  }),
  {
    fetchOrder: fetchOrderAC,
    completeOrder: completeOrderAC,
    cancelOrder: cancelOrderAC,
    fetchOrderItems: fetchOrderItemsAC,
  }
);

export default connectWithRedux(AdminOrderDetail);
