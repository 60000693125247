import '../css/styles.css';
import 'antd/dist/antd.min.css';

import { Provider } from 'react-redux';
import React from 'react';

import AppRouter from '../AppRouter';
import store from '../state/store';

const App = () => (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

export default App;
