import cookie from 'js-cookie';

const setAdminSessionToLocalStorage = session => {
  cookie.set('adminSession.access_token', session.access_token, { expires: 1 });
  cookie.set('adminSession.user', session.user, { expires: 1 });
  localStorage.setItem('adminSession', JSON.stringify(session));
};

const getAdminSessionFromLocalStorage = () => {
  return localStorage.getItem('adminSession');
};

export { setAdminSessionToLocalStorage, getAdminSessionFromLocalStorage };
