import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchAdminPhoneBillAC,
  adminPhoneBillDataSelector,
  fetchAdminPhoneBillErrorSelector,
  isFetchingAdminPhoneBillSelector,
} from '../../apiCalls/adminPhoneBill/get';
import { convertFormData } from '../../utils/data';
import {
  updatePhoneBillAC,
  updatePhoneBillDataSelector,
  updatePhoneBillErrorSelector,
  isUpdatingPhoneBillSelector,
} from '../../apiCalls/adminPhoneBill/edit';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PhoneBillForm, { parseInitialValues } from './PhoneBillForm';

const AdminPhoneBillEdit = ({ phoneBill, handleSubmit, match }) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Phone Bills</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/billings/phone">Phone Bills</Link>
            </li>
            <li>Edit #{match.params.id}</li>
          </ul>
        </div>

        {phoneBill && (
          <PhoneBillForm
            onFinish={handleSubmit}
            initialValues={parseInitialValues(phoneBill)}
            history
            pristine
            submitting
          />
        )}
      </div>
    </div>
  </div>
);

const prepareProps = mapProps(
  ({ updatePhoneBill, updatePhoneBillError, ...others }) => ({
    formError: updatePhoneBillError,
    handleSubmit: data => {
      const object_id = others.match.params.id;
      if (data.invoice_image && data.invoice_image instanceof File) {
        const formData = convertFormData(data);
        return updatePhoneBill(object_id, formData);
      }
      if (data.invoice_image || data.invoice_image === '') {
        delete data.invoice_image;
      }
      return updatePhoneBill(object_id, data);
    },
    ...others,
  })
);

const enhance = compose(
  connect(
    state => ({
      phoneBill: adminPhoneBillDataSelector(state),
      isFetching: isFetchingAdminPhoneBillSelector(state),
      isUpdating: isUpdatingPhoneBillSelector(state),
      error: fetchAdminPhoneBillErrorSelector(state),
      updatedError: updatePhoneBillErrorSelector(state),
      updatedPhoneBill: updatePhoneBillDataSelector(state),
    }),
    {
      fetchAdminPhoneBillEdit: fetchAdminPhoneBillAC,
      updatePhoneBill: updatePhoneBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      // eslint-disable-next-line
      this.props.fetchAdminPhoneBillEdit(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminPhoneBillEdit);
