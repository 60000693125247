import { Button } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';
import update from 'immutability-helper';

import { deleteFeatureAC } from '../../apiCalls/features/delete';
import {
  fetchFeaturesAC,
  featuresDataSelector,
  fetchFeaturesErrorSelector,
  isFetchingFeaturesSelector,
  totalFeaturesSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/features/list';
import { formatDate, timeAgo } from '../../utils/formatDate';
import { updateFeatureAC } from '../../apiCalls/features/update';
import DataTable from '../shared/DataTable';
import DraggableBodyRow from '../shared/DraggableBodyRow';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import DeleteButton from './DeleteButton';

const columnsGenerator = () => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name in English',
    dataIndex: 'name',
  },
  {
    title: 'Name in Malaysian',
    dataIndex: 'name_ms',
  },
  {
    title: 'Name in Chinese',
    dataIndex: 'name_zh',
  },
  {
    title: 'Created at',
    dataIndex: 'created',
    render: value => formatDate(value),
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button>
          <Link to={`/emarket/features/${record.id}/edit`}>Edit</Link>
        </Button>
        <DeleteButton id={record.id} />
      </Button.Group>
    ),
  },
];

const components = {
  body: {
    row: DraggableBodyRow,
  },
};

const Features = ({
  fetchFeatures,
  features,
  history,
  updateFeature,
  ...others
}) => {
  const [data, setData] = React.useState([]);
  const [changingOrder, setChangingOrder] = React.useState(false);

  React.useEffect(() => {
    if (features) {
      setData(features);
    }
  }, [features]);

  React.useEffect(() => {
    if (changingOrder) {
      data.forEach((feature, index) => {
        updateFeature(feature.id, { order: index });
      });
      setChangingOrder(false);
    }
  }, [data, changingOrder, updateFeature]);

  const moveRow = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(data =>
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      setChangingOrder(true);
    },
    [data]
  );

  return (
    <div id="content">
      <PageTitle content="Features" />
      <div style={{ textAlign: 'right' }}>
        <button
          className="button button--primary"
          onClick={() => history.push(`/emarket/features/add`)}
        >
          Add New Feature <i className="fas fa-plus" />
        </button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <DataTable
          dataSource={data}
          dataFetcher={fetchFeatures}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          {...others}
        />
      </DndProvider>
    </div>
  );
};

const enhance = compose(
  connect(
    state => ({
      features: featuresDataSelector(state),
      loading: isFetchingFeaturesSelector(state),
      error: fetchFeaturesErrorSelector(state),
      totalItems: totalFeaturesSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchFeatures: fetchFeaturesAC,
      deleteFeature: deleteFeatureAC,
      updateFeature: updateFeatureAC,
    }
  ),
  mapProps(({ deleteFeature, ...others }) => ({
    columns: columnsGenerator({ deleteFeature }),
    ...others,
  })),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchFeatures();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Features);
