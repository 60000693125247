import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_CREDIT_RECORDS_API_ID = 'credit/FETCH_ADMIN_CREDIT_RECORDS';

const {
  actionCreator: fetchAdminCreditRecordsAC,
  dataSelector: adminCreditsRawSelector,
  errorSelector: fetchAdminCreditRecordsErrorSelector,
  isFetchingSelector: isFetchingAdminCreditRecordsSelector,
  updater: updateAdminCreditRecordsAC,
} = makeFetchAction(FETCH_ADMIN_CREDIT_RECORDS_API_ID, (params = {}) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/credits`,
    params,
  }),
  method: 'GET',
}));

const totalCreditsItemSelector = createSelector(
  adminCreditsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  adminCreditsRawSelector,
  get('paging.current_page')
);

const adminCreditsDataSelector = createSelector(
  adminCreditsRawSelector,
  get('credit_records')
);

const pageSizeSelector = createSelector(
  adminCreditsRawSelector,
  get('paging.per_page')
);

export {
  FETCH_ADMIN_CREDIT_RECORDS_API_ID,
  fetchAdminCreditRecordsAC,
  adminCreditsDataSelector,
  fetchAdminCreditRecordsErrorSelector,
  isFetchingAdminCreditRecordsSelector,
  updateAdminCreditRecordsAC,
  totalCreditsItemSelector,
  currentPageSelector,
  pageSizeSelector,
};
