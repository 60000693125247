import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import {
  adminUserDataSelector,
  fetchAdminUserAC,
} from '../../apiCalls/adminUsers/get';
import { approveCreditRequestAC } from '../../apiCalls/creditSystem/approve';
import {
  fetchAdminCreditHistoryAC,
  adminCreditsDataSelector,
  fetchAdminCreditHistoryErrorSelector,
  isFetchingAdminCreditHistorySelector,
  totalCreditsItemSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/creditSystem/creditHistory';
import { fetchWalletAC, walletDataSelector } from '../../apiCalls/wallets/get';
import { formatDate } from '../../utils/formatDate';
import { rejectCreditRequestAC } from '../../apiCalls/creditSystem/reject';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import Status from '../shared/Status';
import formatAmount from '../../utils/formatAmount';

const columnGenerator = (rejectCreditRequest, approveCreditRequest) => [
  {
    title: 'Transaction ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Creator',
    dataIndex: ['creator', 'name'],
  },
  {
    title: 'Reference',
    dataIndex: 'description',
  },
  // {
  //   title: 'Particular',
  //   dataIndex: 'type',
  // },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: formatAmount,
    align: 'right',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: formatAmount,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const actions = [
        <Button>
          <Link to={`/payment/credits/records/${record.id}`}>View details</Link>
        </Button>,
      ];
      if (record.status === 'pending') {
        actions.push(
          <Button
            key="1"
            type="primary"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Are you sure?')) {
                approveCreditRequest(record.id);
              }
            }}
          >
            Approve
          </Button>
        );
        actions.push(
          <Button
            key="2"
            type="danger"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Are you sure?')) {
                rejectCreditRequest(record.id);
              }
            }}
          >
            Reject
          </Button>
        );
      }

      if (actions.length > 0)
        return <Button.Group>{actions.map(o => o)}</Button.Group>;
      return '';
    },
  },
];

const UserCreditHistory = ({
  credits,
  history,
  match,
  rejectCreditRequest,
  approveCreditRequest,
  fetchAdminCreditHistory,
  fetchAdminUser,
  fetchWallet,
  loading,
  error,
  userData,
  walletData,
  ...others
}) => {
  const userId = match.params.id;
  const params = { user_id: match.params.id };
  const columns = columnGenerator(rejectCreditRequest, approveCreditRequest);

  useEffect(() => {
    fetchAdminCreditHistory({ filter: { user_id: userId } });
    fetchAdminUser(userId);
    fetchWallet(userId);

    // eslint-disable-next-line
  }, [userId, fetchAdminCreditHistory]);

  return (
    <div className="">
      <div className="">
        <PageTitle content="Credit History" />
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/payment/credits">Credit System</Link>
            </li>
            <li>Credit History</li>
          </ul>
        </div>
        {userData ? (
          <div className="dashboard-page__actions">
            <div className="dashboard-page__actions__buttons text-right">
              <Button
                onClick={() =>
                  history.push(
                    `/payment/credits/new?user_id=${userData.user.id}&user_name=${userData.user.name}`
                  )
                }
              >
                Add New Record &nbsp;
                <i className="fas fa-plus" />
              </Button>
            </div>
          </div>
        ) : null}

        {error && <ErrorMessage />}

        <h6>Username: {userData && userData.user.name}</h6>
        <h6>
          Current credit: {walletData && formatAmount(walletData.wallet.credit)}
        </h6>
        <DataTable
          dataFetcher={fetchAdminCreditHistory}
          dataSource={credits}
          loading={loading}
          filters={{ filter: params }}
          columns={columns}
          {...others}
        />
      </div>
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    userData: adminUserDataSelector(state),
    walletData: walletDataSelector(state),
    credits: adminCreditsDataSelector(state),
    errors: fetchAdminCreditHistoryErrorSelector(state),
    loading: isFetchingAdminCreditHistorySelector(state),
    totalItems: totalCreditsItemSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchAdminUser: fetchAdminUserAC,
    fetchWallet: fetchWalletAC,
    fetchAdminCreditHistory: fetchAdminCreditHistoryAC,
    rejectCreditRequest: rejectCreditRequestAC,
    approveCreditRequest: approveCreditRequestAC,
  }
);

export default connectToRedux(UserCreditHistory);
