import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { Descriptions, Tag, Button } from 'antd';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchStaffAC,
  staffDataSelector,
  fetchStaffErrorSelector,
  isFetchingStaffSelector,
} from '../../apiCalls/adminStaffs/get';
import { activateStaffAC } from '../../apiCalls/adminStaffs/activate';
import { deleteStaffAC } from '../../apiCalls/adminStaffs/delete';
import { deactivateStaffAC } from '../../apiCalls/adminStaffs/deactivate';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import UserAction from '../shared/UserAction';

const AdminStaffDetail = ({
  staff,
  isActivating,
  activateStaff,
  isDeactivating,
  deactivateStaff,
  deleteStaff,
  match,
  ...others
}) => (
  <div className="">
    <div className="">
      <h1 className="page-title">User Management</h1>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/staffs">Staffs</Link>
          </li>
          <li>#{match.params.id}</li>
        </ul>
      </div>
      <div className="">
        {staff ? (
          <Descriptions
            title="Staff details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{staff.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{staff.name}</Descriptions.Item>
            <Descriptions.Item label="Email">{staff.email}</Descriptions.Item>
            <Descriptions.Item label="Roles">
              {staff.roles &&
                staff.roles.map(role => (
                  <Tag key={role} color="geekblue" style={{ margin: 2 }}>
                    {role}
                  </Tag>
                ))}
            </Descriptions.Item>
            <Descriptions.Item label="Is Active">
              {staff.is_active.toString()}
            </Descriptions.Item>
          </Descriptions>
        ) : null}
      </div>
      <hr />
      <div className="text-right">
        {staff && (
          <Button.Group>
            <Button
              type="danger"
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  deleteStaff(staff.id);
                }
              }}
            >
              Delete
            </Button>
            <Button>
              <Link
                to={`/iam/staffs/${staff.id}/edit`}
                style={{ textTransform: 'uppercase' }}
              >
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={`/iam/staffs/${staff.id}/password`}
                style={{ textTransform: 'uppercase' }}
              >
                Change Password
              </Link>
            </Button>
            {staff.actions.map(action => (
              <UserAction
                key={action}
                action={action}
                objectId={match.params.id}
                isActivating={isActivating}
                isDeactivating={isDeactivating}
                activate={activateStaff}
                deactivate={deactivateStaff}
              />
            ))}
          </Button.Group>
        )}
      </div>
    </div>
  </div>
);

const enhance = compose(
  connect(
    state => ({
      staff: staffDataSelector(state),
      isFetching: isFetchingStaffSelector(state),
      error: fetchStaffErrorSelector(state),
    }),
    {
      fetchStaff: fetchStaffAC,
      activateStaff: activateStaffAC,
      deactivateStaff: deactivateStaffAC,
      deleteStaff: deleteStaffAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchStaff(id);
    },
  }),
  mapProps(props => {
    const { staff, ...others } = props;
    return {
      staff: get('staff')(staff),
      ...others,
    };
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(AdminStaffDetail);
