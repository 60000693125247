import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 'prepaid', label: 'Prepaid' },
  { value: 'postpaid', label: 'Postpaid' },
];

const PhonePlanSelect = props => (
  <SelectField placeholder="Select plan" {...props} options={options} />
);

export default PhonePlanSelect;
