import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Button } from 'antd';
import { formatDate } from '../../utils/formatDate';
import {
  fetchAdminCreditRecordsAC,
  adminCreditsDataSelector,
  fetchAdminCreditRecordsErrorSelector,
  isFetchingAdminCreditRecordsSelector,
  totalCreditsItemSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/creditSystem/list';
import { rejectCreditRequestAC } from '../../apiCalls/creditSystem/reject';
import { approveCreditRequestAC } from '../../apiCalls/creditSystem/approve';
import ErrorMessage from '../shared/ErrorMessage';
import Status from '../shared/Status';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';

const columnGenerator = (rejectCreditRequest, approveCreditRequest) => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'User',
    dataIndex: ['user', 'name'],
  },
  {
    title: 'Phone number',
    dataIndex: ['user', 'contact_number'],
    render: (text, record) =>
      `+(${record.user.country_code}) ${record.user.contact_number}`,
  },
  // {
  //   title: 'Record Type',
  //   dataIndex: 'record_type',
  // },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const actions = [
        <Button>
          <Link to={`/payment/credits/records/${record.id}`}>View details</Link>
        </Button>,
      ];
      if (record.status === 'pending') {
        actions.push(
          <Button
            key="1"
            type="primary"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Are you sure?')) {
                approveCreditRequest(record.id);
              }
            }}
          >
            Approve
          </Button>
        );
        actions.push(
          <Button
            key="2"
            type="danger"
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Are you sure?')) {
                rejectCreditRequest(record.id);
              }
            }}
          >
            Reject
          </Button>
        );
      }

      if (actions.length > 0)
        return <Button.Group>{actions.map(o => o)}</Button.Group>;
      return '';
    },
  },
];

const CreditRecords = ({
  credits,
  history,
  match,
  rejectCreditRequest,
  approveCreditRequest,
  fetchAdminCreditRecords,
  loading,
  error,
  ...others
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const columns = columnGenerator(rejectCreditRequest, approveCreditRequest);

  useEffect(() => {
    fetchAdminCreditRecords({ filter: params });
    // eslint-disable-next-line
  }, [location, fetchAdminCreditRecords]);

  return (
    <div className="">
      <div className="">
        <PageTitle content="Credit Records" />
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>Credit Records</li>
          </ul>
        </div>

        {error && <ErrorMessage />}
        <DataTable
          dataFetcher={fetchAdminCreditRecords}
          dataSource={credits}
          loading={loading}
          filters={{ filter: params }}
          columns={columns}
          {...others}
        />
      </div>
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    credits: adminCreditsDataSelector(state),
    errors: fetchAdminCreditRecordsErrorSelector(state),
    loading: isFetchingAdminCreditRecordsSelector(state),
    totalItems: totalCreditsItemSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchAdminCreditRecords: fetchAdminCreditRecordsAC,
    rejectCreditRequest: rejectCreditRequestAC,
    approveCreditRequest: approveCreditRequestAC,
  }
);
// withProps(({ rejectCreditRequest, approveCreditRequest }) => ({
//   columns: columnGenerator(rejectCreditRequest, approveCreditRequest),
// })),

export default connectToRedux(CreditRecords);
