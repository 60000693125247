import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from 'utils/envConfig';

const GET_PRODUCT_API_ID = 'ECOM_GET_PRODUCT';

const {
  actionCreator: fetchProductAC,
  dataSelector: productRawDataSelector,
  errorSelector: fetchProductErrorSelector,
  isFetchingSelector: isFetchingProductSelector,
} = makeFetchAction(GET_PRODUCT_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/ecomproducts/${id}`,
  method: 'GET',
}));

const productDataSelector = productRawDataSelector;

export {
  GET_PRODUCT_API_ID,
  fetchProductAC,
  fetchProductErrorSelector,
  isFetchingProductSelector,
  productDataSelector,
};
