import { Form, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import queryString from 'query-string';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import { convertFormData } from '../../utils/data';
import {
  createTopupRecordAC,
  createTopupRecordErrorSelector,
  isCreatingTopupRecordSelector,
} from '../../apiCalls/adminTopups/create';
import PaymentMethodSelect from '../shared/PaymentMethodSelect';
import TextField from '../shared/form/TextField';
import UserSelect from '../shared/UserSelect';
import PageTitle from '../shared/PageTitle';

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const TopupRecordCreate = ({ submitting, handleSubmit }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const userId = params.user_id;
  const userName = params.user_name;

  return (
    <div id="content">
      <PageTitle content="New Topup Record" />
      <div className="bill">
        <div className="bill__block">
          <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={{
              payment_method: 'bank_transfer',
              user_name: userName,
            }}
            onFinish={data =>
              handleSubmit({ ...data, user_id: userId || data.user_id })
            }
          >
            {userId ? (
              <TextField name="user_name" type="text" label="User" disabled />
            ) : (
              <UserSelect
                name="user_id"
                label="User"
                required
                {...FORM_ITEM_LAYOUT}
              />
            )}
            <TextField
              name="amount"
              type="number"
              label="Amount"
              {...FORM_ITEM_LAYOUT}
              required
            />
            <PaymentMethodSelect
              name="payment_method"
              label="Payment Method"
              required
              {...FORM_ITEM_LAYOUT}
            />
            <Form.Item
              name="invoice_image"
              label="Invoice"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              required
            >
              <Upload listType="picture" beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
            <TextField
              name="description"
              multiline
              type="text"
              label="Notes"
              {...FORM_ITEM_LAYOUT}
            />
            <div className="bill__buttons row align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 order-md-2">
                <div className="_button-group text-right">
                  <Link
                    className="button button--secondary"
                    to="/payment/withdrawals"
                  >
                    Dismiss
                  </Link>
                  <button
                    type="submit"
                    className="button button--primary"
                    disabled={submitting}
                    style={{ marginLeft: 10 }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isCreatingTopupRecordSelector(state),
    createTopupRecordError: createTopupRecordErrorSelector(state),
  }),
  {
    createTopupRecord: createTopupRecordAC,
  }
);

const prepareProps = mapProps(
  ({ createTopupRecord, createTopupRecordError, ...others }) => ({
    formError: createTopupRecordError,
    handleSubmit: data => {
      if (
        data.invoice_image &&
        data.invoice_image[0] &&
        data.invoice_image[0].originFileObj instanceof File
      ) {
        const formData = convertFormData({
          ...data,
          invoice_image: data.invoice_image[0].originFileObj,
        });
        return createTopupRecord(formData);
      }

      if (data.invoice_image || data.invoice_image === '') {
        delete data.invoice_image;
      }

      return createTopupRecord(data);
    },
    ...others,
  })
);

const enhance = compose(connectWithRedux, prepareProps);

export default enhance(TopupRecordCreate);
