import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_PRODUCT_IMAGES_API_ID = 'FETCH_PRODUCT_IMAGES';

const {
  actionCreator: fetchProductImagesAC,
  dataSelector: productImagesRawSelector,
  errorSelector: fetchProductsErrorSelector,
  isFetchingSelector: isFetchingProductsSelector,
  updater: updateProductsAC,
} = makeFetchAction(FETCH_PRODUCT_IMAGES_API_ID, (params = {}) => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/productimages`,
    params,
  }),
  method: 'GET',
}));

const totalProductsSelector = createSelector(
  productImagesRawSelector,
  get('count')
);

const currentPageSelector = createSelector(
  productImagesRawSelector,
  get('count')
);

const pageSizeSelector = createSelector(
  productImagesRawSelector,
  get('paging.per_page')
);

const productImagesDataSelector = createSelector(
  productImagesRawSelector,
  get('results')
);

export {
  FETCH_PRODUCT_IMAGES_API_ID,
  fetchProductImagesAC,
  productImagesDataSelector,
  fetchProductsErrorSelector,
  isFetchingProductsSelector,
  updateProductsAC,
  currentPageSelector,
  pageSizeSelector,
  totalProductsSelector,
};
