import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_BALANCE_API_ID = 'FETCH_BALANCE';

const {
  actionCreator: fetchBalanceAC,
  dataSelector: balanceRawSelector,
  errorSelector: fetchBalanceErrorSelector,
  isFetchingSelector: isFetchingBalanceSelector,
  updater: updateBalanceAC,
} = makeFetchAction(FETCH_BALANCE_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/balance`,
    params,
  }),
  method: 'GET',
}));

const efpayInitialSelector = createSelector(balanceRawSelector, get('initial'));
const efpayBalanceSelector = createSelector(balanceRawSelector, get('efpay'));
const efbankBalanceSelector = createSelector(balanceRawSelector, get('efbank'));

export {
  FETCH_BALANCE_API_ID,
  fetchBalanceAC,
  fetchBalanceErrorSelector,
  isFetchingBalanceSelector,
  updateBalanceAC,
  efpayInitialSelector,
  efpayBalanceSelector,
  efbankBalanceSelector,
};
