import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import PaymentMethodSelect from '../shared/PaymentMethodSelect';
import Uploader from '../shared/form/Uploader';

const WithdrawalForm = ({ handleSubmit, submitting }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={handleSubmit}
    labelAlign="left"
  >
    <TextField
      name="amount"
      type="number"
      label="Amount"
      required
      {...FORM_ITEM_LAYOUT}
    />
    <PaymentMethodSelect
      name="payment_method"
      label="Payment Method"
      {...FORM_ITEM_LAYOUT}
    />
    <Uploader name="invoice_image" label="Invoice" {...FORM_ITEM_LAYOUT} />
    <TextField
      name="note"
      multiline
      type="text"
      label="Notes"
      {...FORM_ITEM_LAYOUT}
    />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting}
          >
            Submit
          </button>
          <Link
            className="button button--secondary"
            to="/payment/withdrawals"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Link>
        </div>
      </div>
    </div>
  </Form>
);

export const buildFormData = values => {
  const { amount, payment_method, description, invoice } = values;
  return {
    amount,
    payment_method,
    note: description,
    invoice_image: invoice.origin_url,
  };
};

export default WithdrawalForm;
