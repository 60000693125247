import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ORDERS_API_ID = 'FETCH_ORDERS';

const {
  actionCreator: fetchOrdersAC,
  dataSelector: ordersRawSelector,
  errorSelector: fetchOrdersErrorSelector,
  isFetchingSelector: isFetchingOrdersSelector,
  updater: updateOrdersAC,
} = makeFetchAction(FETCH_ORDERS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/orders`,
    params,
  }),
  method: 'GET',
}));

const totalOrdersSelector = createSelector(
  ordersRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  ordersRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  ordersRawSelector,
  get('paging.per_page')
);

const ordersDataSelector = createSelector(
  ordersRawSelector,
  get('results')
);

export {
  FETCH_ORDERS_API_ID,
  fetchOrdersAC,
  ordersDataSelector,
  fetchOrdersErrorSelector,
  isFetchingOrdersSelector,
  updateOrdersAC,
  currentPageSelector,
  pageSizeSelector,
  totalOrdersSelector,
};
