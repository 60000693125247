import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_WITHDRAWAL_API_ID = 'REJECT_WITHDRAWAL';

const {
  actionCreator: rejectWithdrawalAC,
  dataSelector: rejectWithdrawalDataSelector,
  errorSelector: rejectWithdrawalErrorSelector,
  isFetchingSelector: isRejectingWithdrawalSelector,
} = makeFetchAction(REJECT_WITHDRAWAL_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/withdrawals/${id}/reject`,
  method: 'POST',
  body: data,
  notify: true,
}));

export {
  REJECT_WITHDRAWAL_API_ID,
  rejectWithdrawalAC,
  rejectWithdrawalDataSelector,
  rejectWithdrawalErrorSelector,
  isRejectingWithdrawalSelector,
};
