import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const ProductForm = ({ onFinish, submitting, initialValues }) => {
  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Name in English"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="name_ms"
        label="Name in Malay"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="name_zh"
        label="Name in Chinese"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="order" label="Ordering" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="image"
        label="Image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload listType="picture" beforeUpload={() => false} maxCount={1}>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      <div className="row align-items-center">
        <div className="col-md-6" />
        <div className="col-md-6">
          <div className="text-right">
            <Button.Group>
              <Button>
                <Link to="/emarket/products">Dismiss</Link>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting}
                loading={submitting}
              >
                Submit
              </Button>
            </Button.Group>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductForm;
