import React from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { compose, mapProps } from 'recompose';
import {
  createUserAC,
  isCreatingUserSelector,
  errorSelector,
} from '../../apiCalls/adminUsers/create';
import UserFormFields from './UserFormFields';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import PageTitle from '../shared/PageTitle';

const AdminUserCreate = ({ history, submitting, handleSubmit }) => (
  <div id="content">
    <PageTitle content="Create new user" />
    <Form
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
    >
      <UserFormFields />
      <hr />
      <Form.Item
        name="password"
        type="password"
        label="Password"
        {...FORM_ITEM_LAYOUT}
        required
      >
        <Input.Password />
      </Form.Item>
      <div className="bill__buttons row align-items-center">
        <div className="col-md-6" />
        <div className="col-md-6 order-md-2">
          <div className="_button-group text-right">
            <button
              type="submit"
              className="button button--primary"
              disabled={submitting}
            >
              Submit
            </button>
            <button
              className="button button--secondary"
              onClick={() => history.push(`/iam/users`)}
            >
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>
);

const connectWithRedux = connect(
  state => ({
    createError: errorSelector(state),
    submitting: isCreatingUserSelector(state),
  }),
  {
    createUser: createUserAC,
  }
);

const prepareProps = mapProps(({ createUser, createError, ...others }) => ({
  handleSubmit: ({
    contact_number,
    email,
    country_code,
    name,
    password,
    roles,
    area,
    address,
    identification_number,
    bank_name,
    bank_account_name,
    bank_account_number,
    person_in_charge,
  }) => {
    return createUser({
      contact_number,
      email,
      name,
      country_code,
      password,
      roles,
      area,
      address,
      identification_number,
      bank_name,
      bank_account_name,
      bank_account_number,
      person_in_charge,
    });
  },
  ...others,
}));

const enhance = compose(connectWithRedux, prepareProps);

export default enhance(AdminUserCreate);
