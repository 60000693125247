import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_SHIPPING_PRICES_API_ID = 'FETCH_SHIPPING_PRICES';

const {
  actionCreator: fetchShippingPricesAC,
  dataSelector: shippingPricesRawSelector,
  errorSelector: fetchShippingPricesErrorSelector,
  isFetchingSelector: isFetchingShippingPricesSelector,
  updater: updateShippingPricesAC,
} = makeFetchAction(
  FETCH_SHIPPING_PRICES_API_ID,
  (params = { page: 1, per_page: 20 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/shippingprices`,
      params,
    }),
    method: 'GET',
  })
);

const totalShippingPricesSelector = createSelector(
  shippingPricesRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  shippingPricesRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  shippingPricesRawSelector,
  get('paging.per_page')
);

const shippingPricesDataSelector = createSelector(
  shippingPricesRawSelector,
  get('results')
);

export {
  FETCH_SHIPPING_PRICES_API_ID,
  fetchShippingPricesAC,
  shippingPricesDataSelector,
  fetchShippingPricesErrorSelector,
  isFetchingShippingPricesSelector,
  updateShippingPricesAC,
  currentPageSelector,
  pageSizeSelector,
  totalShippingPricesSelector,
};
