import { ACTIONS } from 'redux-api-call';
import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

import { UPDATE_STAFF_API_ID } from '../../apiCalls/adminStaffs/edit';
import { UPDATE_STAFF_PASSWORD_API_ID } from '../../apiCalls/adminStaffs/password';
import routerHistory from '../../routerHistory';

const handleUpdateStaffSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_STAFF_API_ID))),
    map(action => {
      const staff = get('payload.json.staff')(action);
      routerHistory.push(`/iam/staffs/${staff.id}`);
    })
  );

const handleUpdateStaffPasswordSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_STAFF_PASSWORD_API_ID))),
    map(action => {
      const staff = get('payload.json.staff')(action);
      routerHistory.push(`/iam/staffs/${staff.id}`);
    })
  );

export default combineEpics(
  handleUpdateStaffSuccess,
  handleUpdateStaffPasswordSuccess
);
