import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get } from 'lodash/fp';
import {
  updatePasswordAC,
  isUpdatingSelector,
} from '../../apiCalls/adminUsers/password';
import PasswordForm from './PasswordForm';
import {
  fetchAdminUserAC,
  adminUserDataSelector,
  isFetchingAdminUserSelector,
} from '../../apiCalls/adminUsers/get';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';

const AdminEditPassword = ({ match, handleSubmit, formError }) => {
  const { search } = useLocation();
  const usersQuery = new URLSearchParams(search);

  return (
    <div className="">
      <div className="">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to={`/iam/users?${usersQuery}`}>Users</Link>
            </li>
            <li>Change password #{match.params.id}</li>
          </ul>
        </div>

        <div className="">
          <div className="">
            <div className="row align-items-center">
              <div className="error">{formError}</div>
            </div>
            <PasswordForm onFinish={handleSubmit} history pristine submitting />
          </div>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    user: adminUserDataSelector(state),
    isFetching: isFetchingAdminUserSelector(state),
  }),
  {
    updatePassword: updatePasswordAC,
    fetchAdminUser: fetchAdminUserAC,
  }
);

const prepareProps = mapProps(
  ({ updatePassword, updatePasswordError, ...others }) => ({
    formError: updatePasswordError,
    handleSubmit: data => {
      const user_id = others.match.params.id;
      return updatePassword(user_id, data);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchAdminUser, match } = this.props;
      const { id } = match.params;
      fetchAdminUser(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditPassword);
