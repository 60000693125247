import { ACTIONS } from 'redux-api-call';
import { combineEpics, ofType } from 'redux-observable';
import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';

import { REJECT_WITHDRAWAL_API_ID } from '../../apiCalls/adminWithdrawals/reject';
import { updateAdminWithdrawalAC } from '../../apiCalls/adminWithdrawals/get';
import routerHistory from '../../routerHistory';

const handleRejectWithdrawalSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(REJECT_WITHDRAWAL_API_ID))),
    tap(action => {
      console.log(action);
      routerHistory.goBack();
    }),
    map(action => {
      const withdrawal = get('payload.json.withdrawal')(action);
      return updateAdminWithdrawalAC(withdrawal, store$.value);
    })
  );

export default combineEpics(handleRejectWithdrawalSuccess);
