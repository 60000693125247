import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Modal, Input } from 'antd';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';

const LocationForm = ({
  onFinish,
  submitting,
  initialValues,
  deleteLocation,
}) => (
  <Form
    {...FORM_ITEM_LAYOUT}
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={onFinish}
    initialValues={initialValues}
    labelAlign="left"
  >
    <Form.Item
      name="name"
      type="text"
      label="Name (English)"
      rules={[{ required: true }]}
    >
      <Input />
    </Form.Item>
    <Form.Item name="name_ms" type="text" label="Name (Malaysian)">
      <Input />
    </Form.Item>
    <Form.Item name="name_zh" type="text" label="Name (Chinese)">
      <Input />
    </Form.Item>
    <Form.Item name="address" type="text" label="Address">
      <Input />
    </Form.Item>
    <Form.Item name="description" type="text" label="Description">
      <Input.TextArea />
    </Form.Item>

    <div className="row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6">
        <div className="text-right">
          <Button.Group>
            {initialValues && initialValues.id && (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure?',
                    onOk: () => deleteLocation(initialValues.id),
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button>
              <Link to="/emarket/locations">Dismiss</Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitting}
              loading={submitting}
            >
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

export default LocationForm;
