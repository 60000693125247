import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createProviderAC,
  isCreatingProviderSelector,
  createProviderErrorSelector,
} from '../../apiCalls/adminProviders/create';
import { convertFormData } from '../../utils/data';
import ProviderForm from './Form';

const ProviderCreate = ({ handleSubmit, submitting }) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/billings/providers">Providers</Link>
            </li>
            <li>Add new provider</li>
          </ul>
        </div>

        <div className="dashboard-page__actions">
          <div className="dashboard-page__actions__top">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-small-title">Add New Provider</h4>
              </div>
            </div>
          </div>
        </div>

        <ProviderForm onFinish={handleSubmit} submitting={submitting} />
      </div>
    </div>
  </div>
);

const connectWithRedux = connect(
  state => ({
    createError: createProviderErrorSelector(state),
    submitting: isCreatingProviderSelector(state),
  }),
  {
    handleSubmit: obj => {
      if (obj.logo && obj.logo instanceof File) {
        const formData = convertFormData(obj);
        return createProviderAC(formData);
      }
      if (obj.logo || obj.logo === '') delete obj.logo;
      return createProviderAC(obj);
    },
  }
);

export default connectWithRedux(ProviderCreate);
