import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_UTILITY_BILL_API_ID = 'REJECT_UTILITY_BILL';

const {
  actionCreator: rejectUtilityBillAC,
  dataSelector: rejectUtilityBillDataSelector,
  errorSelector: rejectUtilityBillErrorSelector,
  isFetchingSelector: isRejectingUtilityBillSelector,
} = makeFetchAction(REJECT_UTILITY_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/utility_bills/${id}/reject`,
  method: 'POST',
  notify: true,
}));

export {
  REJECT_UTILITY_BILL_API_ID,
  rejectUtilityBillAC,
  rejectUtilityBillDataSelector,
  rejectUtilityBillErrorSelector,
  isRejectingUtilityBillSelector,
};
