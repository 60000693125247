import { get } from 'lodash/fp';
import { createAction, handleActions } from 'redux-actions';

import { setAuthTokenAC } from '../state/session/state';
import { setAdminAuthTokenAC } from '../state/adminSession/state';
import { getSessionFromLocalStorage } from './index';
import { getAdminSessionFromLocalStorage } from './admin';

const START_FETCHING_LOCAL_STORAGE = 'START_FETCHING_LOCAL_STORAGE';
const FINISH_FETCHING_LOCAL_STORAGE = 'FINISH_FETCHING_LOCAL_STORAGE';

const startFetchingLocalStorateAC = createAction(START_FETCHING_LOCAL_STORAGE);

const finishFetchingLocalStorateAC = createAction(
  FINISH_FETCHING_LOCAL_STORAGE
);

const startFetchingLocalStorateThunkCreator = () => dispatch => {
  dispatch(startFetchingLocalStorateAC());

  const result = getSessionFromLocalStorage();
  if (result) {
    const session = JSON.parse(result);
    dispatch(setAuthTokenAC(session));
  }

  const adminResult = getAdminSessionFromLocalStorage();
  if (adminResult) {
    const adminSession = JSON.parse(adminResult);
    dispatch(setAdminAuthTokenAC(adminSession));
  }

  dispatch(finishFetchingLocalStorateAC());
  // getSessionFromLocalStorage().then(result => {
  //   console.log(result);
  //   if (result) {
  //     const data = JSON.parse(result);
  //     dispatch(setAuthTokenAC(data.auth_token));
  //   }
  //   dispatch(finishFetchingLocalStorateAC());
  // });
};

const isFetchingLocalStorageReducer = handleActions(
  {
    [START_FETCHING_LOCAL_STORAGE]: () => true,
    [FINISH_FETCHING_LOCAL_STORAGE]: () => false,
  },
  null
);

const isFetchingLocalStorageSelector = get('isFetchingLocalStorage');

export {
  FINISH_FETCHING_LOCAL_STORAGE,
  startFetchingLocalStorateAC,
  finishFetchingLocalStorateAC,
  isFetchingLocalStorageSelector,
  startFetchingLocalStorateThunkCreator,
};

export default { isFetchingLocalStorage: isFetchingLocalStorageReducer };
