import { ACTIONS } from 'redux-api-call';
import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { CREATE_STAFF_API_ID } from '../../apiCalls/adminStaffs/create';
import routerHistory from '../../routerHistory';

const handleCreateStaffSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_STAFF_API_ID))),
    map(() => {
      routerHistory.push(`/iam/staffs`);
    })
  );

export default handleCreateStaffSuccess;
