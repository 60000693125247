import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { get, map } from 'lodash/fp';
import React from 'react';

import {
  fetchStaffsAC,
  fetchStaffsErrorSelector,
  isFetchingStaffsSelector,
  staffsDataSelector,
  totalStaffsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/adminStaffs/list';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    render: roles =>
      map(role => (
        <Tag key={role} color="geekblue" style={{ margin: 2 }}>{role}</Tag>
      ))(roles),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <>
        <Link
          to={`/iam/staffs/${record.id}`}
          className="button button--small button--red-line"
        >
          Detail
        </Link>
        <Link
          to={`/iam/staffs/${record.id}/edit`}
          className="button button--small button--red-line"
        >
          Edit
        </Link>
      </>
    ),
  },
];

const Staffs = ({ fetchStaffs, staffs, history, ...others }) => (
  <>
    <PageTitle content="Staffs" />
    <div className="dashboard-page__actions">
      <div className="dashboard-page__actions__buttons text-right">
        <button className="button button--primary" onClick={() => history.push(`/iam/staffs/add`)}>
          Add New Staff &nbsp;<i className="fas fa-plus" />
        </button>
      </div>
    </div>
    <DataTable
      dataSource={staffs}
      dataFetcher={fetchStaffs}
      columns={columns}
      {...others}
    />
  </>
);

const enhance = compose(
  connect(
    state => ({
      staffs: staffsDataSelector(state),
      loading: isFetchingStaffsSelector(state),
      error: fetchStaffsErrorSelector(state),
      totalItems: totalStaffsSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchStaffs: fetchStaffsAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchStaffs();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Staffs);
