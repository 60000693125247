import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_CREDIT_RECORD_API_ID = 'GET_CREDIT_RECORD';

const {
  actionCreator: fetchCreditRecordAC,
  dataSelector: creditRecordDataSelector,
  errorSelector: fetchCreditRecordErrorSelector,
  isFetchingSelector: isFetchingCreditRecordSelector,
} = makeFetchAction(GET_CREDIT_RECORD_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/credits/${id}`,
  method: 'GET',
}));

export {
  GET_CREDIT_RECORD_API_ID,
  fetchCreditRecordAC,
  fetchCreditRecordErrorSelector,
  isFetchingCreditRecordSelector,
  creditRecordDataSelector,
};
