import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Input } from 'antd';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';

const PasswordForm = ({ handleSubmit, submitting, pristine }) => (
  <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit} labelAlign="left">
    <Form.Item
      name="password"
      type="password"
      label="Password"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <Input.Password />
    </Form.Item>
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <Button.Group>
            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
            <Button>
              <Link to="/iam/users">
                Dismiss
              </Link>
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

export const buildFormData = values => {
  const { password } = values;
  return { password };
};

export default PasswordForm;
