import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_PACKAGING_BOX_API_ID = 'CREATE_PACKAGING_BOX';

const {
  actionCreator: createPackagingBoxAC,
  dataSelector: createdPackagingBoxRawDataSelector,
  errorSelector: createPackagingBoxErrorSelector,
  isFetchingSelector: isCreatingPackagingBoxSelector,
} = makeFetchAction(CREATE_PACKAGING_BOX_API_ID, packingBox => ({
  endpoint: `${EMARKET_ADMIN_API}/packagingboxes`,
  method: 'POST',
  notify: true,
  body: packingBox,
}));

const createdPackagingBoxDataSelector = createdPackagingBoxRawDataSelector;

export {
  CREATE_PACKAGING_BOX_API_ID,
  createPackagingBoxAC,
  createdPackagingBoxDataSelector,
  createPackagingBoxErrorSelector,
  isCreatingPackagingBoxSelector,
};
