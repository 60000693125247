import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_PACKAGING_BOXES_API_ID = 'FETCH_PACKAGING_BOXES';

const {
  actionCreator: fetchPackagingBoxesAC,
  dataSelector: packagingBoxesRawSelector,
  errorSelector: fetchPackagingBoxesErrorSelector,
  isFetchingSelector: isFetchingPackagingBoxesSelector,
  updater: updatePackagingBoxesAC,
} = makeFetchAction(
  FETCH_PACKAGING_BOXES_API_ID,
  (params = { page: 1, per_page: 20 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/packagingboxes`,
      params,
    }),
    method: 'GET',
  })
);

const totalPackagingBoxesSelector = createSelector(
  packagingBoxesRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  packagingBoxesRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  packagingBoxesRawSelector,
  get('paging.per_page')
);

const packagingBoxesDataSelector = createSelector(
  packagingBoxesRawSelector,
  get('results')
);

export {
  FETCH_PACKAGING_BOXES_API_ID,
  fetchPackagingBoxesAC,
  packagingBoxesDataSelector,
  fetchPackagingBoxesErrorSelector,
  isFetchingPackagingBoxesSelector,
  updatePackagingBoxesAC,
  currentPageSelector,
  pageSizeSelector,
  totalPackagingBoxesSelector,
};
