import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_PRODUCT_API_ID = 'CREATE_PRODUCT';

const {
  actionCreator: createProductAC,
  dataSelector: createdProductRawDataSelector,
  errorSelector: createProductErrorSelector,
  isFetchingSelector: isCreatingProductSelector,
} = makeFetchAction(CREATE_PRODUCT_API_ID, product => ({
  endpoint: `${EMARKET_ADMIN_API}/products`,
  method: 'POST',
  notify: true,
  body: product,
}));

const createdProductDataSelector = createdProductRawDataSelector;

export {
  CREATE_PRODUCT_API_ID,
  createProductAC,
  createdProductDataSelector,
  createProductErrorSelector,
  isCreatingProductSelector,
};
