import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { map } from 'lodash/fp';
import { connect } from 'react-redux';
import TextField from '../shared/form/TextField';
import PostTypeSelect from '../shared/PostTypeSelect';
import SelectField from '../shared/form/SelectField';
import UnitSelect from '../shared/UnitSelect';
import Uploader from '../shared/form/Uploader';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  fetchFeaturesAC,
  featuresDataSelector,
  isFetchingFeaturesSelector,
} from '../../apiCalls/features/list';
import {
  fetchProductsAC,
  productsDataSelector,
  isFetchingProductsSelector,
} from '../../apiCalls/products/list';

const PostForm = ({
  handleSubmit,
  submitting,
  fetchProducts,
  fetchFeatures,
  features: rawFeatures,
  products: rawProducts,
  isLoadingFeatures,
  isLoadingProducts,
}) => {
  React.useEffect(() => {
    fetchProducts();
    fetchFeatures();
  }, [fetchFeatures, fetchProducts]);

  const products = map(product => ({ value: product.id, label: product.name }))(
    rawProducts
  );
  const features = map(feature => ({ value: feature.id, label: feature.name }))(
    rawFeatures
  );

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
      labelAlign="left"
    >
      <PostTypeSelect
        name="post_type"
        type="text"
        label="Post Type"
        {...FORM_ITEM_LAYOUT}
      />
      <SelectField
        name="product"
        options={products}
        label="Product"
        {...FORM_ITEM_LAYOUT}
        loading={isLoadingProducts}
      />
      <UnitSelect
        name="size_unit"
        type="text"
        label="Size Unit"
        {...FORM_ITEM_LAYOUT}
      />
      <TextField
        name="price"
        component={TextField}
        type="number"
        label="Price"
        {...FORM_ITEM_LAYOUT}
      />
      <TextField
        name="quantity"
        type="number"
        label="Quantity"
        {...FORM_ITEM_LAYOUT}
      />
      <TextField name="size" type="text" label="Size" {...FORM_ITEM_LAYOUT} />
      <SelectField
        name="features"
        label="Features"
        options={features}
        mode="multiple"
        {...FORM_ITEM_LAYOUT}
        loading={isLoadingFeatures}
      />
      <TextField
        name="description"
        type="text"
        multiline
        label="Description"
        {...FORM_ITEM_LAYOUT}
      />
      <Uploader
        name="image"
        type="text"
        label="Featured Image"
        {...FORM_ITEM_LAYOUT}
      />

      <div className="bill__buttons row align-items-center">
        <div className="col-md-6" />
        <div className="col-md-6 order-md-2">
          <div className="_button-group text-right">
            <button
              type="submit"
              className="button button--primary"
              disabled={submitting}
            >
              Submit
            </button>
            <Link
              className="button button--secondary"
              to="/emarket/posts"
              style={{ marginLeft: 10 }}
            >
              Dismiss
            </Link>
          </div>
        </div>
      </div>
    </Form>
  );
};

const connectWithRedux = connect(
  state => ({
    features: featuresDataSelector(state),
    products: productsDataSelector(state),
    isLoadingFeatures: isFetchingFeaturesSelector(state),
    isLoadingProducts: isFetchingProductsSelector(state),
  }),
  {
    fetchFeatures: fetchFeaturesAC,
    fetchProducts: fetchProductsAC,
  }
);

export default connectWithRedux(PostForm);
