import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const APPROVE_WITHDRAWAL_API_ID = 'APPROVE_WITHDRAWAL';

const {
  actionCreator: approveWithdrawalAC,
  dataSelector: approveWithdrawalDataSelector,
  errorSelector: approveWithdrawalErrorSelector,
  isFetchingSelector: isApprovingWithdrawalSelector,
} = makeFetchAction(APPROVE_WITHDRAWAL_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/withdrawals/${id}/approve`,
  method: 'POST',
  body: data,
  notify: true,
}));

export {
  APPROVE_WITHDRAWAL_API_ID,
  approveWithdrawalAC,
  approveWithdrawalDataSelector,
  approveWithdrawalErrorSelector,
  isApprovingWithdrawalSelector,
};
