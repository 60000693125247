import { get, map as lodashMap } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { notification } from 'antd';

export const openNotificationWithIcon = ({ type, message, description }) => {
  notification[type]({
    message,
    description,
  });
};

const showNotificationOnSuccess = action$ =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(action => get('payload.notify')(action)),
    map(() =>
      openNotificationWithIcon({
        type: 'success',
        message: 'Success',
      })
    )
  );

const showNotificationOnFailure = action$ =>
  action$.pipe(
    ofType(ACTIONS.FAILURE),
    filter(action => get('payload.notify')(action)),
    map(action => {
      let error_message = null;
      const resp_errors = get(`payload.json.errors`)(action);
      const resp_error = get(`payload.json.error`)(action);

      if (resp_errors) {
        error_message = lodashMap(error => `${error} \n`)(resp_errors);
      } else if (resp_error) {
        error_message = resp_error;
      }
      return openNotificationWithIcon({
        type: 'error',
        message: 'Something went wrong!',
        description: error_message,
      });
    })
  );

export default combineEpics(
  showNotificationOnSuccess,
  showNotificationOnFailure
);
