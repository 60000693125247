import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 100, label: 'kg/unit' },
  { value: 101, label: 'units/kg' },
  { value: 102, label: 'other' },
];

const UnitSelect = props => (
  <SelectField placeholder="Select unit" {...props} options={options} />
);

export default UnitSelect;
