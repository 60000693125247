import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_LOCATION_API_ID = 'UPDATE_LOCATION';

const {
  actionCreator: updateLocationAC,
  dataSelector: updateLocationDataSelector,
  errorSelector: updateLocationErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_LOCATION_API_ID, (id, location) => ({
  endpoint: `${EMARKET_ADMIN_API}/locations/${id}`,
  method: 'PATCH',
  notify: true,
  body: location,
}));

export {
  UPDATE_LOCATION_API_ID,
  updateLocationAC,
  updateLocationDataSelector,
  updateLocationErrorSelector,
  isUpdatingSelector,
};
