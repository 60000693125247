import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get } from 'lodash/fp';
import {
  updateStaffPasswordAC,
  isUpdatingStaffPasswordSelector,
} from '../../apiCalls/adminStaffs/password';
import PasswordForm from './PasswordForm';
import {
  fetchStaffAC,
  staffDataSelector,
  isFetchingStaffSelector,
} from '../../apiCalls/adminStaffs/get';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';

const AdminEditStaffPassword = ({ match, handleSubmit, formError }) => (
  <div className="">
    <div className="">
      <h1 className="page-title">Dashboard</h1>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/staffs">Staffs</Link>
          </li>
          <li>
            <Link to={`/iam/staffs/${match.params.id}`}>
              Staff {match.params.id}
            </Link>
          </li>
          <li>Change password</li>
        </ul>
      </div>

      <div className="">
        <div className="">
          <div className="row align-items-center">
            <div className="error">{formError}</div>
          </div>
          <PasswordForm onFinish={handleSubmit} history pristine submitting />
        </div>
      </div>
    </div>
  </div>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingStaffPasswordSelector(state),
    user: staffDataSelector(state),
    isFetching: isFetchingStaffSelector(state),
  }),
  {
    updateStaffPassword: updateStaffPasswordAC,
    fetchStaff: fetchStaffAC,
  }
);

const prepareProps = mapProps(
  ({ updateStaffPassword, updatePasswordError, ...others }) => ({
    formError: updatePasswordError,
    handleSubmit: data => {
      const staff_id = others.match.params.id;
      return updateStaffPassword(staff_id, data);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchStaff, match } = this.props;
      const { id } = match.params;
      fetchStaff(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditStaffPassword);
