import { Button } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';
import update from 'immutability-helper';

import {
  fetchLocationsAC,
  locationsDataSelector,
  fetchLocationsErrorSelector,
  isFetchingLocationsSelector,
  totalLocationsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/locations/list';
import { timeAgo } from '../../utils/formatDate';
import { updateLocationAC } from '../../apiCalls/locations/update';
import DataTable from '../shared/DataTable';
import DraggableBodyRow from '../shared/DraggableBodyRow';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columnsGenerator = () => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Name (Malaysian)',
    dataIndex: 'name_ms',
  },
  {
    title: 'Name (Chinese)',
    dataIndex: 'name_zh',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button>
        <Link to={`/emarket/locations/${record.id}/edit`}>Edit</Link>
      </Button>
    ),
  },
];

const components = {
  body: {
    row: DraggableBodyRow,
  },
};

const Locations = ({
  fetchLocations,
  locations,
  history,
  updateLocation,
  ...others
}) => {
  const [data, setData] = React.useState([]);
  const [changingOrder, setChangingOrder] = React.useState(false);

  React.useEffect(() => {
    if (locations) {
      setData(locations);
    }
  }, [locations]);

  React.useEffect(() => {
    if (changingOrder) {
      data.forEach((location, index) => {
        updateLocation(location.id, { order: index });
      });
      setChangingOrder(false);
    }
  }, [data, changingOrder, updateLocation]);

  const moveRow = React.useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      setData(data =>
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
      setChangingOrder(true);
    },
    [data]
  );

  return (
    <div id="content">
      <PageTitle content="Locations" />
      <div style={{ textAlign: 'right' }}>
        <button
          className="button button--primary"
          onClick={() => history.push(`/emarket/locations/add`)}
        >
          Add New Location <i className="fas fa-plus" />
        </button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <DataTable
          dataSource={data}
          dataFetcher={fetchLocations}
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
          {...others}
        />
      </DndProvider>
    </div>
  );
};

const enhance = compose(
  connect(
    state => ({
      locations: locationsDataSelector(state),
      loading: isFetchingLocationsSelector(state),
      error: fetchLocationsErrorSelector(state),
      totalItems: totalLocationsSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchLocations: fetchLocationsAC,
      updateLocation: updateLocationAC,
    }
  ),
  mapProps(({ deleteProduct, ...others }) => ({
    columns: columnsGenerator({ deleteProduct }),
    ...others,
  })),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchLocations();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Locations);
