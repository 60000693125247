import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateStaffAC,
  isUpdatingStaffSelector,
  updateStaffErrorSelector,
} from '../../apiCalls/adminStaffs/edit';
import StaffForm, { buildFormData } from './StaffForm';
import {
  fetchStaffAC,
  staffDataSelector,
  isFetchingStaffSelector,
} from '../../apiCalls/adminStaffs/get';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';

const AdminEditStaff = ({
  match,
  fetchStaff,
  isFetching,
  errors,
  updateStaffError,
  staff,
  updateStaff,
  submitting,
}) => {
  React.useEffect(() => {
    fetchStaff(match.params.id);
  }, [fetchStaff, match.params.id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  if (!staff) {
    return null;
  }

  const handleSubmit = staff => {
    const staff_id = match.params.id;
    return updateStaff(staff_id, staff);
  };

  return (
    <div className="">
      <div className="">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/iam/staffs">Staffs</Link>
            </li>
            <li>
              <Link to={`/iam/staffs/${match.params.id}`}>
                Staff {match.params.id}
              </Link>
            </li>
            <li>Edit</li>
          </ul>
        </div>

        <div className="">
          <div className="">
            <div className="row align-items-center">
              <div className="error">{updateStaffError}</div>
            </div>
            <StaffForm
              onFinish={handleSubmit}
              initialValues={buildFormData(staff.staff)}
              submitting={submitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingStaffSelector(state),
    staff: staffDataSelector(state),
    isFetching: isFetchingStaffSelector(state),
    updateStaffError: updateStaffErrorSelector(state),
  }),
  {
    updateStaff: updateStaffAC,
    fetchStaff: fetchStaffAC,
  }
);

export default connectWithRedux(AdminEditStaff);
