import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const VERIFY_USER_API_ID = 'VERIFY_USER';

const {
  actionCreator: verifyUserAC,
  dataSelector: verifyUserDataSelector,
  errorSelector: verifyUserErrorSelector,
  isFetchingSelector: isVerifyingSelector,
} = makeFetchAction(VERIFY_USER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}/verify`,
  method: 'PATCH',
  notify: true,
}));

export {
  VERIFY_USER_API_ID,
  verifyUserAC,
  verifyUserDataSelector,
  verifyUserErrorSelector,
  isVerifyingSelector,
};
