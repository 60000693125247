import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get } from 'lodash/fp';
import {
  updatePriceAC,
  isUpdatingSelector,
} from '../../apiCalls/locationPrices/update';
import PriceForm from './Form';
import {
  fetchPriceAC,
  priceDataSelector,
  isFetchingPriceSelector,
} from '../../apiCalls/locationPrices/get';
import { deletePriceAC } from '../../apiCalls/locationPrices/delete';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

// Now it's loaded before the data is ready
const initialValues = ({ source, destination, ...others }) => ({
  source: get('id')(source),
  destination: get('id')(destination),
  ...others,
});

const AdminEditPrice = ({
  match,
  handleSubmit,
  formError,
  price,
  deletePrice,
}) => (
  <>
    <PageTitle content="Routes and Pricing" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/routes-and-pricing">Routes and Pricing</Link>
        </li>
        <li>Edit price: {match.params.id}</li>
      </ul>
    </div>

    <div className="">
      <div className="">
        <div className="row align-items-center">
          <div className="error">{formError}</div>
        </div>
        {price && (
          <PriceForm
            handleSubmit={handleSubmit}
            deletePrice={deletePrice}
            initialValues={initialValues(price)}
            history
            pristine
            submitting
          />
        )}
      </div>
    </div>
  </>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    price: priceDataSelector(state),
    isFetching: isFetchingPriceSelector(state),
  }),
  {
    updatePrice: updatePriceAC,
    fetchPrice: fetchPriceAC,
    deletePrice: deletePriceAC,
  }
);

const prepareProps = mapProps(
  ({ updatePrice, updatePriceError, ...others }) => ({
    formError: updatePriceError,
    handleSubmit: price => {
      const price_id = others.match.params.id;
      return updatePrice(price_id, price);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchPrice, match } = this.props;
      const { id } = match.params;
      fetchPrice(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditPrice);
