import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { UPDATE_UTILITY_BILL_API_ID } from '../../apiCalls/adminUtilityBill/edit';
import routerHistory from '../../routerHistory';

const handleEditWithdrawalSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(UPDATE_UTILITY_BILL_API_ID)
      )
    ),
    map(action => {
      const bill = get('payload.json.utility_bill ')(action);
      routerHistory.push(`/billings/utility/${bill.id}`);
    })
  );

export default combineEpics(handleEditWithdrawalSuccess);
