import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const CREATE_USER_API_ID = 'CREATE_USER';

const {
  actionCreator: createUserAC,
  dataSelector: createUserDataSelector,
  errorSelector: createUserErrorSelector,
  isFetchingSelector: isCreatingUserSelector,
} = makeFetchAction(CREATE_USER_API_ID, user => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users`,
  method: 'POST',
  notify: true,
  body: user,
}));

const errorSelector = createSelector(
  createUserErrorSelector,
  error => {
    if (isEmpty(error)) return null;
    if (!isEmpty(error.error)) return error.error;
    if (!isEmpty(error.errors)) return error.errors.join(',');
    return 'Oops, something went wrong, please take a screenshot of the current screen & contact your administrator';
  }
);

export {
  CREATE_USER_API_ID,
  createUserAC,
  createUserDataSelector,
  createUserErrorSelector,
  errorSelector,
  isCreatingUserSelector,
};
