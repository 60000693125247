import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const APPROVE_UTILITY_BILL_API_ID = 'APPROVE_UTILITY_BILL';

const {
  actionCreator: approveUtilityBillAC,
  dataSelector: approveUtilityBillDataSelector,
  errorSelector: approveUtilityBillErrorSelector,
  isFetchingSelector: isApprovingUtilityBillSelector,
} = makeFetchAction(APPROVE_UTILITY_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/utility_bills/${id}/approve`,
  method: 'POST',
  notify: true,
}));

export {
  APPROVE_UTILITY_BILL_API_ID,
  approveUtilityBillAC,
  approveUtilityBillDataSelector,
  approveUtilityBillErrorSelector,
  isApprovingUtilityBillSelector,
};
