import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_SHIPMENTS_API_ID = 'FETCH_SHIPMENTS';

const {
  actionCreator: fetchShipmentsAC,
  dataSelector: shipmentsRawSelector,
  errorSelector: fetchShipmentsErrorSelector,
  isFetchingSelector: isFetchingShipmentsSelector,
  updater: updateShipmentsAC,
} = makeFetchAction(FETCH_SHIPMENTS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/shipments`,
    params,
  }),
  method: 'GET',
}));

const totalShipmentsSelector = createSelector(
  shipmentsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  shipmentsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  shipmentsRawSelector,
  get('paging.per_page')
);

const shipmentsDataSelector = createSelector(
  shipmentsRawSelector,
  get('results')
);

export {
  FETCH_SHIPMENTS_API_ID,
  fetchShipmentsAC,
  shipmentsDataSelector,
  fetchShipmentsErrorSelector,
  isFetchingShipmentsSelector,
  updateShipmentsAC,
  currentPageSelector,
  pageSizeSelector,
  totalShipmentsSelector,
};
