import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { get, startCase } from 'lodash/fp';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import { formatDate } from '../../utils/formatDate';
import Status from '../shared/Status';
import ErrorMessage from '../shared/ErrorMessage';
import {
  fetchPhoneBillsAC,
  phoneBillsDataSelector,
  fetchPhoneBillsErrorSelector,
  isFetchingPhoneBillsSelector,
  currentPageSelector,
  totalItemSelector,
  pageSizeSelector,
} from '../../apiCalls/adminPhoneBill/list';
import {
  isApprovingPhoneBillSelector,
  approvePhoneBillAC,
} from '../../apiCalls/adminPhoneBill/approve';
import {
  isRejectingPhoneBillSelector,
  rejectPhoneBillAC,
} from '../../apiCalls/adminPhoneBill/reject';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    render: startCase,
  },
  {
    title: 'Requester',
    dataIndex: ['requester', 'name'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (text, record) => (
      <>
        <Link
          to={`/billings/phone/${record.id}`}
          className="button button--small button--red-line"
        >
          Detail
        </Link>
        <Link
          to={`/billings/phone/${record.id}/edit`}
          className="button button--small button--red-line"
        >
          Edit
        </Link>
      </>
    ),
  },
];

const PhoneBills = ({ phoneBills, fetchPhoneBills, ...others }) => (
  <div id="content">
    <PageTitle content="Phone Bills" />
    <DataTable
      dataFetcher={fetchPhoneBills}
      dataSource={phoneBills}
      columns={columns}
      {...others}
    />
  </div>
);

const enhance = compose(
  connect(
    state => ({
      loading: isFetchingPhoneBillsSelector(state),
      errors: fetchPhoneBillsErrorSelector(state),
      phoneBills: phoneBillsDataSelector(state),
      totalItems: totalItemSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
      isRejecting: isRejectingPhoneBillSelector(state),
      isApproving: isApprovingPhoneBillSelector(state),
    }),
    {
      fetchPhoneBills: fetchPhoneBillsAC,
      approvePhoneBillRequest: approvePhoneBillAC,
      rejectPhoneBillRequest: rejectPhoneBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchPhoneBills();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(PhoneBills);
