import { makeFetchAction } from 'redux-api-call';
import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GENERATE_USER_KEY_API_ID = 'GENERATE_USER_KEY';

const {
  actionCreator: fetchAdminUserKeyAC,
  dataSelector: adminUserKeyDataSelector,
  errorSelector: fetchAdminUserKeyErrorSelector,
  isFetchingSelector: isFetchingAdminUserKeySelector,
} = makeFetchAction(GENERATE_USER_KEY_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}/get-token`,
  method: 'POST',
}));

const secretKeySelector = createSelector(
  adminUserKeyDataSelector,
  get('access_token')
);

export {
  GENERATE_USER_KEY_API_ID,
  fetchAdminUserKeyAC,
  fetchAdminUserKeyErrorSelector,
  isFetchingAdminUserKeySelector,
  adminUserKeyDataSelector,
  secretKeySelector,
};
