import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_SHIPPING_REQUESTS_API_ID = 'FETCH_SHIPPING_REQUESTS';

const {
  actionCreator: fetchShippingRequestsAC,
  dataSelector: shippingRequestsRawSelector,
  errorSelector: fetchShippingRequestsErrorSelector,
  isFetchingSelector: isFetchingShippingRequestsSelector,
  updater: updateShippingRequestsAC,
} = makeFetchAction(
  FETCH_SHIPPING_REQUESTS_API_ID,
  (params = { page: 1, status: 1000 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/shippingrequests`,
      params,
    }),
    method: 'GET',
  })
);

const totalShippingRequestsSelector = createSelector(
  shippingRequestsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  shippingRequestsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  shippingRequestsRawSelector,
  get('paging.per_page')
);

const shippingRequestsDataSelector = createSelector(
  shippingRequestsRawSelector,
  get('results')
);

export {
  FETCH_SHIPPING_REQUESTS_API_ID,
  fetchShippingRequestsAC,
  shippingRequestsDataSelector,
  fetchShippingRequestsErrorSelector,
  isFetchingShippingRequestsSelector,
  updateShippingRequestsAC,
  currentPageSelector,
  pageSizeSelector,
  totalShippingRequestsSelector,
};
