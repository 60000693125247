import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from 'utils/envConfig';

const DELETE_PRODUCT_API_ID = 'ECOM_DELETE_PRODUCT';

const {
  actionCreator: deleteProductAC,
  dataSelector: deleteProductDataSelector,
  errorSelector: deleteProductErrorSelector,
  isFetchingSelector: isDeletingProductSelector,
} = makeFetchAction(DELETE_PRODUCT_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/ecomproducts/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_PRODUCT_API_ID,
  deleteProductAC,
  deleteProductDataSelector,
  deleteProductErrorSelector,
  isDeletingProductSelector,
};
