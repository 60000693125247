import { combineReducers } from 'redux';
import { reducers as apiReducers } from 'redux-api-call';

import isFetchingLocalStorageReducer from '../LocalStorage/state';
import sessionReducer from './session/state';
import adminSessionReducer from './adminSession/state';

export default combineReducers({
  ...apiReducers,
  ...isFetchingLocalStorageReducer,
  ...sessionReducer,
  ...adminSessionReducer,
});
