import { makeFetchAction } from 'redux-api-call';

import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const COMPLETE_SHIPPING_REQUEST_API_ID = 'COMPLETE_SHIPPING_REQUEST';

const {
  actionCreator: completeShippingRequestAC,
  dataSelector: completeShippingRequestDataSelector,
  errorSelector: completeShippingRequestErrorSelector,
  isFetchingSelector: isCompletingShippingRequestSelector,
} = makeFetchAction(COMPLETE_SHIPPING_REQUEST_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingrequests/${id}`,
  method: 'PATCH',
  body: { status: 1001 },
  notify: true,
}));

export {
  COMPLETE_SHIPPING_REQUEST_API_ID,
  completeShippingRequestAC,
  completeShippingRequestDataSelector,
  completeShippingRequestErrorSelector,
  isCompletingShippingRequestSelector,
};
