import React from 'react';
import { Modal, Spin } from 'antd';

const ActionButton = ({
  text,
  action,
  loading,
  className,
  requireConfirmation = true,
  disabled = false,
  ...others
}) => {
  const onClick = () => {
    if (requireConfirmation) {
      Modal.confirm({
        title: 'Are you sure?',
        onOk: action,
      });
    } else {
      action();
    }
  };

  return (
    <button
      style={{ marginLeft: 4, marginRight: 4, width: 120 }}
      className={className}
      disabled={disabled}
      onClick={onClick}
      {...others}
    >
      {loading ? <Spin size="small" /> : text.toUpperCase()}
    </button>
  );
};

export default ActionButton;
