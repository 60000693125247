import { Link } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { connect } from 'react-redux';
import React from 'react';

import { CREDIT_USER_ID } from '../../utils/envConfig';
import {
  fetchTransactionsAC,
  transactionsDataSelector,
  fetchTransactionsErrorSelector,
  isFetchingTransactionsSelector,
  totalTransactionsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/transactions/list';
import { fetchWalletAC, walletDataSelector } from '../../apiCalls/wallets/get';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import formatAmount from '../../utils/formatAmount';
import { formatDate } from '../../utils/formatDate';
import {
  transactionTypeColors,
  transactionTypeConfig,
} from '../../utils/constants';

const columnsGenerator = () => [
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: created_at => formatDate(created_at),
  },
  {
    title: 'Sender',
    dataIndex: ['request', 'requester'],
    render: (user, obj) =>
      obj.request.receiver ? (
        <Link to={`/iam/users/${user.id}`}>{user.name}</Link>
      ) : (
        <Link to="/payment/efpay_account">EFPay</Link>
      ),
  },
  {
    title: 'Receiver',
    dataIndex: ['request', 'receiver'],
    render: user =>
      user ? (
        <Link to={`/iam/users/${user.id}`}>{user.name}</Link>
      ) : (
        <Link to={`/iam/users/${CREDIT_USER_ID}`}>EFPay Credit</Link>
      ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type, obj) => (
      <>
        <Tag color={transactionTypeColors[type]}>
          {transactionTypeConfig(type, obj.service_code, obj.transaction_type)}
        </Tag>
        {obj.parent_id ? <Tag color="red">Refund</Tag> : null}
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (price, obj) =>
      `${obj.transaction_type === 'withdrawal' ? '-' : '+'}RM${price}`,
    align: 'right',
  },
  {
    title: 'Balance',
    dataIndex: 'current_balance',
    render: current_balance => `RM${current_balance}`,
    align: 'right',
  },
  {
    title: '',
    dataIndex: 'id',
    render: id => (
      <Button.Group>
        <Button>
          <Link to={`/payment/transactions/${id}`}>View detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const columns = columnsGenerator();

const CreditTransactions = ({
  transactions,
  error,
  fetchTransactions,
  history,
  fetchWallet,
  walletData,
  ...others
}) => {
  React.useEffect(() => {
    fetchTransactions({ filter: { user_id: CREDIT_USER_ID } });
    fetchWallet(CREDIT_USER_ID);
  }, [fetchTransactions, fetchWallet]);

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <div id="content">
      <PageTitle content="Credit Transactions" />
      <div className="row">
        <div className="col-sm-6">
          <h6>
            Total Topup:{' '}
            {walletData && formatAmount(walletData.wallet.total_topup_amount)}
          </h6>
          <h6>
            Current balance:{' '}
            {walletData && formatAmount(walletData.wallet.payment)}
          </h6>
        </div>
        <div className="col-sm-6">
          <div className="dashboard-page__actions">
            <div className="dashboard-page__actions__buttons text-right">
              <Button
                onClick={() =>
                  history.push(
                    `/payment/topups/new?user_id=${CREDIT_USER_ID}&user_name=EFPay Credit`
                  )
                }
              >
                Topup &nbsp;
                <i className="fas fa-plus" />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        dataSource={transactions}
        dataFetcher={fetchTransactions}
        filters={{ filter: { user_id: CREDIT_USER_ID } }}
        {...others}
      />
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    transactions: transactionsDataSelector(state),
    loading: isFetchingTransactionsSelector(state),
    error: fetchTransactionsErrorSelector(state),
    totalItems: totalTransactionsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    walletData: walletDataSelector(state),
  }),
  {
    fetchTransactions: fetchTransactionsAC,
    fetchWallet: fetchWalletAC,
  }
);

export default connectToRedux(CreditTransactions);
