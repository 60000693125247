import cookie from 'js-cookie';

const setSessionToLocalStorage = session => {
  cookie.set('access_token', session.access_token, { expires: 1 });
  cookie.set('user', session.user, { expires: 1 });
  localStorage.setItem('session', JSON.stringify(session));
};

const removeFromLocalStorage = key => {
  localStorage.removeItem(key);
};

const getSessionFromLocalStorage = () => {
  return localStorage.getItem('session');
};

export {
  setSessionToLocalStorage,
  removeFromLocalStorage,
  getSessionFromLocalStorage,
};
