import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import TextField from 'components/shared/form/TextField';
import UnitSelect from 'components/shared/UnitSelect';
import { FORM_ITEM_LAYOUT } from 'components/shared/form/config';

const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const ProductForm = ({ onFinish, submitting, initialValues }) => {
  return (
    <Form
      {...FORM_ITEM_LAYOUT}
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onFinish}
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Name (English)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="description" type="text" label="Description (English)">
        <Input.TextArea rows={10} />
      </Form.Item>

      <hr />

      <Form.Item
        name="name_ms"
        label="Name (Malay)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="description_ms" type="text" label="Description (Malay)">
        <Input.TextArea rows={10} />
      </Form.Item>

      <hr />

      <Form.Item
        name="name_zh"
        label="Name (Chinese)"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description_zh"
        type="text"
        label="Description (Chinese)"
      >
        <Input.TextArea rows={10} />
      </Form.Item>

      <hr />

      <Form.Item
        name="image"
        label="Image"
        valuePropName="fileList"
        getValueFromEvent={normFile}
      >
        <Upload listType="picture" beforeUpload={() => false} maxCount={1}>
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>

      <TextField
        name="price"
        component={TextField}
        type="number"
        label="Price"
        rules={[{ required: true }]}
        extra="Price per unit"
        {...FORM_ITEM_LAYOUT}
      />
      {/* <TextField
        name="quantity"
        type="number"
        label="Quantity (max capacity)"
        extra="We prefer this as a reference for a max supplied capacity. It's not for inventory checking for now"
        {...FORM_ITEM_LAYOUT}
      /> */}

      <div className="row align-items-center">
        <div className="col-md-6" />
        <div className="col-md-6">
          <div className="text-right">
            <Button.Group>
              <Button>
                <Link to="/ecommerce/products">Dismiss</Link>
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting}
                loading={submitting}
              >
                Submit
              </Button>
            </Button.Group>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ProductForm;
