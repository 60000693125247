import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const ACTIVATE_USER_API_ID = 'ACTIVATE_USER';

const {
  actionCreator: activateUserAC,
  dataSelector: activateUserDataSelector,
  errorSelector: activateUserErrorSelector,
  isFetchingSelector: isActivatingSelector,
} = makeFetchAction(ACTIVATE_USER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}/activate`,
  method: 'PATCH',
}));

export {
  ACTIVATE_USER_API_ID,
  activateUserAC,
  activateUserDataSelector,
  activateUserErrorSelector,
  isActivatingSelector,
};
