import { Form, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import TextField from '../shared/form/TextField';

const PackagingBoxForm = ({ onFinish, initialValues, deletePackagingBox }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={onFinish}
    initialValues={initialValues}
    labelAlign="left"
  >
    <TextField
      name="title"
      type="text"
      label="Name"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <TextField
      name="description"
      type="text"
      label="Size"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <div className="row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6">
        <div className="text-right">
          <Button.Group>
            {initialValues && initialValues.id && (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure?',
                    onOk: () => deletePackagingBox(initialValues.id),
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button>
              <Link to="/emarket/packaging_boxes">Dismiss</Link>
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

export default PackagingBoxForm;
