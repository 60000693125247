import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_LOCATIONS_API_ID = 'FETCH_LOCATIONS';

const {
  actionCreator: fetchLocationsAC,
  dataSelector: locationsRawSelector,
  errorSelector: fetchLocationsErrorSelector,
  isFetchingSelector: isFetchingLocationsSelector,
  updater: updateLocationsAC,
} = makeFetchAction(
  FETCH_LOCATIONS_API_ID,
  (params = { page: 1, per_page: 20 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/locations`,
      params,
    }),
    method: 'GET',
  })
);

const totalLocationsSelector = createSelector(
  locationsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  locationsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  locationsRawSelector,
  get('paging.per_page')
);

const locationsDataSelector = createSelector(
  locationsRawSelector,
  get('results')
);

export {
  FETCH_LOCATIONS_API_ID,
  fetchLocationsAC,
  locationsDataSelector,
  fetchLocationsErrorSelector,
  isFetchingLocationsSelector,
  updateLocationsAC,
  currentPageSelector,
  pageSizeSelector,
  totalLocationsSelector,
};
