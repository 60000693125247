export const convertFormData = values => {
  const keys = Object.keys(values);
  const data = new FormData();
  keys.forEach(key => {
    if (values[key]) {
      data.append(key, values[key]);
    } else {
      data.append(key, '');
    }
  });
  return data;
};

export default { convertFormData };
