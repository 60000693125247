import React from 'react';
import { Button } from 'antd';

const UserAction = ({
  action,
  isActivating,
  isDeactivating,
  activate,
  verify,
  deactivate,
  objectId,
}) => (
  <Button
    type={action === 'activate' || action === 'verify' ? 'primary' : 'danger'}
    disabled={action === 'activate' ? isActivating : isDeactivating}
    onClick={() => {
      if (window.confirm('Are you sure?')) {
        if (action === 'activate') activate(objectId);
        else if (action === 'deactivate') deactivate(objectId);
        else if (action === 'verify') verify(objectId);
      }
    }}
  >
    {action.toUpperCase()}
  </Button>
);

export default UserAction;
