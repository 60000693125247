export default number => {
  if (!number) {
    return 'RM0';
  }

  const formatedNumber = Math.abs(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (parseFloat(number) < 0) {
    return `-RM${formatedNumber}`;
  }

  return `RM${formatedNumber}`;
};
