import { ACTIONS } from 'redux-api-call';
import { combineEpics, ofType } from 'redux-observable';
import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';

import { UPDATE_WITHDRAWAL_API_ID } from '../../apiCalls/adminWithdrawals/edit';
import routerHistory from '../../routerHistory';

const handleEditWithdrawalSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_WITHDRAWAL_API_ID))),
    map(action => {
      routerHistory.goBack();
    })
  );

export default combineEpics(handleEditWithdrawalSuccess);
