import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get, map } from 'lodash/fp';
import { updatePostAC, isUpdatingSelector } from '../../apiCalls/posts/update';
import PostForm from './Form';
import {
  fetchPostAC,
  postDataSelector,
  isFetchingPostSelector,
} from '../../apiCalls/posts/get';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import { convertFormData } from '../../utils/data';

// Now it's loaded before the data is ready
const initialValues = ({ product, features, ...others }) => ({
  product: get('id')(product),
  features: map(get('id'))(features),
  ...others,
});

const AdminEditPost = ({ match, handleSubmit, formError, post }) => (
  <div className="">
    <PageTitle content="Post management" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>Edit post {match.params.id}</li>
      </ul>
    </div>

    <div className="bill">
      <div className="bill__block">
        <div className="bill__buttons row align-items-center">
          <div className="error">{formError}</div>
        </div>
        {post && (
          <PostForm
            onFinish={handleSubmit}
            initialValues={initialValues(post)}
            history
            pristine
            submitting
          />
        )}
      </div>
    </div>
  </div>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    post: postDataSelector(state),
    isFetching: isFetchingPostSelector(state),
  }),
  {
    updatePost: updatePostAC,
    fetchPost: fetchPostAC,
  }
);

const prepareProps = mapProps(({ updatePost, updatePostError, ...others }) => ({
  formError: updatePostError,
  handleSubmit: post => {
    const postId = others.match.params.id;
    if (post.image && post.image instanceof File) {
      const formData = convertFormData(post);
      return updatePost(postId, formData);
    }
    if (post.image || post.image === '') delete post.image;
    return updatePost(postId, post);
  },
  ...others,
}));

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchPost, match } = this.props;
      const { id } = match.params;
      fetchPost(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditPost);
