import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_PHONE_BILL_API_ID = 'FETCH_PHONE_BILL';

const {
  actionCreator: fetchPhoneBillsAC,
  dataSelector: phoneBillsRawSelector,
  errorSelector: fetchPhoneBillsErrorSelector,
  isFetchingSelector: isFetchingPhoneBillsSelector,
  updater: updatePhoneBillsAC,
} = makeFetchAction(FETCH_PHONE_BILL_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/phone_bills`,
    params,
  }),
  method: 'GET',
}));

const totalItemSelector = createSelector(
  phoneBillsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  phoneBillsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  phoneBillsRawSelector,
  get('paging.per_page')
);

const phoneBillsDataSelector = createSelector(
  phoneBillsRawSelector,
  get('phone_bills')
);

export {
  FETCH_PHONE_BILL_API_ID,
  fetchPhoneBillsAC,
  phoneBillsRawSelector,
  totalItemSelector,
  fetchPhoneBillsErrorSelector,
  isFetchingPhoneBillsSelector,
  updatePhoneBillsAC,
  currentPageSelector,
  pageSizeSelector,
  phoneBillsDataSelector,
};
