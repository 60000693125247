import { Button, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import {
  approveCreditRequestAC,
  approveCreditRequestErrorSelector,
  isApprovingCreditRequestSelector,
} from '../../apiCalls/creditSystem/approve';
import {
  creditRecordDataSelector,
  fetchCreditRecordAC,
  fetchCreditRecordErrorSelector,
  isFetchingCreditRecordSelector,
} from '../../apiCalls/creditSystem/get';
import {
  isRejectingCreditRequestSelector,
  rejectCreditRequestAC,
  rejectCreditRequestErrorSelector,
} from '../../apiCalls/creditSystem/reject';
import ApprovalButton from '../shared/ApprovalButton';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import Status from '../shared/Status';
import formatDate from '../../utils/formatDate';

const CreditRecordDetail = ({
  creditRecordData,
  isFetching,
  isApproving,
  isRejecting,
  match,
  approveTopup,
  rejectTopup,
  fetchCreditRecordDetail,
  errors,
}) => {
  React.useEffect(() => {
    fetchCreditRecordDetail(match.params.id);
  }, []); // eslint-disable-line

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }
  const creditRecord = get('credit_record')(creditRecordData);

  if (!creditRecord) {
    return null;
  }

  return (
    <div id="content">
      <div className="container">
        <div className="dashboard-page">
          <h1 className="page-title">Credit Record</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to="/payment/credits/records">Credit Records</Link>
              </li>
              <li>#{match.params.id}</li>
            </ul>
          </div>
          {
            <Descriptions
              title="Credit Record details"
              bordered
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="ID">
                {creditRecord.id}
              </Descriptions.Item>
              <Descriptions.Item label="Created At">
                {formatDate(creditRecord.created_at)}
              </Descriptions.Item>
              <Descriptions.Item label="Creator">
                {creditRecord.creator.name}
              </Descriptions.Item>
              <Descriptions.Item label="User">
                {creditRecord.user.name}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number">
                {creditRecord.user.contact_number}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                RM{creditRecord.amount}
              </Descriptions.Item>
              <Descriptions.Item label="Invoice">
                {creditRecord.invoice && creditRecord.invoice.origin_url ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={creditRecord.invoice.origin_url}
                  >
                    <img
                      height={500}
                      src={creditRecord.invoice.origin_url}
                      alt="invoice"
                    />
                  </a>
                ) : (
                  'N/A'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="File Reference">
                {creditRecord.description}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Status status={creditRecord.status} />
              </Descriptions.Item>
            </Descriptions>
          }
          {creditRecord.status === 'pending' && (
            <>
              <hr />
              <div className="text-right">
                <Button.Group>
                  <ApprovalButton
                    key="approve"
                    action="approve"
                    objectId={match.params.id}
                    isApproving={isApproving}
                    approve={approveTopup}
                  />
                  <ApprovalButton
                    key="reject"
                    action="reject"
                    objectId={match.params.id}
                    isRejecting={isRejecting}
                    reject={rejectTopup}
                  />
                </Button.Group>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
const enhance = compose(
  connect(
    state => ({
      creditRecordData: creditRecordDataSelector(state),
      isFetching: isFetchingCreditRecordSelector(state),
      isRejecting: isRejectingCreditRequestSelector(state),
      isApproving: isApprovingCreditRequestSelector(state),
      error: fetchCreditRecordErrorSelector(state),
      approvedError: approveCreditRequestErrorSelector(state),
      rejectedError: rejectCreditRequestErrorSelector(state),
    }),
    {
      fetchCreditRecordDetail: fetchCreditRecordAC,
      approveTopup: approveCreditRequestAC,
      rejectTopup: rejectCreditRequestAC,
    }
  )
);

export default enhance(CreditRecordDetail);
