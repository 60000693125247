import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_SHIPPING_PRICE_API_ID } from '../apiCalls/shippingPriceTables/create';
import { UPDATE_SHIPPING_PRICE_API_ID } from '../apiCalls/shippingPriceTables/update';
import { DELETE_SHIPPING_PRICE_API_ID } from '../apiCalls/shippingPriceTables/delete';
import routerHistory from '../routerHistory';
import {
  fetchShippingPricesAC,
  currentPageSelector,
} from '../apiCalls/shippingPriceTables/list';

const handleCreateShippingPriceSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_SHIPPING_PRICE_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/shipping_prices`);
    })
  );

const refreshListViewOnDelete = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(DELETE_SHIPPING_PRICE_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/shipping_prices`);
      const page = currentPageSelector(state$.value);
      return fetchShippingPricesAC({ page, per_page: 12 });
    })
  );

const handleEditShippingPriceSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_SHIPPING_PRICE_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/shipping_prices`);
    })
  );

export default combineEpics(
  handleCreateShippingPriceSuccess,
  refreshListViewOnDelete,
  handleEditShippingPriceSuccess
);
