import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import TextField from '../shared/form/TextField';

const PasswordForm = ({ handleSubmit, submitting }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={handleSubmit}
    labelAlign="left"
  >
    <TextField
      name="password"
      type="password"
      label="Password"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting}
          >
            Submit
          </button>
          <Link
            className="button button--secondary"
            to="/iam/staffs"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Link>
        </div>
      </div>
    </div>
  </Form>
);

export const buildFormData = values => {
  const { password } = values;
  return { password };
};

export default PasswordForm;
