import { Form } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  createCreditTransactionAC,
  isCreatingTransactionSelector,
} from '../../apiCalls/creditSystem/create';
import CreditRecordTypeSelect from '../shared/CreditRecordTypeSelect';
import PageTitle from '../shared/PageTitle';
import TextField from '../shared/form/TextField';

const CreditTransactionCreate = ({
  history,
  submitting,
  createCreditTransaction,
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const userId = params.user_id;
  const userName = params.user_name;

  if (!userId || !userName) {
    return <PageTitle content="Invalid User" />;
  }

  return (
    <>
      <PageTitle content="New Credit Record" />
      <div className="bill">
        <div className="bill__block">
          <Form
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 12 }}
            initialValues={{
              record_type: 'credit',
              user_name: userName,
            }}
            onFinish={formData =>
              createCreditTransaction({ ...formData, user_id: userId })
            }
            labelAlign="left"
            {...FORM_ITEM_LAYOUT}
          >
            <TextField name="user_name" type="text" label="User" disabled />
            <CreditRecordTypeSelect name="record_type" label="Record Type" />
            <TextField name="amount" type="number" label="Amount" />
            <TextField name="description" type="text" label="File Reference" />
            <div className="bill__buttons row align-items-center">
              <div className="col-md-8 order-md-2">
                <div className="_button-group text-right">
                  <button
                    type="button"
                    className="button button--secondary"
                    onClick={() => history.goBack()}
                  >
                    Dismiss
                  </button>
                  <button
                    type="submit"
                    className="button button--primary"
                    disabled={submitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isCreatingTransactionSelector(state),
  }),
  {
    createCreditTransaction: createCreditTransactionAC,
  }
);

export default connectWithRedux(CreditTransactionCreate);
