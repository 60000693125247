import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { updateAdminPhoneBillAC } from '../../apiCalls/adminPhoneBill/get';
import { APPROVE_PHONE_BILL_API_ID } from '../../apiCalls/adminPhoneBill/approve';

const handleApprovePhoneBillSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(APPROVE_PHONE_BILL_API_ID)
      )
    ),
    tap(action => {
      console.log(action);
    }),
    map(action => {
      const phone_bill = get('payload.json.phone_bill')(action);
      return updateAdminPhoneBillAC(phone_bill, store$.value);
    })
  );

export default combineEpics(handleApprovePhoneBillSuccess);
