import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { DELETE_USER_API_ID } from '../../apiCalls/adminUsers/delete';
import routerHistory from '../../routerHistory';

const handleDeleteSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(DELETE_USER_API_ID)
      )
    ),
    map(() => {
      routerHistory.push(`/iam/users`);
    })
  );

export default combineEpics(handleDeleteSuccess);
