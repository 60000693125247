import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const FETCH_ORDER_API_ID = 'FETCH_ORDER';

const {
  actionCreator: fetchOrderAC,
  dataSelector: rawOrderDataSelector,
  isFetchingSelector: isFetchingOrderSelector,
  errorSelector: fetchingOrderErrorSelector,
} = makeFetchAction(FETCH_ORDER_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/orders/${id}`,
  method: 'GET',
}));

const orderDataSelector = rawOrderDataSelector;

export {
  FETCH_ORDER_API_ID,
  fetchOrderAC,
  orderDataSelector,
  isFetchingOrderSelector,
  fetchingOrderErrorSelector,
};
