import React from 'react';
import { Table } from 'antd';

const DataTable = ({
  dataFetcher,
  dataSource,
  currentPage,
  totalItems,
  filters,
  loading,
  columns,
  pageSize,
  ...others
}) => (
  <Table
    style={{ marginTop: 20 }}
    rowKey="id"
    bordered
    dataSource={dataSource}
    columns={columns.filter(o => o.visible === true || o.visible !== false)}
    loading={loading}
    pagination={{
      position: 'both',
      current: currentPage,
      total: totalItems,
      hideOnSinglePage: true,
      pageSize,
      onChange: page => {
        dataFetcher({ page, ...filters });
      },
    }}
    {...others}
  />
);

export default DataTable;
