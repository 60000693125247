import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const FETCH_WALLET = 'DASHBOARD/FETCH_WALLET';

const {
  actionCreator: fetchWalletAC,
  dataSelector: walletDataSelector,
  errorSelector: fetchWalletErrorSelector,
  isFetchingSelector: isFetchingWalletSelector,
} = makeFetchAction(FETCH_WALLET, userId => ({
  endpoint: `${API_ADMIN_ENDPOINT}/wallets/${userId}`,
  method: 'GET',
}));

export {
  FETCH_WALLET,
  fetchWalletAC,
  walletDataSelector,
  fetchWalletErrorSelector,
  isFetchingWalletSelector,
};
