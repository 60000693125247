import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import {
  efbankBalanceSelector,
  fetchBalanceAC,
} from '../../apiCalls/adminBalance/list';
import {
  efbankTransactionsDataSelector,
  fetchEfbankTransactionsAC,
  fetchEfbankTransactionsErrorSelector,
  isFetchingEfbankTransactionsSelector,
  totalEfbankTransactionsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/transactions/efbank';
import { formatDate } from '../../utils/formatDate';
import {
  transactionTypeColors,
  transactionTypeConfig,
} from '../../utils/constants';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import formatAmount from '../../utils/formatAmount';

const columns = [
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'User',
    dataIndex: ['user', 'name'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type, obj) => (
      <>
        <Tag color={transactionTypeColors[type]}>
          {transactionTypeConfig(type, obj.service_code, obj.transaction_type)}
        </Tag>
        {obj.parent_id ? <Tag color="red">Refund</Tag> : null}
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (value, obj) => {
      const formatedAmount = formatAmount(value);
      switch (obj.type) {
        case 'WithdrawalTransaction':
          if (obj.transaction_type === 'withdrawal') {
            return `-${formatedAmount}`;
          }
          return `+${formatedAmount}`;

        case 'TopupTransaction':
          return `+${formatedAmount}`;
        default:
          return formatedAmount;
      }
    },
    align: 'right',
  },
  {
    title: 'Balance',
    dataIndex: 'efbank_balance',
    render: formatAmount,
    align: 'right',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      return (
        <Button.Group>
          <Button>
            <Link to={`/payment/transactions/${record.id}`}>Detail</Link>
          </Button>
        </Button.Group>
      );
    },
  },
];

const Heading = ({ children }) => {
  return (
    <h2 style={{ fontWeight: 500, fontSize: 24, paddingBottom: 12 }}>
      {children}
    </h2>
  );
};

const AdminRequests = ({
  fetchBalance,
  efbankBalance,
  transactions,
  fetchTransactions,
  loading,
  error,
  history,
  ...others
}) => {
  useEffect(() => {
    fetchTransactions();
    fetchBalance();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle content="EFBank" />
      <Heading>Current Balance: {formatAmount(efbankBalance)}</Heading>
      {error && <ErrorMessage />}
      <DataTable
        dataSource={transactions}
        dataFetcher={fetchTransactions}
        loading={loading}
        columns={columns}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    transactions: efbankTransactionsDataSelector(state),
    loading: isFetchingEfbankTransactionsSelector(state),
    error: fetchEfbankTransactionsErrorSelector(state),
    totalItems: totalEfbankTransactionsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    efbankBalance: efbankBalanceSelector(state),
  }),
  {
    fetchTransactions: fetchEfbankTransactionsAC,
    fetchBalance: fetchBalanceAC,
  }
);

export default connectToRedux(AdminRequests);
