import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { map, find, get } from 'lodash/fp';
import { Layout, Menu } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import { withProps, compose } from 'recompose';
import { connect } from 'react-redux';
import fullMenuData from './data';
import staffData from './staff';

const menuWithAuthorization = roles => {
  if (roles && roles.includes('admin')) {
    return fullMenuData;
  }

  return staffData;
};

const SideMenu = ({ selectedKey, roles }) => (
  <Layout.Sider
    theme="light"
    breakpoint="sm"
    width="300"
    style={{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
    }}
  >
    <Menu style={{ border: 'none' }} selectedKeys={[selectedKey]}>
      {map(menu =>
        get('items')(menu) ? (
          <Menu.ItemGroup
            key={menu.key}
            title={
              <>
                <strong style={{ color: '#232d62' }}>
                  <menu.icon
                    style={{ marginRight: 5, verticalAlign: 'baseline' }}
                  />
                  {menu.title}
                </strong>
              </>
            }
          >
            {map(data =>
              data.location && data.location.startsWith('https') ? (
                <Menu.Item key={data.key} style={{ marginLeft: 8 }}>
                  <a target="_blank" rel="noreferrer" href={data.location}>
                    {data.label} <ArrowRightOutlined />{' '}
                  </a>
                </Menu.Item>
              ) : (
                data.location && (
                  <Menu.Item key={data.key} style={{ marginLeft: 8 }}>
                    <NavLink to={data.location}>{data.label}</NavLink>
                  </Menu.Item>
                )
              )
            )(menu.items)}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            <NavLink to={menu.location}>{menu.label}</NavLink>
          </Menu.Item>
        )
      )(menuWithAuthorization(roles))}
    </Menu>
  </Layout.Sider>
);

const enhance = compose(
  connect(state => ({
    roles: get('adminSession.user.roles')(state),
  })),
  withRouter,
  withProps(state => ({
    selectedKey: get('key')(
      find({ location: state.location.pathname })(fullMenuData)
    ),
  }))
);

export default enhance(SideMenu);
