import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLoggingInSelector } from '../../apiCalls/adminSessions/create';

const Logout = props => {
  const { isLoggingIn } = props;

  if (isLoggingIn) {
    return <Redirect to="/" push />;
  }

  return <Redirect to="/" push />;
};

const connectWithRedux = connect(
  state => ({
    isLoggingIn: isLoggingInSelector(state),
  }),
  {}
);

export default connectWithRedux(Logout);
