import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_UTILITY_BILL_API_ID = 'UPDATE_UTILITY_BILL';

const {
  actionCreator: updateUtilityBillAC,
  dataSelector: updateUtilityBillRawDataSelector,
  errorSelector: updateUtilityBillErrorSelector,
  isFetchingSelector: isUpdatingUtilityBillSelector,
} = makeFetchAction(UPDATE_UTILITY_BILL_API_ID, (id, phone_bill) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/utility_bills/${id}`,
  method: 'PATCH',
  notify: true,
  body: phone_bill,
}));

const updateUtilityBillDataSelector = createSelector(
  updateUtilityBillRawDataSelector,
  get('utility_bill')
);

export {
  UPDATE_UTILITY_BILL_API_ID,
  updateUtilityBillAC,
  updateUtilityBillDataSelector,
  updateUtilityBillErrorSelector,
  isUpdatingUtilityBillSelector,
};
