import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

import {
  fetchShippingPricesAC,
  shippingPricesDataSelector,
  fetchShippingPricesErrorSelector,
  isFetchingShippingPricesSelector,
  totalShippingPricesSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/shippingPriceTables/list';
import { timeAgo } from '../../utils/formatDate';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';
import formatAmount from '../../utils/formatAmount';

const columnsGenerator = () => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Pick up location',
    dataIndex: 'pick_up_location',
    render: value => value.name,
  },
  {
    title: 'Drop off location',
    dataIndex: 'drop_off_location',
    render: value => value.name,
  },
  {
    title: 'Box',
    dataIndex: 'box',
    render: value => `${value.title} / ${value.description}`,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: formatAmount,
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button>
        <Link to={`/emarket/shipping_prices/${record.id}/edit`}>Edit</Link>
      </Button>
    ),
  },
];

const ShippingPrices = ({
  fetchShippingPrices,
  shippingPrices,
  loading,
  errors,
  history,
  ...others
}) => {
  React.useEffect(() => {
    fetchShippingPrices();
  }, []);

  if (loading && !shippingPrices) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div id="content">
      <PageTitle content="ShippingPrices" />
      <div style={{ textAlign: 'right' }}>
        <button
          className="button button--primary"
          onClick={() => history.push(`/emarket/shipping_prices/add`)}
        >
          Add New Shipping Price <i className="fas fa-plus" />
        </button>
      </div>
      <DataTable
        columns={columnsGenerator()}
        dataSource={shippingPrices}
        dataFetcher={fetchShippingPrices}
        {...others}
      />
    </div>
  );
};

const enhance = connect(
  state => ({
    shippingPrices: shippingPricesDataSelector(state),
    loading: isFetchingShippingPricesSelector(state),
    errors: fetchShippingPricesErrorSelector(state),
    totalItems: totalShippingPricesSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchShippingPrices: fetchShippingPricesAC,
  }
);

export default enhance(ShippingPrices);
