import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_PAYMENT_API_ID = 'REJECT_PAYMENT';

const {
  actionCreator: rejectPaymentAC,
  dataSelector: rejectPaymentDataSelector,
  errorSelector: rejectPaymentErrorSelector,
  isFetchingSelector: isRejectingPaymentSelector,
} = makeFetchAction(REJECT_PAYMENT_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/payments/${id}/reject`,
  method: 'POST',
  notify: true,
}));

export {
  REJECT_PAYMENT_API_ID,
  rejectPaymentAC,
  rejectPaymentDataSelector,
  rejectPaymentErrorSelector,
  isRejectingPaymentSelector,
};
