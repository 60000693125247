import { Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';

import {
  currentPageSelector,
  fetchTransactionsAC,
  fetchTransactionsErrorSelector,
  isFetchingTransactionsSelector,
  pageSizeSelector,
  totalTransactionsSelector,
  transactionsDataSelector,
} from '../../apiCalls/transactions/list';
import {
  efpayBalanceSelector,
  efpayInitialSelector,
  fetchBalanceAC,
} from '../../apiCalls/adminBalance/list';
import { formatDate } from '../../utils/formatDate';
import {
  transactionTypeColors,
  transactionTypeConfig,
} from '../../utils/constants';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import formatAmount from '../../utils/formatAmount';

const columns = efpayInitial => [
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'User',
    dataIndex: ['user', 'name'],
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type, obj) => (
      <>
        <Tag color={transactionTypeColors[type]}>
          {transactionTypeConfig(type, obj.service_code, obj.transaction_type)}
        </Tag>
        {obj.parent_id ? <Tag color="red">Refund</Tag> : null}
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (value, obj) => {
      const formatedAmount = formatAmount(value);
      switch (obj.type) {
        case 'WithdrawalTransaction':
          if (obj.transaction_type === 'withdrawal') {
            return `+${formatedAmount}`;
          }
          return `-${formatedAmount}`;

        case 'TopupTransaction':
          return `-${formatedAmount}`;
        default:
          return formatedAmount;
      }
    },
    align: 'right',
  },
  {
    title: 'Balance',
    dataIndex: 'efbank_balance',
    render: currentBalance => formatAmount(efpayInitial - currentBalance),
    align: 'right',
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      return (
        <Button.Group>
          <Button>
            <Link to={`/payment/transactions/${record.id}`}>Detail</Link>
          </Button>
        </Button.Group>
      );
    },
  },
];

const Heading = ({ children }) => {
  return (
    <h2 style={{ fontWeight: 500, fontSize: 24, paddingBottom: 12 }}>
      {children}
    </h2>
  );
};

const AdminRequests = ({
  fetchBalance,
  efpayBalance,
  efpayInitial,
  transactions,
  fetchTransactions,
  loading,
  error,
  history,
  ...others
}) => {
  const params = { type: ['WithdrawalTransaction', 'TopupTransaction'] };

  useEffect(() => {
    fetchTransactions({ filter: params });
    fetchBalance();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PageTitle content="EFPay Account" />
      <Heading>Initial Amount: {formatAmount(efpayInitial)}</Heading>
      <Heading>Current Balance: {formatAmount(efpayBalance)}</Heading>
      {error && <ErrorMessage />}
      <DataTable
        dataSource={transactions}
        dataFetcher={fetchTransactions}
        loading={loading}
        filters={{ filter: params }}
        columns={columns(efpayInitial)}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    transactions: transactionsDataSelector(state),
    loading: isFetchingTransactionsSelector(state),
    error: fetchTransactionsErrorSelector(state),
    totalItems: totalTransactionsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    efpayBalance: efpayBalanceSelector(state),
    efpayInitial: efpayInitialSelector(state),
  }),
  {
    fetchTransactions: fetchTransactionsAC,
    fetchBalance: fetchBalanceAC,
  }
);

export default connectToRedux(AdminRequests);
