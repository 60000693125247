import React from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';

import { deleteProductAC } from '../../apiCalls/products/delete';

const DeleteButton = ({ id, deleteProduct }) => {
  return (
    <Button
      type="danger"
      onClick={() =>
        Modal.confirm({
          title: 'Are you sure?',
          onOk: () => deleteProduct(id),
        })
      }
    >
      Delete
    </Button>
  );
};

const connectWithRedux = connect(null, {
  deleteProduct: deleteProductAC,
});

export default connectWithRedux(DeleteButton);
