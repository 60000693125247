import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const DEACTIVATE_USER_API_ID = 'DEACTIVATE_USER';

const {
  actionCreator: deactivateUserAC,
  dataSelector: deactivateUserDataSelector,
  errorSelector: deactivateUserErrorSelector,
  isFetchingSelector: isDeactivatingSelector,
} = makeFetchAction(DEACTIVATE_USER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}/deactivate`,
  method: 'PATCH',
  notify: true,
}));

export {
  DEACTIVATE_USER_API_ID,
  deactivateUserAC,
  deactivateUserDataSelector,
  deactivateUserErrorSelector,
  isDeactivatingSelector,
};
