import { Link } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { deleteShippingPriceAC } from '../../apiCalls/shippingPriceTables/delete';
import {
  fetchShippingPriceAC,
  shippingPriceDataSelector,
  isFetchingShippingPriceSelector,
} from '../../apiCalls/shippingPriceTables/get';
import {
  updateShippingPriceAC,
  isUpdatingSelector,
} from '../../apiCalls/shippingPriceTables/update';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import ShippingPriceForm from './Form';
import PageTitle from '../shared/PageTitle';

const AdminEditShippingPrice = ({
  match,
  handleSubmit,
  submitting,
  formError,
  shippingPrice,
  deleteShippingPrice,
}) => (
  <>
    <PageTitle content="Shipping Prices" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/shipping_prices">Shipping Prices</Link>
        </li>
        <li>Edit shipping price: {match.params.id}</li>
      </ul>
    </div>

    <div className="">
      <div className="">
        <div className="row align-items-center">
          <div className="error">{formError}</div>
        </div>
        {shippingPrice && (
          <ShippingPriceForm
            onFinish={handleSubmit}
            deleteShippingPrice={deleteShippingPrice}
            initialValues={{
              id: shippingPrice.id,
              pick_up_location: shippingPrice.pick_up_location.id,
              drop_off_location: shippingPrice.drop_off_location.id,
              box: shippingPrice.box.id,
              price: shippingPrice.price,
            }}
            submitting={submitting}
          />
        )}
      </div>
    </div>
  </>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    shippingPrice: shippingPriceDataSelector(state),
    isFetching: isFetchingShippingPriceSelector(state),
  }),
  {
    updateShippingPrice: updateShippingPriceAC,
    fetchShippingPrice: fetchShippingPriceAC,
    deleteShippingPrice: deleteShippingPriceAC,
  }
);

const prepareProps = mapProps(
  ({ updateShippingPrice, updateShippingPriceError, ...others }) => ({
    formError: updateShippingPriceError,
    handleSubmit: shippingPrice => {
      const shippingPrice_id = others.match.params.id;
      return updateShippingPrice(shippingPrice_id, shippingPrice);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchShippingPrice, match } = this.props;
      const { id } = match.params;
      fetchShippingPrice(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditShippingPrice);
