import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Modal, Input } from 'antd';
import { compose, lifecycle, mapProps } from 'recompose';
import { map } from 'lodash/fp';
import { connect } from 'react-redux';
import SelectField from '../shared/form/SelectField';
import ShippingMethodSelect from '../shared/ShippingMethodSelect';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  fetchLocationsAC,
  locationsDataSelector,
  isFetchingLocationsSelector,
} from '../../apiCalls/locations/list';

const PriceForm = ({
  handleSubmit,
  locations,
  initialValues,
  deletePrice,
  isLoadingLocations,
}) => (
  <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} 
    onFinish={handleSubmit} 
    initialValues={initialValues}
    labelAlign="left"
  >
    <Form.Item
      name="method"
      type="number"
      label="Method"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <ShippingMethodSelect />
    </Form.Item>
    <Form.Item
      name="source"
      label="Source"
      {...FORM_ITEM_LAYOUT}
      required
    >
      { isLoadingLocations || <SelectField options={locations} /> }
    </Form.Item>
    <Form.Item
      name="destination"
      label="Destination"
      {...FORM_ITEM_LAYOUT}
      required
    >
      { isLoadingLocations || <SelectField options={locations} /> }
    </Form.Item>
    <Form.Item
      name="price"
      type="text"
      label="Price"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <Input />
    </Form.Item>

    <div className="row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6">
        <div className="text-right">
          <Button.Group>
            {initialValues && initialValues.id && (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure?',
                    onOk: () => deletePrice(initialValues.id),
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button>
              <Link to="/emarket/routes-and-pricing">Dismiss</Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

const enhance = compose(
  connect(
    state => ({
      locations: locationsDataSelector(state),
      isLoadingLocations: isFetchingLocationsSelector(state),
    }),
    {
      fetchLocations: fetchLocationsAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchLocations();
    },
  }),
  mapProps(({ locations, ...others }) => ({
    locations: map(location => ({ value: location.id, label: location.name }))(
      locations
    ),
    ...others,
  }))
);

export default enhance(PriceForm);
