import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_PACKAGING_BOX_API_ID = 'GET_PACKAGING_BOX';

const {
  actionCreator: fetchPackagingBoxAC,
  dataSelector: packagingBoxRawDataSelector,
  errorSelector: fetchPackagingBoxErrorSelector,
  isFetchingSelector: isFetchingPackagingBoxSelector,
} = makeFetchAction(GET_PACKAGING_BOX_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/packagingboxes/${id}`,
  method: 'GET',
}));

const packagingBoxDataSelector = packagingBoxRawDataSelector;

export {
  GET_PACKAGING_BOX_API_ID,
  fetchPackagingBoxAC,
  fetchPackagingBoxErrorSelector,
  isFetchingPackagingBoxSelector,
  packagingBoxDataSelector,
};
