import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_USERS_API_ID = 'FETCH_ADMIN_USERS';

const {
  actionCreator: fetchAdminUsersAC,
  dataSelector: usersRawSelector,
  errorSelector: fetchAdminUsersErrorSelector,
  isFetchingSelector: isFetchingAdminUsersSelector,
  updater: updateAdminUsersAC,
} = makeFetchAction(FETCH_ADMIN_USERS_API_ID, (params = {}) => {
  return {
    endpoint: generateEndpoint({
      host: `${API_ADMIN_ENDPOINT}/users`,
      params,
    }),
    method: 'GET',
  };
});

const totalUsersSelector = createSelector(
  usersRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  usersRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  usersRawSelector,
  get('paging.per_page')
);

const adminUsersDataSelector = createSelector(
  usersRawSelector,
  get('users')
);

export {
  FETCH_ADMIN_USERS_API_ID,
  fetchAdminUsersAC,
  adminUsersDataSelector,
  fetchAdminUsersErrorSelector,
  isFetchingAdminUsersSelector,
  updateAdminUsersAC,
  currentPageSelector,
  pageSizeSelector,
  totalUsersSelector,
};
