import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const CREATE_PROVIDER_API_ID = 'CREATE_PROVIDER';

const {
  actionCreator: createProviderAC,
  dataSelector: createProviderDataSelector,
  errorSelector: createProviderErrorSelector,
  isFetchingSelector: isCreatingProviderSelector,
} = makeFetchAction(CREATE_PROVIDER_API_ID, provider => ({
  endpoint: `${API_ADMIN_ENDPOINT}/providers`,
  method: 'POST',
  notify: true,
  body: provider,
}));

export {
  CREATE_PROVIDER_API_ID,
  createProviderAC,
  createProviderDataSelector,
  createProviderErrorSelector,
  isCreatingProviderSelector,
};
