import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_PRODUCTS_API_ID = 'FETCH_PRODUCTS';

const {
  actionCreator: fetchProductsAC,
  dataSelector: productsRawSelector,
  errorSelector: fetchProductsErrorSelector,
  isFetchingSelector: isFetchingProductsSelector,
  updater: updateProductsAC,
} = makeFetchAction(
  FETCH_PRODUCTS_API_ID,
  (params = { page: 1, ordering: '-created' }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/products`,
      params,
    }),
    method: 'GET',
  })
);

const totalProductsSelector = createSelector(
  productsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  productsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  productsRawSelector,
  get('paging.per_page')
);

const productsDataSelector = createSelector(
  productsRawSelector,
  get('results')
);

export {
  FETCH_PRODUCTS_API_ID,
  fetchProductsAC,
  productsDataSelector,
  fetchProductsErrorSelector,
  isFetchingProductsSelector,
  updateProductsAC,
  currentPageSelector,
  pageSizeSelector,
  totalProductsSelector,
};
