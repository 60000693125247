import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from 'utils/envConfig';

const CANCEL_ORDER_API_ID = 'ECOM_CANCEL_ORDER';

const {
  actionCreator: cancelOrderAC,
  dataSelector: cancelOrderDataSelector,
  errorSelector: cancelOrderErrorSelector,
  isFetchingSelector: isSubmittingOrderSelector,
} = makeFetchAction(CANCEL_ORDER_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/ecomorders/${id}`,
  method: 'PUT',
  notify: true,
  body: { status: 1002 },
}));

export {
  CANCEL_ORDER_API_ID,
  cancelOrderAC,
  cancelOrderDataSelector,
  cancelOrderErrorSelector,
  isSubmittingOrderSelector,
};
