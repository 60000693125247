import { Alert, Button, Form, Input } from 'antd';
import { connect } from 'react-redux';
import React from 'react';

import {
  createSessionAC,
  isLoggingInSelector,
  loginErrorSelector,
} from '../../apiCalls/adminSessions/create';
import Logo from '../shared/Logo';
import background from '../../images/background.jpg';

const formItemLayout = {
  labelcol: { span: 4 },
  wrappercol: { span: 20 },
};

const Login = ({ isLoggingIn, loginError, createSession }) => {
  return (
    <div className="access-page">
      <div className="access-page__wrap">
        <div className="access-page__main">
          <div className="access-page__content">
            <div className="container">
              <div className="access-page__box">
                <div className="access-page__box__wrap">
                  <h1 className="access-page__title text-center">
                    &nbsp; Sign in to EFPay Dashboard
                  </h1>
                  <Form
                    {...formItemLayout}
                    onFinish={createSession}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Username"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid email',
                        },
                        {
                          required: true,
                          message: 'Please enter your email',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your password',
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>

                    {loginError && (
                      <Form.Item>
                        <Alert message={loginError} type="warning" />
                      </Form.Item>
                    )}

                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <Button type="primary" htmlType="submit">
                        <i className="fas fa-sign-in-alt" />
                        &nbsp;
                        {isLoggingIn ? 'Signing in...' : 'Sign in'}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="_bg-full"
          style={{ background: `url('${background}') top left` }}
        />
      </div>
      <div className="access-page__footer text-center">
        <div className="container">
          <Logo height={100} />
        </div>
        <div className="container" style={{ marginTop: '20px' }}>
          <p>The most efficient way to trade seafood in South East Asia.</p>
          <p>
            EFisheries.com is a seafood trading marketplace where you can trade
            your seafood online anytime you want
          </p>
          <p>2022 © Sing Seng Enterprise Sdn. Bhd.</p>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    loginError: loginErrorSelector(state),
    isLoggingIn: isLoggingInSelector(state),
  }),
  { createSession: createSessionAC }
);

export default connectWithRedux(Login);

