import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_FEATURE_API_ID = 'GET_FEATURE';

const {
  actionCreator: fetchFeatureAC,
  dataSelector: featureRawDataSelector,
  errorSelector: fetchFeatureErrorSelector,
  isFetchingSelector: isFetchingFeatureSelector,
} = makeFetchAction(GET_FEATURE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/features/${id}`,
  method: 'GET',
}));

const featureDataSelector = featureRawDataSelector;

export {
  GET_FEATURE_API_ID,
  fetchFeatureAC,
  fetchFeatureErrorSelector,
  isFetchingFeatureSelector,
  featureDataSelector,
};
