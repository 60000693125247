import { Link, useLocation } from 'react-router-dom';
import { FileImageOutlined } from '@ant-design/icons';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';
import queryString from 'query-string';

import {
  fetchAdminPaymentsAC,
  adminPaymentsDataSelector,
  fetchAdminPaymentsErrorSelector,
  isFetchingAdminPaymentsSelector,
  currentPageSelector,
  totalPaymentItemSelector,
  pageSizeSelector,
} from '../../apiCalls/adminPayments/list';
import { formatDate } from '../../utils/formatDate';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columnsGenerator = () => [
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Sender',
    dataIndex: ['requester', 'name'],
  },
  {
    title: 'Receiver',
    dataIndex: ['receiver', 'name'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    render: value => value && value.thumb_url && <FileImageOutlined />,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) =>
      record && (
        <Link
          to={`/payment/payments/${record.id}`}
          className="button button--small button--red-line"
        >
          Detail
        </Link>
      ),
  },
];

const AdminPayments = ({
  paymentRequests,
  fetchAdminPayments,
  errors,
  ...others
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  React.useEffect(() => {
    fetchAdminPayments({ filter: params });
    // eslint-disable-next-line
  }, [location, fetchAdminPayments]);

  if (errors) {
    return <ErrorMessage />;
  }

  console.log('paymentRequests', paymentRequests);
  console.log('others', others);

  return (
    <div id="content">
      <PageTitle content="Payment Requests" />
      <DataTable
        columns={columnsGenerator()}
        dataSource={paymentRequests}
        dataFetcher={fetchAdminPayments}
        filters={{ filter: params }}
        {...others}
      />
    </div>
  );
};

const enhance = compose(
  connect(
    state => ({
      paymentRequests: adminPaymentsDataSelector(state),
      loading: isFetchingAdminPaymentsSelector(state),
      errors: fetchAdminPaymentsErrorSelector(state),
      totalItems: totalPaymentItemSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchAdminPayments: fetchAdminPaymentsAC,
    }
  )
);

export { columnsGenerator };

export default enhance(AdminPayments);
