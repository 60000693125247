import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'lodash/fp';
import React from 'react';

import {
  adminUsersDataSelector,
  fetchAdminUsersAC,
  fetchAdminUsersErrorSelector,
  isFetchingAdminUsersSelector,
  totalUsersSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/adminUsers/list';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  // {
  //   title: 'Person in charge',
  //   dataIndex: 'person_in_charge',
  // },
  // {
  //   title: 'Area',
  //   dataIndex: 'area',
  // },
  // {
  //   title: 'Address',
  //   dataIndex: 'address',
  // },
  {
    title: 'Phone number',
    dataIndex: 'contact_number',
    render: (text, record) =>
      `+(${record.country_code}) ${record.contact_number}`,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button>
        <Link to={`/payment/credits/users/${record.id}`}>Credit History</Link>
      </Button>
    ),
  },
];

const UserCreditList = ({ fetchAdminUsers, users, errors, ...others }) => {
  const searchUsers = debounce(500, fetchAdminUsers);

  React.useEffect(() => {
    fetchAdminUsers({ filter: { status: 'verified' } });
  }, [fetchAdminUsers]);

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div>
      <PageTitle content="Credit System" />
      <div className="row">
        <div className="col-sm-6">
          <div className="search-box">
            <input
              type="text"
              placeholder="Find users by name or contact number"
              onChange={e => searchUsers({ query: e.target.value })}
            />
            <button className="button button--primary" type="button">
              🔎
            </button>
          </div>
        </div>
      </div>
      <DataTable
        dataFetcher={fetchAdminUsers}
        dataSource={users}
        filters={{ filter: { status: 'verified' } }}
        columns={columns}
        {...others}
      />
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    users: adminUsersDataSelector(state),
    errors: fetchAdminUsersErrorSelector(state),
    loading: isFetchingAdminUsersSelector(state),
    totalItems: totalUsersSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  { fetchAdminUsers: fetchAdminUsersAC }
);

export default connectWithRedux(UserCreditList);
