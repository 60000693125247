import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateProductAC,
  isUpdatingSelector,
} from 'apiCalls/ecomProducts/update';
import ProductForm from './Form';
import {
  fetchProductAC,
  productDataSelector,
  isFetchingProductSelector,
} from 'apiCalls/ecomProducts/get';
import {
  fetchProductImagesAC,
  productImagesDataSelector,
} from 'apiCalls/productImages/list';
import { convertFormData } from '../../utils/data';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const AdminEditProduct = ({
  match,
  fetchProduct,
  fetchProductImages,
  updateProduct,
  uploadProductError,
  product,
  submitting,
  isFetching,
  errors,
}) => {
  React.useEffect(() => {
    fetchProduct(match.params.id);
    fetchProductImages(match.params.id);
  }, [match.params.id, fetchProduct, fetchProductImages]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  if (!product) {
    return null;
  }

  const product_id = match.params.id;
  const handleSubmit = data => {
    if (
      data.image &&
      data.image[0] &&
      data.image[0].uid !== -1 &&
      data.image[0].originFileObj instanceof File
    ) {
      const formData = convertFormData({
        ...data,
        image: data.image[0].originFileObj,
      });
      return updateProduct(product_id, formData);
    }
    if (data.image || data.image === '') delete data.image;
    return updateProduct(product_id, data);
  };

  return (
    <>
      <PageTitle content="Ecom Products" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/ecommerce/products">Ecom Products</Link>
          </li>
          <li>Edit product: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        <div className="">
          <div className="row align-items-center">
            <div className="error">{uploadProductError}</div>
          </div>
          {product && (
            <ProductForm
              onFinish={handleSubmit}
              submitting={submitting}
              initialValues={{
                ...product,
                image: [
                  {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: product.image,
                  },
                ],
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    product: productDataSelector(state),
    productImages: productImagesDataSelector(state),
    isFetching: isFetchingProductSelector(state),
  }),
  {
    updateProduct: updateProductAC,
    fetchProduct: fetchProductAC,
    fetchProductImages: fetchProductImagesAC,
  }
);

export default connectWithRedux(AdminEditProduct);
