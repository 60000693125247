import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import { formatDate } from '../../utils/formatDate';
import Status from '../shared/Status';
import ErrorMessage from '../shared/ErrorMessage';
import {
  fetchTransfersAC,
  transfersDataSelector,
  fetchTransfersErrorSelector,
  isFetchingTransfersSelector,
  currentPageSelector,
  totalPaymentItemSelector,
  pageSizeSelector,
} from '../../apiCalls/adminTransfers/list';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Requester',
    dataIndex: ['requester', 'name'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
];

const Transfers = ({ transfers, fetchTransfers, ...others }) => (
  <div id="content">
    <PageTitle content="Revenue Transfer Requests" />
    <DataTable
      dataFetcher={fetchTransfers}
      dataSource={transfers}
      columns={columns}
      {...others}
    />
  </div>
);

const enhance = compose(
  connect(
    state => ({
      loading: isFetchingTransfersSelector(state),
      errors: fetchTransfersErrorSelector(state),
      transfers: transfersDataSelector(state),
      totalItems: totalPaymentItemSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchTransfers: fetchTransfersAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchTransfers();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Transfers);
