import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { UPDATE_POST_API_ID } from '../apiCalls/posts/update';
import { CREATE_POST_API_ID } from '../apiCalls/posts/create';
import { ENABLE_POST_API_ID } from '../apiCalls/posts/enable';
import { DISABLE_POST_API_ID } from '../apiCalls/posts/disable';
import { CANCEL_POST_API_ID } from '../apiCalls/posts/cancel';
import { fetchPostsAC, currentPageSelector } from '../apiCalls/posts/list';
import routerHistory from '../routerHistory';

const handleCreateProductSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(CREATE_POST_API_ID)
      )
    ),
    map(() => {
      routerHistory.push('/emarket/posts');
    })
  );

const handleEditPostSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(UPDATE_POST_API_ID)
      )
    ),
    map(() => {
      routerHistory.push('/emarket/posts');
    })
  );

const handleCancelPostSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(CANCEL_POST_API_ID)
      )
    ),
    map(() => {
      routerHistory.push('/emarket/posts');
    })
  );

const refreshListViewOnEnable = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(ENABLE_POST_API_ID)
      )
    ),
    map(() => {
      const page = currentPageSelector(state$.value);
      return fetchPostsAC({ page, per_page: 10 });
    })
  );

const refreshListViewOnDisable = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(DISABLE_POST_API_ID)
      )
    ),
    map(() => {
      const page = currentPageSelector(state$.value);
      return fetchPostsAC({ page, per_page: 10 });
    })
  );
export default combineEpics(
  handleEditPostSuccess,
  handleCreateProductSuccess,
  handleCancelPostSuccess,
  refreshListViewOnDisable,
  refreshListViewOnEnable
);
