import React from 'react';
import { Statistic, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { compose, mapProps, lifecycle } from 'recompose';
import { mapKeys, camelCase } from 'lodash/fp';
import PageTitle from '../shared/PageTitle';
import {
  getStatisticAC,
  statisticDataSelector,
} from '../../apiCalls/statistic/get';

const Overview = ({
  totalUsers,
  totalTransactions,
  monthlyRegister,
  monthlyTransactions,
  monthlyValue,
  monthlyRevenue,
  monthlyPayment,
}) => (
  <>
    <PageTitle content="Overview - Basic statistics" />
    <br />
    <h3>Overview</h3>
    <Row gutter={16}>
      <Col span={12}>
        <Statistic title="Total users" value={totalUsers} />
      </Col>
      <Col span={12}>
        <Statistic title="Total transactions" value={totalTransactions} />
      </Col>
    </Row>
    <br />
    <br />
    <h3>
      {new Date().toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      })}
    </h3>
    <Row>
      <Col span={6}>
        <Statistic title="New register" value={monthlyRegister} />
      </Col>
      <Col span={6}>
        <Statistic title="Transactions count" value={monthlyTransactions} />
      </Col>
    </Row>
    <Row>
      <Col span={6}>
        <Statistic
          title="Total value"
          value={monthlyValue}
          prefix="RM"
          precision={2}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Revenue amount"
          value={monthlyRevenue}
          prefix="RM"
          precision={2}
        />
      </Col>
      <Col span={6}>
        <Statistic
          title="Payment amount"
          value={monthlyPayment}
          prefix="RM"
          precision={2}
        />
      </Col>
    </Row>
  </>
);

const camelizeKeys = mapKeys(camelCase);

const enhance = compose(
  connect(
    state => ({
      data: statisticDataSelector(state),
    }),
    { fetchStatistic: getStatisticAC }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchStatistic();
    },
  }),
  mapProps(({ data, ...others }) => ({
    ...camelizeKeys(data),
    ...others,
  }))
);

export default enhance(Overview);
