import { Button, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, flow, map, filter } from 'lodash/fp';
import React from 'react';

import {
  completeShippingRequestAC,
  completeShippingRequestErrorSelector,
  isCompletingShippingRequestSelector,
} from '../../apiCalls/shippingRequests/complete';
import {
  fetchShippingRequestAC,
  fetchShippingRequestErrorSelector,
  isFetchingShippingRequestSelector,
  shippingRequestDataSelector,
} from '../../apiCalls/shippingRequests/get';
import {
  fetchShippingRequestPaymentsAC,
  shippingRequestPaymentsDataSelector,
} from '../../apiCalls/shippingRequestPayments/list';
import { formatDateTime } from '../../utils/formatDate';
import ApprovalButton from '../shared/ApprovalButton';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';
import formatAmount from '../../utils/formatAmount';
import { PaymentData } from '../Order/detail';
import Status from '../shared/Status';

const parsePaymentData = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return { payment: null, payment_transactions: [] };
  }
};

const shippingRequestStatusConfig = {
  1000: 'pending',
  1001: 'success',
  1002: 'failed',
};

const AdminShippingRequestDetail = ({
  match,
  shippingRequest,
  isFetching,
  fetchShippingRequest,
  errors,
  completeShippingRequest,
  isCompleting,
  fetchShippingRequestPayments,
  shippingRequestPaymentsData,
}) => {
  React.useEffect(() => {
    fetchShippingRequest(match.params.id);
    fetchShippingRequestPayments(match.params.id);
  }, []); // eslint-disable-line

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  const shippingRequestPayments = map(
    flow(get('description'), parsePaymentData)
  )(shippingRequestPaymentsData);

  return (
    <div className="">
      <PageTitle content="Shipping Requests" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/emarket/shipments">ShippingRequests</Link>
          </li>
          <li>View shipping request detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {shippingRequest && (
          <>
            <Descriptions
              title="Shipping request details"
              bordered
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="Created" span={2}>
                {formatDateTime(shippingRequest.created)}
              </Descriptions.Item>
              <Descriptions.Item label="Request ID" span={2}>
                {`#${shippingRequest.id}`}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="Pick Up From">
                {shippingRequest.pick_up_location_name}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {shippingRequest.sender_address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {shippingRequest.sender_name}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number">
                {shippingRequest.sender_phone_number}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="Deliver To">
                {shippingRequest.drop_off_location_name}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {shippingRequest.receiver_address}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {shippingRequest.receiver_name}
              </Descriptions.Item>
              <Descriptions.Item label="Phone Number">
                {shippingRequest.receiver_phone_number}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="Packaging">
                {flow(
                  filter(box => box.box_quantity > 0),
                  map(box => <p>{`${box.box_quantity} * ${box.box.title}`}</p>)
                )(shippingRequest.shipping_boxes)}
              </Descriptions.Item>
              <Descriptions.Item label="Ready For Pick Up Time">
                {shippingRequest.pick_up_time}
              </Descriptions.Item>
              <Descriptions.Item label="Preferable Drop Off Time">
                {shippingRequest.drop_off_time}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="Total Cost">
                {formatAmount(shippingRequest.total_amount)}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="EFMarket Order">
                {shippingRequest.order && (
                  <Link to={`/emarket/orders/${shippingRequest.order}`}>
                    View order
                  </Link>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="EFMarket Quotation">
                {shippingRequest.quotation && (
                  <Link to={`/emarket/posts/${shippingRequest.quotation}`}>
                    View quotation
                  </Link>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Notes">
                {shippingRequest.notes}
              </Descriptions.Item>
              <hr />
              <Descriptions.Item label="Payment Status">
                <Status
                  status={
                    shippingRequestStatusConfig[shippingRequest.payment_status]
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label="Shipping Status">
                <Status
                  status={shippingRequestStatusConfig[shippingRequest.status]}
                />
              </Descriptions.Item>
            </Descriptions>

            {shippingRequest.status === 1000 && (
              <>
                <hr />
                <div className="text-right">
                  <Button.Group>
                    <ApprovalButton
                      action="approve"
                      text="Move to complete"
                      objectId={match.params.id}
                      isApproving={isCompleting}
                      approve={completeShippingRequest}
                    />
                  </Button.Group>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {map(payment => <PaymentData {...payment} />)(shippingRequestPayments)}
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    shippingRequest: shippingRequestDataSelector(state),
    isFetching: isFetchingShippingRequestSelector(state),
    errors: fetchShippingRequestErrorSelector(state),
    isCompleting: isCompletingShippingRequestSelector(state),
    completeError: completeShippingRequestErrorSelector(state),
    shippingRequestPaymentsData: shippingRequestPaymentsDataSelector(state),
  }),
  {
    fetchShippingRequest: fetchShippingRequestAC,
    completeShippingRequest: completeShippingRequestAC,
    fetchShippingRequestPayments: fetchShippingRequestPaymentsAC,
  }
);

export default connectWithRedux(AdminShippingRequestDetail);
