import { Button, Form, Tag, Select } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { debounce } from 'lodash/fp';
import React from 'react';
import queryString from 'query-string';

import {
  fetchAdminUsersAC,
  adminUsersDataSelector,
} from '../../apiCalls/adminUsers/list';
import {
  fetchTransactionsAC,
  transactionsDataSelector,
  fetchTransactionsErrorSelector,
  isFetchingTransactionsSelector,
  totalTransactionsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/transactions/list';
import { formatDate } from '../../utils/formatDate';
import {
  transactionTypeColors,
  transactionTypeConfig,
} from '../../utils/constants';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columnsGenerator = () => [
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: created_at => formatDate(created_at),
  },
  {
    title: 'User',
    dataIndex: 'user',
    render: user => <Link to={`/iam/users/${user.id}`}>{user.name}</Link>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type, obj) => (
      <>
        <Tag color={transactionTypeColors[type]}>
          {transactionTypeConfig(type, obj.service_code, obj.transaction_type)}
        </Tag>
        {obj.parent_id ? <Tag color="red">Refund</Tag> : null}
      </>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: (price, obj) =>
      `${obj.transaction_type === 'withdrawal' ? '-' : '+'}RM${price}`,
    align: 'right',
  },
  {
    title: 'Balance',
    dataIndex: 'current_balance',
    render: current_balance => `RM${current_balance}`,
    align: 'right',
  },
  {
    title: '',
    dataIndex: 'id',
    render: id => (
      <Button.Group>
        <Button>
          <Link to={`/payment/transactions/${id}`}>View detail</Link>
        </Button>
      </Button.Group>
    ),
  },
];

const columns = columnsGenerator();

const Transactions = ({
  transactions,
  error,
  users,
  fetchAdminUsers,
  fetchTransactions,
  history,
  ...others
}) => {
  const searchUsers = debounce(500, fetchAdminUsers);
  const [filters, changeFilters] = React.useState({});
  const filterQs = React.useMemo(
    () => queryString.parse(history.location.search),
    [history.location.search]
  );

  React.useEffect(() => {
    fetchTransactions({
      filter: {
        ...filterQs,
        ...filters,
      },
    });
  }, [fetchTransactions, filterQs, filters]);

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <div id="content">
      <PageTitle content="Transaction Management" />
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{ marginBottom: 16 }}
      >
        <Form.Item label="Type">
          <Select
            defaultValue={filters.type}
            style={{ width: 200 }}
            onChange={value => {
              if (value === 'all') {
                changeFilters({ ...filters, type: undefined });
              } else {
                changeFilters({ ...filters, type: [value] });
              }
            }}
            placeholder="Select transaction type"
          >
            <Select.Option key="all">All</Select.Option>
            <Select.Option key="PaymentTransaction">Payment</Select.Option>
            <Select.Option key="TopupTransaction">Topup</Select.Option>
            <Select.Option key="WithdrawalTransaction">
              Withdrawal
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="User">
          <Select
            showSearch
            defaultValue={filters.user_id}
            style={{ width: 200 }}
            onChange={value => {
              if (value === 'all') {
                changeFilters({ ...filters, user_id: undefined });
              } else {
                changeFilters({ ...filters, user_id: value });
              }
            }}
            onSearch={value => searchUsers({ query: value })}
            placeholder="Select a user by name or contact number"
            showArrow={false}
            filterOption={false}
          >
            <Select.Option key="all">All</Select.Option>
            {users &&
              users.length &&
              users.map(user => (
                <Select.Option key={user.id}>{user.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
      <DataTable
        columns={columns}
        dataSource={transactions}
        dataFetcher={fetchTransactions}
        filters={{
          filter: { ...filterQs, ...filters },
        }}
        {...others}
      />
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    transactions: transactionsDataSelector(state),
    loading: isFetchingTransactionsSelector(state),
    error: fetchTransactionsErrorSelector(state),
    totalItems: totalTransactionsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    users: adminUsersDataSelector(state),
  }),
  {
    fetchTransactions: fetchTransactionsAC,
    fetchAdminUsers: fetchAdminUsersAC,
  }
);

export { columnsGenerator };

export default connectToRedux(Transactions);
