import React from 'react';
import PageTitle from '../shared/PageTitle';
import { GROWTH_REPORT_URL } from '../../utils/envConfig';

const Report = () => (
  <div className="">
    <PageTitle content="Growth Report" />
    <div className="">
      <div className="">
        {GROWTH_REPORT_URL ? (
          <iframe
            title="Growth Report"
            width="100%"
            height="2000px"
            frameBorder="0"
            allowFullscreen=""
            style={{
              left: 0,
              top: '50px',
            }}
            src={GROWTH_REPORT_URL}
          />
        ) : (
          'The report is only available on production'
        )}
      </div>
    </div>
  </div>
);

export default Report;
