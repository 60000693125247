import React from 'react';
import { Link } from 'react-router-dom';
// import { connect } from 'react-redux';
// import { compose, lifecycle, mapProps } from 'recompose';
import { Form } from 'antd';
import { map, get } from 'lodash/fp';
// import { merge, find } from 'lodash/fp';
import TextField from '../shared/form/TextField';
import SelectField from '../shared/form/SelectField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import Uploader from '../shared/form/Uploader';

const UtilityBillForm = ({ handleSubmit, submitting, pristine, providers }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={handleSubmit}
    labelAlign="left"
  >
    <TextField
      name="amount"
      type="number"
      label="Amount"
      disabled
      {...FORM_ITEM_LAYOUT}
    />
    <TextField
      name="contract_id"
      type="text"
      label="Contract Number"
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <SelectField
      name="provider_id"
      label="Provider"
      options={map(formatOption)(providers)}
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <Uploader name="invoice_image" label="Invoice" {...FORM_ITEM_LAYOUT} />

    <TextField
      name="note"
      multiline
      type="text"
      label="Note"
      {...FORM_ITEM_LAYOUT}
    />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting || pristine}
          >
            Submit
          </button>
          <Link
            className="button button--secondary"
            to="/billings/utility"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Link>
        </div>
      </div>
    </div>
  </Form>
);

const formatOption = option => ({
  value: get('id', option),
  label: get('name', option),
});

// const enhance = compose(
//   connect(
//     state => ({
//       providers: providersDataSelector(state),
//       isFetching: isFetchingProvidersSelector(state),
//     }),
//     {
//       fetchProviders: fetchProvidersAC,
//     }
//   ),
//   lifecycle({
//     componentDidMount() {
//       // eslint-disable-next-line
//       this.props.fetchProviders();
//     },
//   }),
//   mapProps(({ providers, ...others }) => ({
//     providers: map(formatOption)(providers),
//     ...others,
//   })),
//   withReduxForm
// );

export const buildFormData = ({ provider, invoice, ...others }, providers) => ({
  provider_id: get('id', provider),
  providers,
  invoice_image: invoice.origin_url,
  ...others,
});

export default UtilityBillForm;
