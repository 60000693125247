import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CANCEL_POST_API_ID = 'CANCEL_POST';

const {
  actionCreator: cancelPostAC,
  dataSelector: cancelPostDataSelector,
  errorSelector: cancelPostErrorSelector,
  isFetchingSelector: isSubmittingPostSelector,
} = makeFetchAction(CANCEL_POST_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/posts/${id}/actions`,
  method: 'PUT',
  notify: true,
  body: { action: 'cancel' },
}));

export {
  CANCEL_POST_API_ID,
  cancelPostAC,
  cancelPostDataSelector,
  cancelPostErrorSelector,
  isSubmittingPostSelector,
};
