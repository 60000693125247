import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'antd';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';

const FeatureForm = ({ onFinish, submitting, initialValues }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 20 }}
    onFinish={onFinish}
    initialValues={initialValues}
  >
    <TextField
      name="name"
      type="text"
      label="Name in English"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <TextField
      name="name_ms"
      type="text"
      label="Name in Malay"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <TextField
      name="name_zh"
      type="text"
      label="Name in Chinese"
      {...FORM_ITEM_LAYOUT}
      required
    />

    <div className="row">
      <div className="col-md-12">
        <div className="text-right">
          <Button.Group>
            <Button>
              <Link to="/emarket/features">Dismiss</Link>
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={submitting}
              loading={submitting}
            >
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

export default FeatureForm;
