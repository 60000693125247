import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_UTILITY_BILL_API_ID = 'FETCH_UTILITY_BILLS';

const {
  actionCreator: fetchUtilityBillsAC,
  dataSelector: utilityBillsRawSelector,
  errorSelector: fetchUtilityBillsErrorSelector,
  isFetchingSelector: isFetchingUtilityBillsSelector,
  updater: updateUtilityBillsAC,
} = makeFetchAction(FETCH_UTILITY_BILL_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/utility_bills`,
    params,
  }),
  method: 'GET',
}));

const totalItemSelector = createSelector(
  utilityBillsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  utilityBillsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  utilityBillsRawSelector,
  get('paging.per_page')
);

const utilityBillsDataSelector = createSelector(
  utilityBillsRawSelector,
  get('utility_bills')
);

export {
  FETCH_UTILITY_BILL_API_ID,
  fetchUtilityBillsAC,
  utilityBillsRawSelector,
  totalItemSelector,
  fetchUtilityBillsErrorSelector,
  isFetchingUtilityBillsSelector,
  updateUtilityBillsAC,
  currentPageSelector,
  pageSizeSelector,
  utilityBillsDataSelector,
};
