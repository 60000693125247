import React from 'react';
import { Input, Form } from 'antd';

const TextField = ({ input, children, multiline, ...others }) => (
  <Form.Item {...others}>
    {multiline ? (
      <Input.TextArea rows={3} {...others} {...input}>
        {children}
      </Input.TextArea>
    ) : (
      <Input {...others} {...input}>
        {children}
      </Input>
    )}
  </Form.Item>
);

export default TextField;
