import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createPostAC,
  isCreatingPostSelector,
  createPostErrorSelector,
} from '../../apiCalls/posts/create';
import Form from './Form';
import { convertFormData } from '../../utils/data';

const CreatePost = ({ handleSubmit, submitting }) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>Add new post</li>
          </ul>
        </div>

        <div className="dashboard-page__actions">
          <div className="dashboard-page__actions__top">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-small-title">Add New Post</h4>
              </div>
            </div>
          </div>
        </div>

        <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit} history submitting={submitting} />
      </div>
    </div>
  </div>
);

const enhance = connect(
  state => ({
    createError: createPostErrorSelector(state),
    submitting: isCreatingPostSelector(state),
  }),
  {
    handleSubmit: post => {
      if (post.image) {
        const formData = convertFormData(post);
        return createPostAC(formData);
      }
      return createPostAC(post);
    },
  }
);

export default enhance(CreatePost);
