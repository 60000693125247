import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createFeatureAC,
  isCreatingFeatureSelector,
  createFeatureErrorSelector,
} from '../../apiCalls/features/create';
import FeatureForm from './FeatureForm';

const CreateFeature = ({ handleSubmit, submitting }) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/emarket/features">Features</Link>
            </li>
            <li>Add new feature</li>
          </ul>
        </div>

        <div className="dashboard-page__actions">
          <div className="dashboard-page__actions__top">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-small-title">Add New Feature</h4>
              </div>
            </div>
          </div>
        </div>

        <FeatureForm onFinish={handleSubmit} history submitting={submitting} />
      </div>
    </div>
  </div>
);

const enhance = connect(
  state => ({
    createError: createFeatureErrorSelector(state),
    submitting: isCreatingFeatureSelector(state),
  }),
  {
    handleSubmit: createFeatureAC,
  }
);

export default enhance(CreateFeature);
