import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { updateAdminTopupAC } from '../../apiCalls/adminTopups/get';
import { APPROVE_TOPUP_API_ID } from '../../apiCalls/adminTopups/approve';
import routerHistory from '../../routerHistory';

const handleApproveAdminTopupSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(APPROVE_TOPUP_API_ID))),
    tap(action => {
      console.log(action);
      routerHistory.goBack();
    }),
    map(action => {
      const topup = get('payload.json.topup_request')(action);
      return updateAdminTopupAC(topup, store$.value);
    })
  );

export default combineEpics(handleApproveAdminTopupSuccess);
