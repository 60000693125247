import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { updateAdminUserAC } from '../../apiCalls/adminUsers/get';
import { DEACTIVATE_USER_API_ID } from '../../apiCalls/adminUsers/deactivate';

const handleDeactivateSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(DEACTIVATE_USER_API_ID)
      )
    ),
    tap(action => {
      console.log(action);
    }),
    map(action => {
      const user = get('payload.json.user')(action);
      return updateAdminUserAC(user, store$.value);
    })
  );

export default combineEpics(handleDeactivateSuccess);
