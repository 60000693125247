import React from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';

import { deleteFeatureAC } from '../../apiCalls/features/delete';

const DeleteButton = ({ id, deleteFeature }) => {
  return (
    <Button
      type="danger"
      onClick={() =>
        Modal.confirm({
          title: 'Are you sure?',
          onOk: () => deleteFeature(id),
        })
      }
    >
      Delete
    </Button>
  );
};

const connectWithRedux = connect(null, {
  deleteFeature: deleteFeatureAC,
});

export default connectWithRedux(DeleteButton);
