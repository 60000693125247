import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'antd';
import UserFormFields from './UserFormFields';

const UserForm = ({ handleSubmit, initialValues }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 20 }}
    onFinish={handleSubmit}
    initialValues={initialValues}
  >
    <UserFormFields />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <Button.Group>
            <Button>
              <Link to="/iam/users">Dismiss</Link>
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);
export const buildFormData = values => {
  const {
    name,
    country_code,
    contact_number,
    email,
    roles,
    area,
    address,
    identification_number,
    bank_name,
    bank_account_name,
    bank_account_number,
    person_in_charge,
  } = values;
  return {
    name,
    country_code,
    contact_number,
    email,
    roles,
    area,
    address,
    identification_number,
    bank_name,
    bank_account_name,
    bank_account_number,
    person_in_charge,
  };
};

export default UserForm;
