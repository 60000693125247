import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_SHIPPING_PRICE_API_ID = 'GET_SHIPPING_PRICE';

const {
  actionCreator: fetchShippingPriceAC,
  dataSelector: shippingPriceRawDataSelector,
  errorSelector: fetchShippingPriceErrorSelector,
  isFetchingSelector: isFetchingShippingPriceSelector,
} = makeFetchAction(GET_SHIPPING_PRICE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingprices/${id}`,
  method: 'GET',
}));

const shippingPriceDataSelector = shippingPriceRawDataSelector;

export {
  GET_SHIPPING_PRICE_API_ID,
  fetchShippingPriceAC,
  fetchShippingPriceErrorSelector,
  isFetchingShippingPriceSelector,
  shippingPriceDataSelector,
};
