import React from 'react';
import { Select, Form } from 'antd';
import { map } from 'lodash/fp';

const SelectField = ({ input, options, ...others }) => (
  <Form.Item {...others}>
    <Select {...others} {...input}>
      {map(
        ({ value, label }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ),
        options
      )}
    </Select>
  </Form.Item>
);

export default SelectField;
