import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_FEATURE_API_ID = 'UPDATE_FEATURE';

const {
  actionCreator: updateFeatureAC,
  dataSelector: updateFeatureDataSelector,
  errorSelector: updateFeatureErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_FEATURE_API_ID, (id, feature) => ({
  endpoint: `${EMARKET_ADMIN_API}/features/${id}`,
  method: 'PATCH',
  notify: true,
  body: feature,
}));

export {
  UPDATE_FEATURE_API_ID,
  updateFeatureAC,
  updateFeatureDataSelector,
  updateFeatureErrorSelector,
  isUpdatingSelector,
};
