import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_ADMIN_WITHDRAWAL_API_ID = 'GET_ADMIN_WITHDRAWAL';

const {
  actionCreator: fetchAdminWithdrawalAC,
  dataSelector: adminWithdrawalDataSelector,
  errorSelector: fetchAdminWithdrawalErrorSelector,
  isFetchingSelector: isFetchingAdminWithdrawalSelector,
  updater: updateAdminWithdrawalDataAC,
} = makeFetchAction(GET_ADMIN_WITHDRAWAL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/withdrawals/${id}`,
  method: 'GET',
}));

const updateAdminWithdrawalAC = (withdrawal, state) => {
  const adminWithdrawal = adminWithdrawalDataSelector(state);
  const data = {
    ...adminWithdrawal,
    withdrawal,
  };
  return updateAdminWithdrawalDataAC(data);
};

export {
  GET_ADMIN_WITHDRAWAL_API_ID,
  fetchAdminWithdrawalAC,
  fetchAdminWithdrawalErrorSelector,
  isFetchingAdminWithdrawalSelector,
  adminWithdrawalDataSelector,
  updateAdminWithdrawalAC,
};
