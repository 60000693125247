import { useLocation, Link } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import {
  fetchAdminUserAC,
  adminUserDataSelector,
  isFetchingAdminUserSelector,
} from '../../apiCalls/adminUsers/get';
import {
  updateUserAC,
  isUpdatingSelector,
} from '../../apiCalls/adminUsers/edit';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import UserForm, { buildFormData } from './UserForm';

const AdminEditUser = ({ match, handleSubmit, formError, user }) => {
  const { search } = useLocation();
  const usersQuery = new URLSearchParams(search);

  return (
    <div className="">
      <div className="">
        <h1 className="page-title">Dashboard</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to={`/iam/users?${usersQuery}`}>Users</Link>
            </li>
            <li>Edit user {match.params.id}</li>
          </ul>
        </div>

        <div className="">
          <div className="">
            <div className="row align-items-center">
              <div className="error">{formError}</div>
            </div>
            {user && (
              <UserForm
                handleSubmit={handleSubmit}
                initialValues={buildFormData(user.user)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    user: adminUserDataSelector(state),
    isFetching: isFetchingAdminUserSelector(state),
  }),
  {
    updateUser: updateUserAC,
    fetchAdminUser: fetchAdminUserAC,
  }
);

const prepareProps = mapProps(({ updateUser, updateUserError, ...others }) => ({
  formError: updateUserError,
  handleSubmit: user => {
    const user_id = others.match.params.id;
    return updateUser(user_id, user);
  },
  ...others,
}));

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchAdminUser, match } = this.props;
      const { id } = match.params;
      fetchAdminUser(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditUser);
