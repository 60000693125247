import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_USER_API_ID = 'UPDATE_USER';

const {
  actionCreator: updateUserAC,
  dataSelector: updateUserDataSelector,
  errorSelector: updateUserErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_USER_API_ID, (id, user) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}`,
  method: 'PATCH',
  notify: true,
  body: user,
}));

export {
  UPDATE_USER_API_ID,
  updateUserAC,
  updateUserDataSelector,
  updateUserErrorSelector,
  isUpdatingSelector,
};
