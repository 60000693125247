import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import {
  adminRequestDataSelector,
  fetchAdminRequestAC,
  fetchAdminRequestErrorSelector,
  isFetchingAdminRequestSelector,
} from '../../apiCalls/adminRequests/get';
import { columnsGenerator } from '../Transactions';
import {
  fetchTransactionsAC,
  transactionsDataSelector,
} from '../../apiCalls/transactions/list';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';
import Status from '../shared/Status';
import formatDate from '../../utils/formatDate';

const transactionColumns = columnsGenerator();

const AdminRequestDetail = ({
  fetchTransactions,
  transactions,
  fetchAdminRequestDetail,
  requestData,
  match,
  isFetching,
  errors,
}) => {
  React.useEffect(() => {
    fetchAdminRequestDetail(match.params.id);
    fetchTransactions({ filter: { request_id: match.params.id } });
  }, []); // eslint-disable-line

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  const request = get('request')(requestData);

  return (
    <div className="">
      <PageTitle content="Orders" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/payment/requests">Requests</Link>
          </li>
          <li>View request detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {request && (
          <Descriptions
            title="Request details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{request.id}</Descriptions.Item>
            <Descriptions.Item label="Requester">
              {request.requester.name}
            </Descriptions.Item>
            <Descriptions.Item label="Requester email">
              {request.requester.email}
            </Descriptions.Item>
            <Descriptions.Item label="Requester phone">
              {request.requester.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label="Requester area">
              {request.requester.area}
            </Descriptions.Item>
            {request.receiver ? (
              <>
                <Descriptions.Item label="Receiver">
                  {request.receiver.name}
                </Descriptions.Item>
                <Descriptions.Item label="Receiver email">
                  {request.receiver.email}
                </Descriptions.Item>
                <Descriptions.Item label="Receiver phone">
                  {request.receiver.phone_number}
                </Descriptions.Item>
                <Descriptions.Item label="Receiver area">
                  {request.receiver.area}
                </Descriptions.Item>
              </>
            ) : null}
            <Descriptions.Item label="Amount">
              RM{request.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status status={request.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Notes">{request.note}</Descriptions.Item>
            <Descriptions.Item label="Invoices">
              {request.invoice && request.invoice.origin_url ? (
                <a
                  href={request.invoice.origin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={request.invoice.thumb_url} alt="" />
                </a>
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {formatDate(request.created_at)}
            </Descriptions.Item>
          </Descriptions>
        )}
        <div className="">
          <div className="ant-descriptions-title">Transactions</div>
          <DataTable dataSource={transactions} columns={transactionColumns} />
        </div>
      </div>
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    requestData: adminRequestDataSelector(state),
    isFetching: isFetchingAdminRequestSelector(state),
    errors: fetchAdminRequestErrorSelector(state),
    transactions: transactionsDataSelector(state),
  }),
  {
    fetchAdminRequestDetail: fetchAdminRequestAC,
    fetchTransactions: fetchTransactionsAC,
  }
);

export default connectToRedux(AdminRequestDetail);
