import React from 'react';
import { Form } from 'antd';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import TextField from '../shared/form/TextField';
import RoleSelect from '../shared/RoleSelect';

const options = [{ value: 'driver', label: 'Driver' }];

const UserFormFields = () => (
  <>
    <Form.Item
      name="email"
      type="email"
      label="Email"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <TextField />
    </Form.Item>
    <Form.Item name="name" type="text" label="Name" {...FORM_ITEM_LAYOUT}>
      <TextField />
    </Form.Item>
    <Form.Item
      name="country_code"
      type="text"
      label="Country code"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <TextField />
    </Form.Item>
    <br />
    <Form.Item
      name="contact_number"
      type="text"
      label="Phone"
      {...FORM_ITEM_LAYOUT}
      required
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="identification_number"
      type="text"
      label="Identification Number"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="person_in_charge"
      type="text"
      label="Person in charge"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>

    <Form.Item name="roles" type="text" label="Roles" {...FORM_ITEM_LAYOUT}>
      <RoleSelect options={options} />
    </Form.Item>
    <Form.Item name="address" type="text" label="Address" {...FORM_ITEM_LAYOUT}>
      <TextField />
    </Form.Item>
    <Form.Item name="area" type="text" label="Area" {...FORM_ITEM_LAYOUT}>
      <TextField />
    </Form.Item>
    <br />
    <Form.Item
      name="bank_name"
      type="text"
      label="Bank Name"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="bank_account_name"
      type="text"
      label="Bank Account Name"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="bank_account_number"
      type="text"
      label="Bank Account Number"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
  </>
);
export default UserFormFields;
