import { makeFetchAction } from 'redux-api-call';
import { get } from 'lodash/fp';
import { createSelector } from 'reselect';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_FEATURE_API_ID = 'CREATE_FEATURE';

const {
  actionCreator: createFeatureAC,
  dataSelector: createdFeatureRawDataSelector,
  errorSelector: createFeatureErrorSelector,
  isFetchingSelector: isCreatingFeatureSelector,
} = makeFetchAction(CREATE_FEATURE_API_ID, feature => ({
  endpoint: `${EMARKET_ADMIN_API}/features`,
  method: 'POST',
  notify: true,
  body: feature,
}));

const createdFeatureDataSelector = createSelector(
  createdFeatureRawDataSelector,
  get('feature')
);

export {
  CREATE_FEATURE_API_ID,
  createFeatureAC,
  createdFeatureDataSelector,
  createFeatureErrorSelector,
  isCreatingFeatureSelector,
};
