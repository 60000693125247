import { Form, Button, Modal } from 'antd';
import { Link } from 'react-router-dom';
import { compose, lifecycle, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { map } from 'lodash/fp';
import React from 'react';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  fetchLocationsAC,
  isFetchingLocationsSelector,
  locationsDataSelector,
} from '../../apiCalls/locations/list';
import {
  fetchPackagingBoxesAC,
  isFetchingPackagingBoxesSelector,
  packagingBoxesDataSelector,
} from '../../apiCalls/packagingBoxes/list';
import SelectField from '../shared/form/SelectField';
import TextField from '../shared/form/TextField';

const ShippingPriceForm = ({
  onFinish,
  initialValues,
  deleteShippingPrice,
  locations,
  isLoadingLocations,
  packagingBoxes,
  isLoadingBoxes,
}) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={onFinish}
    initialValues={initialValues}
    labelAlign="left"
  >
    {isLoadingLocations || (
      <SelectField
        name="pick_up_location"
        label="Pick up location"
        {...FORM_ITEM_LAYOUT}
        required
        options={locations}
      />
    )}
    {isLoadingLocations || (
      <SelectField
        name="drop_off_location"
        label="Drop off location"
        {...FORM_ITEM_LAYOUT}
        required
        options={locations}
      />
    )}
    {isLoadingBoxes || (
      <SelectField
        name="box"
        label="Box"
        {...FORM_ITEM_LAYOUT}
        required
        options={packagingBoxes}
      />
    )}
    <TextField
      name="price"
      type="text"
      label="Price"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <div className="row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6">
        <div className="text-right">
          <Button.Group>
            {initialValues && initialValues.id && (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure?',
                    onOk: () => deleteShippingPrice(initialValues.id),
                  })
                }
              >
                Delete
              </Button>
            )}
            <Button>
              <Link to="/emarket/shipping_prices">Dismiss</Link>
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  </Form>
);

const enhance = compose(
  connect(
    state => ({
      locations: locationsDataSelector(state),
      isLoadingLocations: isFetchingLocationsSelector(state),
      packagingBoxes: packagingBoxesDataSelector(state),
      isLoadingBoxes: isFetchingPackagingBoxesSelector(state),
    }),
    {
      fetchLocations: fetchLocationsAC,
      fetchPackagingBoxes: fetchPackagingBoxesAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchLocations();
      this.props.fetchPackagingBoxes();
    },
  }),
  mapProps(({ locations, packagingBoxes, ...others }) => ({
    locations: map(location => ({ value: location.id, label: location.name }))(
      locations
    ),
    packagingBoxes: map(packagingBox => ({
      value: packagingBox.id,
      label: `${packagingBox.title} / ${packagingBox.description}`,
    }))(packagingBoxes),
    ...others,
  }))
);
export default enhance(ShippingPriceForm);
