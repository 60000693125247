import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DELETE_PRICE_API_ID = 'DELETE_PRICE';

const {
  actionCreator: deletePriceAC,
  dataSelector: deletePriceDataSelector,
  errorSelector: deletePriceErrorSelector,
  isFetchingSelector: isDeletingPriceSelector,
} = makeFetchAction(DELETE_PRICE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/prices/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_PRICE_API_ID,
  deletePriceAC,
  deletePriceDataSelector,
  deletePriceErrorSelector,
  isDeletingPriceSelector,
};
