import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_POST_API_ID = 'UPDATE_POST';

const {
  actionCreator: updatePostAC,
  dataSelector: updatePostDataSelector,
  errorSelector: updatePostErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_POST_API_ID, (id, post) => ({
  endpoint: `${EMARKET_ADMIN_API}/posts/${id}`,
  method: 'PATCH',
  notify: true,
  body: post,
}));

export {
  UPDATE_POST_API_ID,
  updatePostAC,
  updatePostDataSelector,
  updatePostErrorSelector,
  isUpdatingSelector,
};
