import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Layout, Menu, Dropdown, Modal } from 'antd';
import { get } from 'lodash/fp';
import { removeFromLocalStorage } from '../../LocalStorage';
import { resetAdminAuthTokenAC } from '../../state/adminSession/state';
import Logo from './Logo';

const UserMenu = ({ logOut }) => (
  <Menu>
    <Menu.Item>
      <Link
        to={''}
        onClick={() =>
          Modal.confirm({
            title: 'Are you sure?',
            onOk: () => logOut(),
          })
        }
      >
        <i className="fas fa-sign-out-alt" /> Log Out
      </Link>
    </Menu.Item>
  </Menu>
);

const Header = ({ user, ...others }) => (
  <Layout.Header
    theme="light"
    style={{ backgroundColor: '#fff', borderBottom: '3px solid #232d62' }}
  >
    <Logo />
    <Link
      to="/"
      style={{
        fontWeight: 800,
        fontSize: 16,
        marginLeft: 5,
        color: '#232d62',
      }} >
      EFPay Dashboard
    </Link>
    <div className="float-right">
      {user ? (
        <Dropdown
          overlay={UserMenu(others)}
          style={{ cursor: 'pointer' }}
          trigger={['click']}
        >
          <span style={{ userSelect: 'none', cursor: 'pointer' }}>
            {user.name}
          </span>
        </Dropdown>
      ) : (
        <Link to="/login">Login</Link>
      )}
    </div>
  </Layout.Header>
);

const connectWithRedux = connect(
  state => ({
    user: get('adminSession.user')(state),
  }),
  dispatch => ({
    logOut: () => {
      dispatch(resetAdminAuthTokenAC());
      removeFromLocalStorage('adminSession');
    },
  })
);

const enhance = compose(connectWithRedux);

export default enhance(Header);
