import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createPriceAC,
  isCreatingPriceSelector,
  createPriceErrorSelector,
} from '../../apiCalls/locationPrices/create';
import Form from './Form';
import PageTitle from '../shared/PageTitle';

const CreatePrice = ({ handleSubmit, submitting }) => (
  <>
    <PageTitle content="Routes and Pricing" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/routes-and-pricing">Routes and Pricing</Link>
        </li>
        <li>Add new price</li>
      </ul>
    </div>

    <div className="row align-items-center">
      <div className="col-sm-6">
        <h4 className="page-small-title">Add New Route</h4>
      </div>
    </div>

    <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={handleSubmit} history submitting={submitting} />
  </>
);

const enhance = connect(
  state => ({
    createError: createPriceErrorSelector(state),
    submitting: isCreatingPriceSelector(state),
  }),
  {
    handleSubmit: price => {
      return createPriceAC(price);
    },
  }
);

export default enhance(CreatePrice);
