import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PageTitle from '../shared/PageTitle';
import { get } from 'lodash/fp';

const Dashboard = ({ name }) => (
  <>
    <PageTitle content="EFPay Dashboard" />
    <h3>Hi {name} 👋</h3>
    <p>Workspace for EFPay team.</p>
  </>
);

const enhance = compose(
  connect(
    state => ({
      name: get('adminSession.user.name')(state),
    })
  ),
);

export default enhance(Dashboard);
