import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_ADMIN_PAYMENT_API_ID = 'GET_ADMIN_PAYMENT';

const {
  actionCreator: fetchAdminPaymentAC,
  dataSelector: adminPaymentDataSelector,
  errorSelector: fetchAdminPaymentErrorSelector,
  isFetchingSelector: isFetchingAdminPaymentSelector,
  updater: updateAdminPaymentDataAC,
} = makeFetchAction(GET_ADMIN_PAYMENT_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/payments/${id}`,
  method: 'GET',
}));

const updateAdminPaymentAC = (payment, state) => {
  const adminPayment = adminPaymentDataSelector(state);
  const data = {
    ...adminPayment,
    payment,
  };
  return updateAdminPaymentDataAC(data);
};

export {
  GET_ADMIN_PAYMENT_API_ID,
  fetchAdminPaymentAC,
  fetchAdminPaymentErrorSelector,
  isFetchingAdminPaymentSelector,
  adminPaymentDataSelector,
  updateAdminPaymentAC,
};
