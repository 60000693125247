import { combineEpics } from 'redux-observable';

import loginSuccessEpic from '../epics/login/loginSuccessEpic';
import adminLoginSuccessEpic from '../epics/adminLogin/adminLoginSuccessEpic';
import adminApproveWithdrawal from '../epics/adminWithdrawal/adminApproveWithdrawalSuccessEpic';
import adminRejectWithdrawal from '../epics/adminWithdrawal/adminRejectWithdrawalSuccessEpic';
import adminEditWithdrawal from '../epics/adminWithdrawal/adminEditWithdrawalSuccessEpic';
import adminCreateTopup from '../epics/adminTopup/adminCreateTopupSuccessEpic';
import adminApproveTopup from '../epics/adminTopup/adminApproveTopupSuccessEpic';
import adminRejectTopup from '../epics/adminTopup/adminRejectTopupSuccessEpic';
import adminRejectPayment from '../epics/adminPayment/adminRejectPaymentSuccessEpic';
import adminCreateCreditTransactionSuccessEpic from '../epics/creditSystem/adminCreateCreditTransactionSuccessEpic';
import adminRejectCreditTransactionSuccessEpic from '../epics/creditSystem/adminRejectCreditTransactionSuccessEpic';
import adminApproveCreditTransactionSuccessEpic from '../epics/creditSystem/adminApproveCreditTransactionSuccessEpic';
import adminCreateUserSuccessEpic from '../epics/adminUsers/adminCreateUserSuccessEpic';
import adminEditUserSuccessEpic from '../epics/adminUsers/adminEditUserSuccessEpic';
import adminActivateSuccessEpic from '../epics/adminUsers/adminActivateSuccessEpic';
import adminDeactivateSuccessEpic from '../epics/adminUsers/adminDeactivateSuccessEpic';
import adminDeleteSuccessEpic from '../epics/adminUsers/adminDeleteSuccessEpic';
import adminVerifySuccessEpic from '../epics/adminUsers/adminVerifySuccessEpic';
import notificationEpics from '../epics/notificationEpics';
import adminApprovePhoneBill from '../epics/adminPhoneBill/adminApprovePhoneBillSuccessEpic';
import adminRejectPhoneBill from '../epics/adminPhoneBill/adminRejectPhoneBillSuccessEpic';
import adminEditPhoneBill from '../epics/adminPhoneBill/adminEditPhoneBillSuccessEpic';
import adminApproveUtilityBill from '../epics/adminUtilityBill/adminApproveUtilityBillSuccessEpic';
import adminRejectUtilityBill from '../epics/adminUtilityBill/adminRejectUtilityBillSuccessEpic';
import adminEditUtilityBill from '../epics/adminUtilityBill/adminEditUtilityBillSuccessEpic';
import providerEpics from '../epics/providerEpics';
import featureEpics from '../epics/featureEpics';
import productEpics from '../epics/productEpics';
import postEpics from '../epics/postEpics';
import orderEpics from '../epics/orderEpics';
import locationEpics from '../epics/locationEpics';
import packagingBoxEpics from '../epics/packagingBoxEpics';
import shippingPriceEpics from '../epics/shippingPriceEpics';
import shippingRequestEpics from '../epics/shippingRequestEpics';
import locationPriceEpics from '../epics/locationPriceEpics';
import adminStaffsEpic from '../epics/adminStaffs';
import ecomProductEpics from '../epics/ecomProductEpics';
import ecomOrderEpics from '../epics/ecomOrderEpics';
import handle401ErrorEpic from '../epics/handle401ErrorEpic';

export default combineEpics(
  loginSuccessEpic,
  adminLoginSuccessEpic,
  adminApproveWithdrawal,
  adminRejectWithdrawal,
  adminEditWithdrawal,
  adminCreateTopup,
  adminApproveTopup,
  adminRejectTopup,
  adminRejectPayment,
  adminCreateCreditTransactionSuccessEpic,
  adminRejectCreditTransactionSuccessEpic,
  adminApproveCreditTransactionSuccessEpic,
  adminCreateUserSuccessEpic,
  adminEditUserSuccessEpic,
  adminActivateSuccessEpic,
  adminDeactivateSuccessEpic,
  adminDeleteSuccessEpic,
  adminVerifySuccessEpic,
  notificationEpics,
  adminApprovePhoneBill,
  adminRejectPhoneBill,
  adminEditPhoneBill,
  adminApproveUtilityBill,
  adminRejectUtilityBill,
  adminEditUtilityBill,
  providerEpics,
  featureEpics,
  productEpics,
  postEpics,
  orderEpics,
  locationEpics,
  packagingBoxEpics,
  shippingPriceEpics,
  shippingRequestEpics,
  locationPriceEpics,
  adminStaffsEpic,
  ecomProductEpics,
  ecomOrderEpics,
  handle401ErrorEpic
);
