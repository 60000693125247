import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get, map } from 'lodash/fp';
import { Descriptions, Tag, Button, Modal } from 'antd';
import {
  fetchPostAC,
  postDataSelector,
  isFetchingPostSelector,
} from '../../apiCalls/posts/get';
import { cancelPostAC } from '../../apiCalls/posts/cancel';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import { formatDate, timeAgo } from '../../utils/formatDate';
import {
  postTypesConfig,
  TAG_COLOR,
  sizeUnitConfig,
  unitColors,
  postStatusColors,
  postStatusConfig,
} from '../../utils/constants';

const AdminPostDetail = ({ match, post, cancelPost }) => {
  const { search } = useLocation();
  const postsQuery = new URLSearchParams(search);

  return (
    <div className="">
      <PageTitle content="Post Management" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to={`/emarket/posts?${postsQuery}`}>Posts</Link>
          </li>
          <li>View post detail: {match.params.id}</li>
        </ul>
      </div>

      {post && (
        <Descriptions
          title="Post details"
          bordered
          column={1}
          layout="horizontal"
        >
          <Descriptions.Item label="ID">{post.id}</Descriptions.Item>
          <Descriptions.Item label="Post Type">
            <Tag color={TAG_COLOR[post.post_type]}>
              {postTypesConfig[post.post_type]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Product">
            {post.product.name}
          </Descriptions.Item>
          <Descriptions.Item label="Created by">
            {post.created_by.name}
          </Descriptions.Item>
          <Descriptions.Item label="Size unit">
            <Tag color={unitColors[post.size_unit]}>
              {sizeUnitConfig[post.size_unit]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Price">{post.price}</Descriptions.Item>
          <Descriptions.Item label="Quantity">
            {post.quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Available Quantity">
            {post.available_quantity}
          </Descriptions.Item>
          <Descriptions.Item label="Size">{post.size}</Descriptions.Item>
          <Descriptions.Item label="Features">
            {map(feature => (
              <Tag color="geekblue" style={{ margin: 2 }}>
                {feature.name}
              </Tag>
            ))(post.features)}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={3}>
            <Tag color={postStatusColors[post.status]}>
              {postStatusConfig[post.status]}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Notes">
            {post.description}
          </Descriptions.Item>
          <Descriptions.Item label="Featured Image">
            {post.image ? (
              <img src={post.image} alt={post.product.name} />
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Created" span={2}>
            {formatDate(post.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Last updated" span={2}>
            {timeAgo(post.modified)}
          </Descriptions.Item>
          <Descriptions.Item label="Additional Photos">
            {post.images
              ? map(item => <img src={item.image} alt="Additional images" />)(
                  post.images
                )
              : '-'}
          </Descriptions.Item>
        </Descriptions>
      )}

      <hr />
      <div className="text-right">
        {post && (
          <Button.Group>
            {post.status === 1000 && (
              <Button
                type="danger"
                onClick={() =>
                  Modal.confirm({
                    title: 'Are you sure?',
                    onOk: () => cancelPost(post.id),
                  })
                }
              >
                Cancel
              </Button>
            )}
          </Button.Group>
        )}
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    post: postDataSelector(state),
    isFetching: isFetchingPostSelector(state),
  }),
  {
    fetchPost: fetchPostAC,
    cancelPost: cancelPostAC,
  }
);

const prepareProps = mapProps(({ ...others }) => ({
  ...others,
}));

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchPost, match } = this.props;
      const { id } = match.params;
      fetchPost(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminPostDetail);
