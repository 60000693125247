import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_STATISTIC = 'DASHBOARD/GET_STATISTIC';

const {
  actionCreator: getStatisticAC,
  dataSelector: statisticRawDataSelector,
  errorSelector: getStatisticErrorSelector,
  isFetchingSelector: isGettingStatisticSelector,
} = makeFetchAction(GET_STATISTIC, () => ({
  endpoint: `${API_ADMIN_ENDPOINT}/statistic`,
  method: 'get',
}));

const statisticDataSelector = createSelector(
  statisticRawDataSelector,
  get('statistic')
);

export {
  GET_STATISTIC,
  getStatisticAC,
  statisticDataSelector,
  getStatisticErrorSelector,
  isGettingStatisticSelector,
};
