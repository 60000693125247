import { Select, Spin, Form } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import debounce from 'lodash/debounce';

import {
  adminUsersDataSelector,
  fetchAdminUsersAC,
  isFetchingAdminUsersSelector,
} from '../../apiCalls/adminUsers/list';

const DebounceSelect = ({
  fetchAdminUsers,
  debounceTimeout = 800,
  users = [],
  loading,
  input,
  ...props
}) => {
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = value => {
      fetchAdminUsers({ query: value });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchAdminUsers, debounceTimeout]);

  const options = users.map(user => (
    <Select.Option key={user.id} value={user.id}>
      {user.name}
    </Select.Option>
  ));

  return (
    <Form.Item {...props}>
      <Select
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch
        {...props}
        {...input}
        placeholder="Select users"
      >
        {options}
      </Select>
    </Form.Item>
  );
};

const connectToRedux = connect(
  state => ({
    users: adminUsersDataSelector(state),
    loading: isFetchingAdminUsersSelector(state),
  }),
  {
    fetchAdminUsers: fetchAdminUsersAC,
  }
);

export default connectToRedux(DebounceSelect);
