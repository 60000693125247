import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { eq, flow, get, overSome } from 'lodash/fp';
import React from 'react';

import {
  adminAuthTokenSelector,
  adminUserSelector,
} from './state/adminSession/state';
import {
  isFetchingLocalStorageSelector,
  startFetchingLocalStorateThunkCreator,
} from './LocalStorage/state';
import routerHistory from './routerHistory';

import AdminCreateProvider from './components/AdminProviders/create';
import AdminEditPassword from './components/AdminUsers/password';
import AdminEditStaffPassword from './components/AdminStaffs/password';
import AdminPaymentDetail from './components/AdminPayments/detail';
import AdminPayments from './components/AdminPayments';
import AdminPhoneBillDetail from './components/AdminPhoneBills/detail';
import AdminPhoneBillForm from './components/AdminPhoneBills/edit';
import AdminPhoneBills from './components/AdminPhoneBills';
import AdminProviders from './components/AdminProviders';
import EFPayAccount from './components/EFPayAccount';
import EFBankAccount from './components/EFBankAccount';
import AdminRequestDetail from './components/AdminRequests/detail';
import AdminStaffCreate from './components/AdminStaffs/create';
import AdminStaffDetail from './components/AdminStaffs/detail';
import AdminStaffEdit from './components/AdminStaffs/edit';
import AdminStaffs from './components/AdminStaffs';
import AdminTopUp from './components/AdminTopUps';
import AdminTopUpCreate from './components/AdminTopUps/create';
import AdminTopUpDetail from './components/AdminTopUps/detail';
import AdminTransfers from './components/AdminTransfers';
import AdminUserCreate from './components/AdminUsers/create';
import AdminUserDetail from './components/AdminUsers/detail';
import AdminUserEdit from './components/AdminUsers/edit';
import AdminUserGenerateKey from './components/AdminUsers/generateKey';
import AdminUsers from './components/AdminUsers';
import AdminUtilityBillDetail from './components/AdminUtilityBills/detail';
import AdminUtilityBillForm from './components/AdminUtilityBills/edit';
import AdminUtilityBills from './components/AdminUtilityBills';
import AdminWithdrawalDetail from './components/AdminWithdrawals/detail';
import AdminWithdrawalEdit from './components/AdminWithdrawals/edit';
import AdminWithdrawals from './components/AdminWithdrawals';
import CreateCreditRecord from './components/CreditRecords/create';
import CreateFeature from './components/Feature/CreateFeature';
import CreateLocation from './components/Location/CreateLocation';
import CreatePost from './components/Post/CreatePost';
import CreatePrice from './components/LocationPrice/CreatePrice';
import CreateProduct from './components/Product/CreateProduct';
import EditProduct from './components/Product/EditProduct';
import ProductTable from './components/Product';
import UserCredits from './components/CreditRecords/user';
import CreditRecords from './components/CreditRecords';
import CreditRecordDetail from './components/CreditRecords/detail';
import CreditTransactions from './components/CreditTransactions';
import CreditSystem from './components/CreditSystem';
import Dashboard from './components/Dashboard';
import EditFeature from './components/Feature/EditFeature';
import EditLocation from './components/Location/EditLocation';
import EditPost from './components/Post/EditPost';
import EditPrice from './components/LocationPrice/EditPrice';
import FeatureList from './components/Feature';
import Layout from './components/shared/Layout';
import LoadingSpinner from './components/shared/LoadingSpinner';
import LocationPrice from './components/LocationPrice';
import Locations from './components/Location';
import PackagingBoxes from './components/PackagingBox';
import CreatePackagingBox from './components/PackagingBox/CreatePackagingBox';
import EditPackagingBox from './components/PackagingBox/EditPackagingBox';
import ShippingPrices from './components/ShippingPrice';
import CreateShippingPrice from './components/ShippingPrice/CreateShippingPrice';
import EditShippingPrice from './components/ShippingPrice/EditShippingPrice';
import Login from './components/AdminLogin';
import Logout from './components/AdminLogin/logout';
import Order from './components/Order';
import OrderDetail from './components/Order/detail';
import Overview from './components/Dashboard/overview';
import PageNotFound from './components/404';
import PostDetail from './components/Post/detail';
import PostList from './components/Post';
import Quotation from './components/Quotation';
import QuotationDetail from './components/Quotation/detail';
import Report from './components/Dashboard/report';
import ScrollToTop from './components/shared/ScrollToTop';
import ShipmentDetail from './components/Shipment/detail';
import Shipments from './components/Shipment';
import TransactionDetail from './components/Transactions/detail';
import Transactions from './components/Transactions';
import EFMarketTransactions from './components/EFMarketAccount';
import ShippingAgentTransactions from './components/ShippingAgentAccount';
import CreateEcomProduct from './components/EcomProducts/CreateProduct';
import EditEcomProduct from './components/EcomProducts/EditProduct';
import EcomProductList from './components/EcomProducts';
import EcomOrder from './components/EcomOrders';
import EcomOrderDetail from './components/EcomOrders/detail';

const mapStateToProps = state => {
  const isStaffSignedIn = adminAuthTokenSelector(state);
  const staff = adminUserSelector(state);
  const isAdminSignedIn = staff && staff.roles.includes('admin');

  return {
    isStaffSignedIn,
    isAdminSignedIn,
    isFetchingLocalStorage: isFetchingLocalStorageSelector(state),
  };
};

const PrivateRoute = connect(
  mapStateToProps
)(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isStaffSignedIn ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
));

const AdminRoute = connect(
  mapStateToProps
)(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isStaffSignedIn ? (
        rest.isAdminSignedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
));

const GuestRoute = connect(
  mapStateToProps
)(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      rest.isStaffSignedIn ? <Redirect to="/" /> : <Component {...props} />
    }
  />
));

const AppRouter = () => (
  <Router history={routerHistory}>
    <ScrollToTop />
    <Switch>
      {/* Authentication */}
      <GuestRoute path="/login" exact component={Login} />
      <PrivateRoute path="/logout" exact component={Logout} />

      {/* Payment */}
      <Route>
        <Layout>
          <Route path="/" exact render={() => <Redirect to="/" />} />

          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/overview/stats" exact component={Overview} />
          <PrivateRoute path="/overview/reports" exact component={Report} />

          {/* Staffs */}
          <AdminRoute path="/iam/staffs" exact component={AdminStaffs} />
          <AdminRoute
            path="/iam/staffs/:id([0-9]+)"
            exact
            component={AdminStaffDetail}
          />
          <AdminRoute
            path="/iam/staffs/:id([0-9]+)/edit"
            exact
            component={AdminStaffEdit}
          />
          <AdminRoute
            path="/iam/staffs/:id([0-9]+)/password"
            exact
            component={AdminEditStaffPassword}
          />
          <AdminRoute
            path="/iam/staffs/add"
            exact
            component={AdminStaffCreate}
          />

          {/* Users */}
          <AdminRoute path="/iam/users/add" exact component={AdminUserCreate} />
          <PrivateRoute path="/iam/users" exact component={AdminUsers} />
          <PrivateRoute
            path="/iam/users/:id([0-9]+)"
            exact
            component={AdminUserDetail}
          />
          <PrivateRoute
            path="/iam/users/:id([0-9]+)/edit"
            exact
            component={AdminUserEdit}
          />
          <PrivateRoute
            path="/iam/users/:id([0-9]+)/keys"
            exact
            component={AdminUserGenerateKey}
          />
          <PrivateRoute
            path="/iam/users/:id([0-9]+)/password"
            exact
            component={AdminEditPassword}
          />

          {/* Payments */}
          <AdminRoute
            path="/payment/efpay_account"
            exact
            component={EFPayAccount}
          />
          <AdminRoute
            path="/payment/efbank_account"
            exact
            component={EFBankAccount}
          />
          <AdminRoute
            path="/payment/requests/:id"
            exact
            component={AdminRequestDetail}
          />
          <AdminRoute path="/payment/topups" exact component={AdminTopUp} />
          <AdminRoute
            path="/payment/topups/:id"
            exact
            component={AdminTopUpDetail}
          />
          <AdminRoute
            path="/payment/topups/new"
            exact
            component={AdminTopUpCreate}
          />
          <PrivateRoute
            path="/payment/withdrawals/:id([0-9]+)"
            exact
            component={AdminWithdrawalDetail}
          />
          <PrivateRoute
            path="/payment/withdrawals/:id([0-9]+)/edit"
            exact
            component={AdminWithdrawalEdit}
          />
          <PrivateRoute
            path="/payment/withdrawals"
            exact
            component={AdminWithdrawals}
          />
          <AdminRoute
            path="/payment/payments"
            exact
            component={AdminPayments}
          />
          <AdminRoute
            path="/payment/payments/:id"
            exact
            component={AdminPaymentDetail}
          />
          <AdminRoute
            path="/payment/revenue"
            exact
            component={AdminTransfers}
          />
          <AdminRoute
            path="/payment/credits/new"
            exact
            component={CreateCreditRecord}
          />
          <AdminRoute
            path="/payment/credits/users/:id"
            exact
            component={UserCredits}
          />
          <AdminRoute
            path="/payment/credits/records"
            exact
            component={CreditRecords}
          />
          <AdminRoute
            path="/payment/credits/records/:id"
            exact
            component={CreditRecordDetail}
          />
          <AdminRoute
            path="/payment/credits/transactions"
            exact
            component={CreditTransactions}
          />
          <AdminRoute path="/payment/credits" exact component={CreditSystem} />
          <PrivateRoute
            path="/payment/transactions"
            exact
            component={Transactions}
          />
          <PrivateRoute
            path="/payment/transactions/:id"
            exact
            component={TransactionDetail}
          />
          <AdminRoute
            path="/payment/efmarket_transactions"
            exact
            component={EFMarketTransactions}
          />
          <AdminRoute
            path="/payment/shipping_agent_transactions"
            exact
            component={ShippingAgentTransactions}
          />

          {/* EFPlatform */}
          <PrivateRoute
            path="/ecommerce/products"
            exact
            component={EcomProductList}
          />
          <PrivateRoute
            path="/ecommerce/products/add"
            exact
            component={CreateEcomProduct}
          />
          <PrivateRoute
            path="/ecommerce/products/:id/edit"
            exact
            component={EditEcomProduct}
          />
          <AdminRoute
            path="/ecommerce/orders/:id"
            exact
            component={EcomOrderDetail}
          />
          <PrivateRoute path="/ecommerce/orders" exact component={EcomOrder} />

          {/* Emarket */}
          <PrivateRoute
            path="/emarket/features"
            exact
            component={FeatureList}
          />
          <PrivateRoute
            path="/emarket/features/add"
            exact
            component={CreateFeature}
          />
          <PrivateRoute
            path="/emarket/features/:id/edit"
            exact
            component={EditFeature}
          />
          <PrivateRoute
            path="/emarket/products"
            exact
            component={ProductTable}
          />
          <PrivateRoute
            path="/emarket/products/add"
            exact
            component={CreateProduct}
          />
          <PrivateRoute
            path="/emarket/products/:id/edit"
            exact
            component={EditProduct}
          />
          <AdminRoute path="/emarket/posts" exact component={PostList} />
          <AdminRoute path="/emarket/posts/add" exact component={CreatePost} />
          <AdminRoute
            path="/emarket/posts/:id/edit"
            exact
            component={EditPost}
          />
          <AdminRoute path="/emarket/posts/:id" exact component={PostDetail} />
          <AdminRoute
            path="/emarket/quotations/:id"
            exact
            component={QuotationDetail}
          />
          <AdminRoute path="/emarket/quotations" exact component={Quotation} />
          <AdminRoute
            path="/emarket/orders/:id"
            exact
            component={OrderDetail}
          />
          <PrivateRoute path="/emarket/orders" exact component={Order} />

          {/* Logistics */}
          <PrivateRoute path="/emarket/locations" exact component={Locations} />
          <PrivateRoute
            path="/emarket/packaging_boxes"
            exact
            component={PackagingBoxes}
          />
          <PrivateRoute
            path="/emarket/packaging_boxes/add"
            exact
            component={CreatePackagingBox}
          />
          <PrivateRoute
            path="/emarket/packaging_boxes/:id/edit"
            exact
            component={EditPackagingBox}
          />
          <PrivateRoute
            path="/emarket/shipping_prices"
            exact
            component={ShippingPrices}
          />
          <PrivateRoute
            path="/emarket/shipping_prices/add"
            exact
            component={CreateShippingPrice}
          />
          <PrivateRoute
            path="/emarket/shipping_prices/:id/edit"
            exact
            component={EditShippingPrice}
          />
          <PrivateRoute
            path="/emarket/locations/add"
            exact
            component={CreateLocation}
          />
          <PrivateRoute
            path="/emarket/locations/:id/edit"
            exact
            component={EditLocation}
          />
          <PrivateRoute
            path="/emarket/routes-and-pricing"
            exact
            component={LocationPrice}
          />
          <PrivateRoute
            path="/emarket/routes-and-pricing/add"
            exact
            component={CreatePrice}
          />
          <PrivateRoute
            path="/emarket/routes-and-pricing/:id/edit"
            exact
            component={EditPrice}
          />
          <PrivateRoute path="/emarket/shipments" exact component={Shipments} />
          <PrivateRoute
            path="/emarket/shipments/:id"
            exact
            component={ShipmentDetail}
          />

          {/* Billings */}
          <AdminRoute
            path="/billings/phone"
            exact
            component={AdminPhoneBills}
          />
          <AdminRoute
            path="/billings/phone/:id"
            exact
            component={AdminPhoneBillDetail}
          />
          <AdminRoute
            path="/billings/phone/:id/edit"
            exact
            component={AdminPhoneBillForm}
          />
          <AdminRoute
            path="/billings/utility"
            exact
            component={AdminUtilityBills}
          />
          <AdminRoute
            path="/billings/utility/:id"
            exact
            component={AdminUtilityBillDetail}
          />
          <AdminRoute
            path="/billings/utility/:id/edit"
            exact
            component={AdminUtilityBillForm}
          />
          <AdminRoute
            path="/billings/providers"
            exact
            component={AdminProviders}
          />
          <AdminRoute
            path="/billings/providers/add"
            exact
            component={AdminCreateProvider}
          />
          {/* - */}

          <Route componenet={PageNotFound} />
        </Layout>
      </Route>
      <Route componenet={PageNotFound} />
    </Switch>
  </Router>
);

const connectToRedux = connect(mapStateToProps, {
  startFetchingLocalStorate: startFetchingLocalStorateThunkCreator,
});

const withLifeCycle = lifecycle({
  componentWillMount() {
    // eslint-disable-next-line
    this.props.startFetchingLocalStorate();
  },
});

const withSpinner = branch(
  flow(get('isFetchingLocalStorage'), overSome([eq(true), eq(null)])),
  renderComponent(LoadingSpinner)
);

const enhance = compose(connectToRedux, withLifeCycle, withSpinner);

export default enhance(AppRouter);
