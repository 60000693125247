import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_PROVIDERS_ID = 'FETCH_ADMIN_PROVIDERS';

const {
  actionCreator: fetchProvidersAC,
  dataSelector: providersRawSelector,
  errorSelector: fetchProvidersErrorSelector,
  isFetchingSelector: isFetchingProvidersSelector,
  updater: updateProvidersAC,
} = makeFetchAction(FETCH_ADMIN_PROVIDERS_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/providers`,
    params,
  }),
  method: 'GET',
}));

const providersDataSelector = createSelector(
  providersRawSelector,
  get('providers')
);

const totalItemSelector = createSelector(
  providersRawSelector,
  get('paging.total_items')
);

const pageSizeSelector = createSelector(
  providersRawSelector,
  get('paging.per_page')
);

const currentPageSelector = createSelector(
  providersRawSelector,
  get('paging.current_page')
);

export {
  FETCH_ADMIN_PROVIDERS_ID,
  fetchProvidersAC,
  fetchProvidersErrorSelector,
  isFetchingProvidersSelector,
  updateProvidersAC,
  providersDataSelector,
  totalItemSelector,
  pageSizeSelector,
  currentPageSelector,
};
