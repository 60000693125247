import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_PHONE_BILL_API_ID = 'UPDATE_PHONE_BILL';

const {
  actionCreator: updatePhoneBillAC,
  dataSelector: updatePhoneBillRawDataSelector,
  errorSelector: updatePhoneBillErrorSelector,
  isFetchingSelector: isUpdatingPhoneBillSelector,
} = makeFetchAction(UPDATE_PHONE_BILL_API_ID, (id, phone_bill) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/phone_bills/${id}`,
  method: 'PATCH',
  notify: true,
  body: phone_bill,
}));

const updatePhoneBillDataSelector = createSelector(
  updatePhoneBillRawDataSelector,
  get('phone_bill')
);

export {
  UPDATE_PHONE_BILL_API_ID,
  updatePhoneBillAC,
  updatePhoneBillDataSelector,
  updatePhoneBillErrorSelector,
  isUpdatingPhoneBillSelector,
};
