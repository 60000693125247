import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import React from 'react';

import {
  fetchPackagingBoxesAC,
  packagingBoxesDataSelector,
  fetchPackagingBoxesErrorSelector,
  isFetchingPackagingBoxesSelector,
  totalPackagingBoxesSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/packagingBoxes/list';
import { timeAgo } from '../../utils/formatDate';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';

const columnsGenerator = () => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'title',
  },
  {
    title: 'Size',
    dataIndex: 'description',
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text, record) => (
      <Button>
        <Link to={`/emarket/packaging_boxes/${record.id}/edit`}>Edit</Link>
      </Button>
    ),
  },
];

const PackagingBoxes = ({
  fetchPackagingBoxes,
  packagingBoxes,
  history,
  loading,
  errors,
  ...others
}) => {
  React.useEffect(() => {
    fetchPackagingBoxes();
  }, []);

  if (loading && !packagingBoxes) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div id="content">
      <PageTitle content="PackagingBoxes" />
      <div style={{ textAlign: 'right' }}>
        <button
          className="button button--primary"
          onClick={() => history.push(`/emarket/packaging_boxes/add`)}
        >
          Add New PackagingBox <i className="fas fa-plus" />
        </button>
      </div>
      <DataTable
        columns={columnsGenerator()}
        dataSource={packagingBoxes}
        dataFetcher={fetchPackagingBoxes}
        {...others}
      />
    </div>
  );
};

const enhance = connect(
  state => ({
    packagingBoxes: packagingBoxesDataSelector(state),
    loading: isFetchingPackagingBoxesSelector(state),
    errors: fetchPackagingBoxesErrorSelector(state),
    totalItems: totalPackagingBoxesSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchPackagingBoxes: fetchPackagingBoxesAC,
  }
);

export default enhance(PackagingBoxes);
