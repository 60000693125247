import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_USER_DEVICES_API_ID = 'FETCH_USER_DEVICES';

const {
  actionCreator: fetchUserDevicesAC,
  dataSelector: userDevicesRawSelector,
  errorSelector: fetchUserDevicesErrorSelector,
  isFetchingSelector: isFetchingUserDevicesSelector,
  updater: updateUserDevicesAC,
} = makeFetchAction(FETCH_USER_DEVICES_API_ID, (user_id, params) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/users/${user_id}/devices`,
    params,
  }),
  method: 'GET',
}));

const totalUserDevicesSelector = createSelector(
  userDevicesRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  userDevicesRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  userDevicesRawSelector,
  get('paging.per_page')
);

const userDevicesDataSelector = createSelector(
  userDevicesRawSelector,
  get('devices')
);

export {
  FETCH_USER_DEVICES_API_ID,
  fetchUserDevicesAC,
  userDevicesDataSelector,
  fetchUserDevicesErrorSelector,
  isFetchingUserDevicesSelector,
  updateUserDevicesAC,
  currentPageSelector,
  pageSizeSelector,
  totalUserDevicesSelector,
};
