import React from 'react';
import { Upload, message, Form, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { compose, withStateHandlers } from 'recompose';
import { get } from 'lodash/fp';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Image must smaller than 5MB!');
  }
  return isJpgOrPng && isLt2M;
}

const ImageUploader = ({
  loading,
  changeLoadingState,
  updateImageUrl,
  updateFileList,
  input,
  previewImage,
  handleCancel,
  handlePreview,
  imageUrl,
  fileList,
  ...props
}) => (
  <Form.Item {...props}>
    <Upload
      {...props}
      accept="image/*"
      className="image-uploader"
      customRequest={({ onSuccess }) => {
        setTimeout(() => {
          onSuccess('ok');
        }, 0);
      }}
      listType="picture-card"
      loading={loading}
      fileList={fileList}
      multiple={false}
      onChange={info => {
        updateFileList(info.fileList.slice(-1));
        input.onChange(info.file.originFileObj);
        if (info.file.status !== 'removed') {
          getBase64(info.file.originFileObj, image => {
            updateImageUrl({ imageUrl: image });
          });
        }
      }}
      onRemove={() => {
        updateImageUrl({ imageUrl: '' });
        input.onChange(null);
      }}
      beforeUpload={beforeUpload}
      onPreview={handlePreview}
    >
      <PlusOutlined style={{ fontSize: 24 }} />
      <div style={{ fontSize: 11 }}>Upload</div>
    </Upload>
    <Modal visible={previewImage} footer={null} onCancel={handleCancel}>
      <img src={get('value', input)} width="100%" alt="" />
    </Modal>
  </Form.Item>
);

const enhance = compose(
  withStateHandlers(
    ({ input }) => ({
      loading: false,
      previewImage: false,
      imageUrl: '',
      confirmLoading: false,
      fileList: get('value', input) && [
        { uid: '-1', name: 'file1', url: get('value', input), status: 'done' },
      ],
    }),
    {
      changeLoadingState: () => ({ loading }) => ({ loading }),
      handlePreview: () => () => ({ previewImage: true }),
      handleCancel: () => () => ({ previewImage: false }),
      updateImageUrl: () => ({ imageUrl }) => ({ imageUrl }),
      updateFileList: () => fileList => ({ fileList }),
    }
  )
);

export default enhance(ImageUploader);
