import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  createStaffAC,
  isCreatingStaffSelector,
  errorSelector,
} from '../../apiCalls/adminStaffs/create';
import StaffFormFields from './StaffFormFields';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import PageTitle from '../shared/PageTitle';

const StaffCreate = ({ history, submitting, createStaff }) => {
  return (
    <div id="content">
      <PageTitle content="Add new staff" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/staffs">Staffs</Link>
          </li>
          <li>New staff</li>
        </ul>
      </div>

      <Form
        layout="horizontal"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 12 }}
        onFinish={createStaff}
      >
        <StaffFormFields />
        <hr />
        <TextField
          name="password"
          type="password"
          label="Password"
          {...FORM_ITEM_LAYOUT}
          required
        />
        <div className="bill__buttons row align-items-center">
          <div className="col-md-7 order-md-2">
            <div className="_button-group text-right">
              <button
                className="button button--secondary"
                onClick={() => history.push(`/iam/staffs`)}
              >
                Dismiss
              </button>
              <button
                type="submit"
                className="button button--primary"
                disabled={submitting}
                style={{ marginLeft: 10 }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    createError: errorSelector(state),
    submitting: isCreatingStaffSelector(state),
  }),
  {
    createStaff: createStaffAC,
  }
);

export default connectWithRedux(StaffCreate);
