import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const FETCH_QUOTATION_API_ID = 'FETCH_QUOTATION';

const {
  actionCreator: fetchQuotationAC,
  dataSelector: rawQuotationDataSelector,
  isFetchingSelector: isFetchingQuotationSelector,
  errorSelector: fetchingQuotationErrorSelector,
} = makeFetchAction(FETCH_QUOTATION_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/quotations/${id}`,
  method: 'GET',
}));

const quotationDataSelector = rawQuotationDataSelector;

export {
  FETCH_QUOTATION_API_ID,
  fetchQuotationAC,
  quotationDataSelector,
  isFetchingQuotationSelector,
  fetchingQuotationErrorSelector,
};
