import React from 'react';
import { Modal } from 'antd';

const ApprovalButton = ({
  text,
  action,
  isApproving,
  isRejecting,
  approve,
  reject,
  objectId,
  data,
  ...others
}) => {
  const className =
    action === 'approve' ? 'button button--primary' : 'button button--danger';
  const disabled = action === 'approve' ? isApproving : isRejecting;
  const onClick = () => {
    Modal.confirm({
      title: 'Are you sure?',
      onOk: () =>
        action === 'approve' ? approve(objectId, data) : reject(objectId, data),
    });
  };

  return (
    <button
      style={{ marginLeft: 4, marginRight: 4 }}
      className={className}
      disabled={disabled}
      onClick={onClick}
      {...others}
    >
      {(text || action).toUpperCase()}
    </button>
  );
};

export default ApprovalButton;
