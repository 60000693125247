import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tag, Button } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import {
  fetchPostsAC,
  postsDataSelector,
  fetchPostsErrorSelector,
  isFetchingPostsSelector,
  totalPostsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/posts/list';
import { enablePostAC } from '../../apiCalls/posts/enable';
import { disablePostAC } from '../../apiCalls/posts/disable';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import {
  postTypesConfig,
  TAG_COLOR,
  // sizeUnitConfig,
  // unitColors,
  postStatusColors,
  postStatusConfig,
} from '../../utils/constants';
import { formatDate, timeAgo } from '../../utils/formatDate';

const columnsGenerator = () => [
  {
    title: 'Created at',
    dataIndex: 'created',
    render: value => formatDate(value),
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Created by',
    dataIndex: ['created_by', 'name'],
  },
  {
    title: 'Product',
    dataIndex: ['product', 'name'],
  },
  // {
  //   title: 'Features',
  //   dataIndex: 'features',
  //   render: features =>
  //     map(feature => (
  //       <Tag key={feature.id} color="geekblue" style={{ margin: 2 }}>
  //         {feature.name}
  //       </Tag>
  //     ))(features),
  // },
  {
    title: 'Price',
    dataIndex: 'price',
    render: price => `RM${price}`,
    align: 'right',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  // {
  //   title: 'Size',
  //   dataIndex: 'size',
  //   align: 'right',
  // },
  // {
  //   title: 'Unit',
  //   dataIndex: 'size_unit',
  //   render: unit => <Tag color={unitColors[unit]}>{sizeUnitConfig[unit]}</Tag>,
  // },
  {
    title: 'Type',
    dataIndex: 'post_type',
    render: type => <Tag color={TAG_COLOR[type]}>{postTypesConfig[type]}</Tag>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => (
      <Tag color={postStatusColors[status]}>{postStatusConfig[status]}</Tag>
    ),
  },
  {
    title: '',
    dataIndex: 'image',
    render: value => value && <FileImageOutlined />,
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: id => {
      const queryString = new URLSearchParams(window.location.search);

      return (
        <Button>
          <Link to={`/emarket/posts/${id}?${queryString}`}>Detail</Link>
        </Button>
      );
    },
  },
];

const Posts = ({ fetchPosts, loading, posts, error, ...others }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    fetchPosts(params);
    // eslint-disable-next-line
  }, [location, fetchPosts]);

  return (
    <>
      <PageTitle content="Posts" />
      {error && <ErrorMessage />}
      {posts && (
        <DataTable
          dataSource={posts}
          dataFetcher={fetchPosts}
          loading={loading}
          filters={params}
          {...others}
        />
      )}
    </>
  );
};

const connectToRedux = connect(
  state => ({
    posts: postsDataSelector(state),
    loading: isFetchingPostsSelector(state),
    error: fetchPostsErrorSelector(state),
    totalItems: totalPostsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    columns: columnsGenerator(),
  }),
  {
    fetchPosts: fetchPostsAC,
    enablePost: enablePostAC,
    disablePost: disablePostAC,
  }
);

export default connectToRedux(Posts);
