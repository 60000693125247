import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchAdminWithdrawalAC,
  adminWithdrawalDataSelector,
  fetchAdminWithdrawalErrorSelector,
  isFetchingAdminWithdrawalSelector,
} from '../../apiCalls/adminWithdrawals/get';
import {
  updateWithdrawalAC,
  updateWithdrawalDataSelector,
  updateWithdrawalErrorSelector,
  isUpdatingWithdrawalSelector,
} from '../../apiCalls/adminWithdrawals/edit';
import { convertFormData } from '../../utils/data';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import WithdrawalForm, { buildFormData } from './WithdrawalForm';

const AdminWithdrawalEdit = ({ withdrawalRequest, handleSubmit, match }) => {
  const { search } = useLocation();
  const requestsQuery = new URLSearchParams(search);

  return (
    <div id="content">
      <div className="container">
        <div className="dashboard-page">
          <h1 className="page-title">Withdrawal Management</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to={`/payment/withdrawals?${requestsQuery}`}>
                  Withdrawal Records
                </Link>
              </li>
              <li>Edit #{match.params.id}</li>
            </ul>
          </div>

          {withdrawalRequest && (
            <WithdrawalForm
              onFinish={handleSubmit}
              initialValues={buildFormData(withdrawalRequest.bank_record)}
              history
              pristine
              submitting
            />
          )}
        </div>
      </div>
    </div>
  );
};

const prepareProps = mapProps(
  ({ updateWithdrawal, updateWithdrawalError, ...others }) => ({
    formError: updateWithdrawalError,
    handleSubmit: data => {
      const object_id = others.match.params.id;
      if (data.invoice_image && data.invoice_image instanceof File) {
        const formData = convertFormData(data);
        return updateWithdrawal(object_id, formData);
      }
      if (data.invoice_image || data.invoice_image === '') {
        delete data.invoice_image;
      }
      return updateWithdrawal(object_id, data);
    },
    ...others,
  })
);

const enhance = compose(
  connect(
    state => ({
      withdrawalRequest: adminWithdrawalDataSelector(state),
      isFetching: isFetchingAdminWithdrawalSelector(state),
      isUpdating: isUpdatingWithdrawalSelector(state),
      error: fetchAdminWithdrawalErrorSelector(state),
      updatedError: updateWithdrawalErrorSelector(state),
      updatedWithdrawal: updateWithdrawalDataSelector(state),
    }),
    {
      fetchAdminWithdrawalEdit: fetchAdminWithdrawalAC,
      updateWithdrawal: updateWithdrawalAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      // eslint-disable-next-line
      this.props.fetchAdminWithdrawalEdit(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminWithdrawalEdit);
