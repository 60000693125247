import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { Descriptions, Tag, Button } from 'antd';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchAdminUserAC,
  adminUserDataSelector,
  fetchAdminUserErrorSelector,
  isFetchingAdminUserSelector,
} from '../../apiCalls/adminUsers/get';
import {
  fetchAdminUserKeyAC,
  secretKeySelector,
} from '../../apiCalls/adminUsers/generateKey';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import { userStatusColors } from '../../utils/constants';

const UserGenerateKey = ({ user, match, generateKey, secretKey }) => (
  <div className="">
    <div className="">
      <h1 className="page-title">Generate API key for developer account</h1>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/users">Users</Link>
          </li>
          <li>Generate API Keys for #{match.params.id}</li>
        </ul>
      </div>
      <div className="">
        {user ? (
          <Descriptions
            title="User details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
            <Descriptions.Item label="Phone">
              {user.country_code &&
                `+(${user.country_code}) ${user.contact_number}`}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {user.email ? user.email : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Person in charge">
              {user.person_in_charge}
            </Descriptions.Item>
            <Descriptions.Item label="Role">
              {user.roles &&
                user.roles.map(role => (
                  <Tag key={role} color="geekblue" style={{ margin: 2 }}>
                    {role}
                  </Tag>
                ))}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Tag color={userStatusColors[user.status]}>{user.status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Developer Secret Key">
              {secretKey}
            </Descriptions.Item>
          </Descriptions>
        ) : (
          <LoadingSpinner />
        )}
      </div>
      <hr />
      <div className="text-right">
        {user && (
          <Button.Group>
            <Button
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  generateKey(user.id);
                }
              }}
            >
              Generate Secret Key
            </Button>
          </Button.Group>
        )}
      </div>
    </div>
  </div>
);

const enhance = compose(
  connect(
    state => ({
      user: adminUserDataSelector(state),
      isFetching: isFetchingAdminUserSelector(state),
      error: fetchAdminUserErrorSelector(state),
      secretKey: secretKeySelector(state),
    }),
    {
      fetchAdminUser: fetchAdminUserAC,
      generateKey: fetchAdminUserKeyAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchAdminUser(id);
    },
  }),
  mapProps(props => {
    const { user, wallet, ...others } = props;
    return {
      user: get('user')(user),
      ...others,
    };
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(UserGenerateKey);
