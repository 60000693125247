import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tag, Button } from 'antd';
import queryString from 'query-string';
import {
  fetchQuotationsAC,
  quotationsDataSelector,
  fetchQuotationsErrorSelector,
  isFetchingQuotationsSelector,
  totalQuotationsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/quotations/list';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import {
  postTypesConfig,
  TAG_COLOR,
  quoteStatusColors,
  quoteStatusConfig,
  receiverByType,
} from '../../utils/constants';
import { formatDate, timeAgo } from '../../utils/formatDate';

const columnsGenerator = ({ quote_type }) => [
  {
    title: 'Created at',
    dataIndex: 'created',
    render: value => formatDate(value),
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Created by',
    visible: typeof receiverByType[quote_type] !== 'undefined',
    render: (text, record) => record.created_by.name,
  },
  {
    title: 'Buyer',
    visible: quote_type === 101,
    render: (text, record) =>
      record.quote_type === 100 ? record.created_by.name : record.receiver.name,
  },
  {
    title: 'Seller',
    visible: quote_type === 100,
    render: (text, record) =>
      record.quote_type === 101 ? record.created_by.name : record.receiver.name,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    render: price => `RM${price}`,
    align: 'right',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    align: 'right',
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    render: total_amount => `RM${total_amount}`,
    align: 'right',
  },
  {
    title: 'Type',
    dataIndex: 'quote_type',
    render: type => <Tag color={TAG_COLOR[type]}>{postTypesConfig[type]}</Tag>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => (
      <Tag color={quoteStatusColors[status]}>{quoteStatusConfig[status]}</Tag>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: id => {
      const queryString = new URLSearchParams(window.location.search);
      return (
        <Button>
          <Link to={`/emarket/quotations/${id}?${queryString}`}>Detail</Link>
        </Button>
      );
    },
  },
];

const Quotations = ({
  fetchQuotations,
  quotations,
  loading,
  error,
  ...others
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    fetchQuotations(params);
    // eslint-disable-next-line
  }, [location, fetchQuotations]);

  return (
    <>
      <PageTitle content="Quotations" />
      {error && <ErrorMessage />}
      <DataTable
        dataSource={quotations}
        dataFetcher={fetchQuotations}
        loading={loading}
        filters={params}
        columns={columnsGenerator(params)}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    quotations: quotationsDataSelector(state),
    loading: isFetchingQuotationsSelector(state),
    error: fetchQuotationsErrorSelector(state),
    totalItems: totalQuotationsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchQuotations: fetchQuotationsAC,
  }
);

export default connectToRedux(Quotations);
