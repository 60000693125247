import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_LOCATION_API_ID = 'CREATE_LOCATION';

const {
  actionCreator: createLocationAC,
  dataSelector: createdLocationRawDataSelector,
  errorSelector: createLocationErrorSelector,
  isFetchingSelector: isCreatingLocationSelector,
} = makeFetchAction(CREATE_LOCATION_API_ID, location => ({
  endpoint: `${EMARKET_ADMIN_API}/locations`,
  method: 'POST',
  notify: true,
  body: location,
}));

const createdLocationDataSelector = createdLocationRawDataSelector;

export {
  CREATE_LOCATION_API_ID,
  createLocationAC,
  createdLocationDataSelector,
  createLocationErrorSelector,
  isCreatingLocationSelector,
};
