import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createProductAC,
  isCreatingProductSelector,
  createProductErrorSelector,
} from '../../apiCalls/products/create';
import { convertFormData } from '../../utils/data';
import Form from './Form';
import PageTitle from '../shared/PageTitle';

const CreateProduct = ({ createProduct, submitting }) => {
  const onFinish = data => {
    if (
      data.image &&
      data.image[0] &&
      data.image[0].uid !== -1 &&
      data.image[0].originFileObj instanceof File
    ) {
      const formData = convertFormData({
        ...data,
        image: data.image[0].originFileObj,
      });
      return createProduct(formData);
    }

    if (data.image) delete data.image;
    return createProduct(data);
  };

  return (
    <>
      <PageTitle content="Products" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/emarket/products">Products</Link>
          </li>
          <li>Add new product</li>
        </ul>
      </div>

      <div className="row align-items-center">
        <div className="col-sm-6">
          <h4 className="page-small-title">Add New Product</h4>
        </div>
      </div>

      <Form
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        submitting={submitting}
      />
    </>
  );
};

const enhance = connect(
  state => ({
    createError: createProductErrorSelector(state),
    submitting: isCreatingProductSelector(state),
  }),
  {
    createProduct: createProductAC,
  }
);

export default enhance(CreateProduct);
