import { makeFetchAction } from 'redux-api-call';

import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DELETE_SHIPPING_PRICE_API_ID = 'DELETE_SHIPPING_PRICE';

const {
  actionCreator: deleteShippingPriceAC,
  dataSelector: deleteShippingPriceDataSelector,
  errorSelector: deleteShippingPriceErrorSelector,
  isFetchingSelector: isDeletingShippingPriceSelector,
} = makeFetchAction(DELETE_SHIPPING_PRICE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingprices/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_SHIPPING_PRICE_API_ID,
  deleteShippingPriceAC,
  deleteShippingPriceDataSelector,
  deleteShippingPriceErrorSelector,
  isDeletingShippingPriceSelector,
};
