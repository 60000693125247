import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_PACKAGING_BOX_API_ID = 'UPDATE_PACKAGING_BOX';

const {
  actionCreator: updatePackagingBoxAC,
  dataSelector: updatePackagingBoxDataSelector,
  errorSelector: updatePackagingBoxErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_PACKAGING_BOX_API_ID, (id, packagingBox) => ({
  endpoint: `${EMARKET_ADMIN_API}/packagingboxes/${id}`,
  method: 'PATCH',
  notify: true,
  body: packagingBox,
}));

export {
  UPDATE_PACKAGING_BOX_API_ID,
  updatePackagingBoxAC,
  updatePackagingBoxDataSelector,
  updatePackagingBoxErrorSelector,
  isUpdatingSelector,
};
