import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_QUOTATIONS_API_ID = 'FETCH_QUOTATIONS';

const {
  actionCreator: fetchQuotationsAC,
  dataSelector: quotationsRawSelector,
  errorSelector: fetchQuotationsErrorSelector,
  isFetchingSelector: isFetchingQuotationsSelector,
  updater: updateQuotationsAC,
} = makeFetchAction(FETCH_QUOTATIONS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/quotations`,
    params,
  }),
  method: 'GET',
}));

const totalQuotationsSelector = createSelector(
  quotationsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  quotationsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  quotationsRawSelector,
  get('paging.per_page')
);

const quotationsDataSelector = createSelector(
  quotationsRawSelector,
  get('results')
);

export {
  FETCH_QUOTATIONS_API_ID,
  fetchQuotationsAC,
  quotationsDataSelector,
  fetchQuotationsErrorSelector,
  isFetchingQuotationsSelector,
  updateQuotationsAC,
  currentPageSelector,
  pageSizeSelector,
  totalQuotationsSelector,
};
