import { Descriptions, Tag, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { activateUserAC } from '../../apiCalls/adminUsers/activate';
import { deactivateUserAC } from '../../apiCalls/adminUsers/deactivate';
import {
  fetchAdminUserAC,
  adminUserDataSelector,
  fetchAdminUserErrorSelector,
  isFetchingAdminUserSelector,
} from '../../apiCalls/adminUsers/get';
import {
  fetchUserDevicesAC,
  userDevicesDataSelector,
  fetchUserDevicesErrorSelector,
  isFetchingUserDevicesSelector,
} from '../../apiCalls/userDevices/list';
import {
  fetchWalletAC,
  walletDataSelector,
  fetchWalletErrorSelector,
  isFetchingWalletSelector,
} from '../../apiCalls/wallets/get';
import { formatDate } from '../../utils/formatDate';
import { userStatusColors } from '../../utils/constants';
import { verifyUserAC } from '../../apiCalls/adminUsers/verify';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import UserAction from '../shared/UserAction';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
  },
  {
    title: 'App version',
    dataIndex: 'app_version',
    render: value => value || '-',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    render: value => (value ? 'Active' : 'Inactive'),
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    render: value => formatDate(value),
  },
];

const AdminUserDetail = ({
  user,
  isActivating,
  isVerifying,
  isDeactivating,
  deactivateUser,
  wallet,
  isWalletFetching,
  isWalletError,
  devices,
  isDeviceFetching,
  deviceError,
  verifyUser,
  activateUser,
  fetchUserDevices,
  match,
  ...others
}) => {
  const { search } = useLocation();
  const usersQuery = new URLSearchParams(search);

  return (
    <div className="">
      <div className="">
        <h1 className="page-title">User Management</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to={`/iam/users?${usersQuery}`}>Users</Link>
            </li>
            <li>#{match.params.id}</li>
          </ul>
        </div>
        <div className="">
          {user ? (
            <Descriptions
              title="User details"
              bordered
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="ID">{user.id}</Descriptions.Item>
              <Descriptions.Item label="Profile Photo">
                {user.avatar && user.avatar.origin_url ? (
                  <a
                    href={user.avatar.origin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={user.avatar.thumb_url} alt="" />
                  </a>
                ) : (
                  '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Name">{user.name}</Descriptions.Item>
              <Descriptions.Item label="Identification Number">
                {user.identification_number}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {user.country_code &&
                  `+(${user.country_code}) ${user.contact_number}`}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {user.email ? user.email : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Person in charge">
                {user.person_in_charge}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {user.address}
              </Descriptions.Item>
              <Descriptions.Item label="Area">{user.area}</Descriptions.Item>
              <Descriptions.Item label="Role">
                {user.roles &&
                  user.roles.map(role => (
                    <Tag key={role} color="geekblue" style={{ margin: 2 }}>
                      {role}
                    </Tag>
                  ))}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag color={userStatusColors[user.status]}>{user.status}</Tag>
              </Descriptions.Item>
              <Descriptions.Item label="Bank Name">
                {user.bank_name}
              </Descriptions.Item>
              <Descriptions.Item label="Bank Account Name">
                {user.bank_account_name}
              </Descriptions.Item>
              <Descriptions.Item label="Bank Account Number">
                {user.bank_account_number}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            isWalletFetching && <LoadingSpinner />
          )}
        </div>
        <hr />
        <div className="">
          {user && wallet ? (
            <Descriptions
              title="Wallet details"
              bordered
              column={2}
              layout="horizontal"
            >
              <Descriptions.Item label="Payment Balance">
                <Link
                  to={`/payment/transactions/?user_id=${user.id}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  {wallet.payment}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Credit Balance">
                <Link
                  to={`/payment/credits/users/${user.id}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  {wallet.credit}
                </Link>
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <>
              {isWalletFetching && <LoadingSpinner />}
              {isWalletError && <ErrorMessage />}
            </>
          )}
        </div>
        <hr />
        <div className="">
          <div className="ant-descriptions-title">Devices</div>
          <DataTable
            dataSource={devices}
            dataFetcher={fetchUserDevices}
            columns={columns}
            {...others}
          />
        </div>
        <hr />
        <div className="text-right">
          {user && (
            <Button.Group>
              <Button>
                <Link
                  to={`/payment/payments/?user_id=${user.id}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  View Payment History
                </Link>
              </Button>
              <Button>
                <Link
                  to={`/payment/transactions/?user_id=${user.id}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  View Transactions
                </Link>
              </Button>
              <Button>
                <Link
                  to={`/iam/users/${user.id}/edit?${usersQuery}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  Edit
                </Link>
              </Button>
              <Button>
                <Link
                  to={`/iam/users/${user.id}/password?${usersQuery}`}
                  style={{ textTransform: 'uppercase' }}
                >
                  Change Password
                </Link>
              </Button>
              {user.actions.map(action => (
                <UserAction
                  key={action}
                  action={action}
                  objectId={match.params.id}
                  isVerifying={isVerifying}
                  isActivating={isActivating}
                  isDeactivating={isDeactivating}
                  verify={verifyUser}
                  activate={activateUser}
                  deactivate={deactivateUser}
                />
              ))}
            </Button.Group>
          )}
        </div>
      </div>
    </div>
  );
};

const enhance = compose(
  connect(
    state => ({
      user: adminUserDataSelector(state),
      isFetching: isFetchingAdminUserSelector(state),
      error: fetchAdminUserErrorSelector(state),
      wallet: walletDataSelector(state),
      isWalletFetching: isFetchingWalletSelector(state),
      walletError: fetchWalletErrorSelector(state),
      devices: userDevicesDataSelector(state),
      isDeviceFetching: isFetchingUserDevicesSelector(state),
      devicesError: fetchUserDevicesErrorSelector(state),
    }),
    {
      fetchAdminUser: fetchAdminUserAC,
      activateUser: activateUserAC,
      verifyUser: verifyUserAC,
      deactivateUser: deactivateUserAC,
      fetchWallet: fetchWalletAC,
      fetchUserDevices: fetchUserDevicesAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchAdminUser(id);
      this.props.fetchWallet(id);
      this.props.fetchUserDevices(id, {});
    },
  }),
  mapProps(props => {
    const { user, wallet, ...others } = props;
    return {
      user: get('user')(user),
      wallet: get('wallet')(wallet),
      walletError: get('walletError')(others),
      deviceError: get('devicesError')(others),
      ...others,
    };
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(AdminUserDetail);
