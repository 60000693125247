import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { COMPLETE_ORDER_API_ID } from '../apiCalls/ecomOrders/complete';
import { CANCEL_ORDER_API_ID } from '../apiCalls/ecomOrders/cancel';
import { fetchOrderAC, orderDataSelector } from '../apiCalls/ecomOrders/get';

const refreshListViewOnComplete = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(COMPLETE_ORDER_API_ID))),
    map(() => {
      const order = orderDataSelector(state$.value);
      return fetchOrderAC(order.id);
    })
  );

const refreshListViewOnCancel = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CANCEL_ORDER_API_ID))),
    map(() => {
      const order = orderDataSelector(state$.value);
      return fetchOrderAC(order.id);
    })
  );

export default combineEpics(refreshListViewOnComplete, refreshListViewOnCancel);
