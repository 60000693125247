import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { Tag, Button } from 'antd';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchPricesAC,
  pricesDataSelector,
  fetchPricesErrorSelector,
  isFetchingPricesSelector,
  totalPricesSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/locationPrices/list';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import { timeAgo } from '../../utils/formatDate';
import {
  methodConfig,
  methodColors,
} from '../../utils/constants';

const columnsGenerator = () => [
  {
    title: 'Route ID',
    dataIndex: 'id',
  },
  {
    title: 'From',
    dataIndex: 'source',
    render: value => value.name,
  },
  {
    title: 'To',
    dataIndex: 'destination',
    render: value => value.name,
  },
  {
    title: 'Type',
    dataIndex: 'method',
    render: value => <Tag color={methodColors[value]}>{methodConfig[value]}</Tag>
  },
  {
    title: 'Price /kg',
    dataIndex: 'price',
  },
  {
    title: 'Last update',
    dataIndex: 'modified',
    render: value => timeAgo(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button>
        <Link to={`/emarket/routes-and-pricing/${record.id}/edit`}>Edit</Link>
      </Button>
    ),
  },
];

const Pricing = ({ fetchPrices, prices, history, ...others }) => (
  <div id="content">
    <PageTitle content="Routes and Pricing" />
    <div style={{ textAlign: 'right' }}>
      <button
        className="button button--primary"
        onClick={() => history.push(`/emarket/routes-and-pricing/add`)}
      >
        Add New Route <i className="fas fa-plus" />
      </button>
    </div>
    <DataTable dataSource={prices} dataFetcher={fetchPrices} {...others} />
  </div>
);

const enhance = compose(
  connect(
    state => ({
      prices: pricesDataSelector(state),
      loading: isFetchingPricesSelector(state),
      error: fetchPricesErrorSelector(state),
      totalItems: totalPricesSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchPrices: fetchPricesAC,
    }
  ),
  mapProps(({ deleteProduct, ...others }) => ({
    columns: columnsGenerator({ deleteProduct }),
    ...others,
  })),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchPrices();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Pricing);
