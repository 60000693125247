import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import PhonePlanSelect from '../shared/PhonePlanSelect';
import Uploader from '../shared/form/Uploader';

const PhoneBillForm = ({ handleSubmit, submitting, pristine }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={handleSubmit}
    labelAlign="left"
  >
    <TextField
      name="amount"
      type="number"
      label="Amount"
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <TextField
      name="phone_number"
      type="text"
      label="Phone Number"
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <TextField
      name="provider"
      type="text"
      label="Provider"
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <PhonePlanSelect
      name="plan"
      type="text"
      label="Plan"
      {...FORM_ITEM_LAYOUT}
      disabled
    />
    <Uploader name="invoice_image" label="Invoice" {...FORM_ITEM_LAYOUT} />
    <TextField
      name="note"
      multiline
      type="text"
      label="Note"
      {...FORM_ITEM_LAYOUT}
    />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting || pristine}
          >
            Submit
          </button>
          <Link
            className="button button--secondary"
            to="/billings/phone"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Link>
        </div>
      </div>
    </div>
  </Form>
);

export const parseInitialValues = ({ invoice, ...others }) => ({
  invoice_image: invoice.origin_url,
  ...others,
});

export default PhoneBillForm;
