import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const APPROVE_PHONE_BILL_API_ID = 'APPROVE_PHONE_BILL';

const {
  actionCreator: approvePhoneBillAC,
  dataSelector: approvePhoneBillDataSelector,
  errorSelector: approvePhoneBillErrorSelector,
  isFetchingSelector: isApprovingPhoneBillSelector,
} = makeFetchAction(APPROVE_PHONE_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/phone_bills/${id}/approve`,
  method: 'POST',
  notify: true,
}));

export {
  APPROVE_PHONE_BILL_API_ID,
  approvePhoneBillAC,
  approvePhoneBillDataSelector,
  approvePhoneBillErrorSelector,
  isApprovingPhoneBillSelector,
};
