import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_ADMIN_REQUEST_API_ID = 'GET_ADMIN_REQUEST';

const {
  actionCreator: fetchAdminRequestAC,
  dataSelector: adminRequestDataSelector,
  errorSelector: fetchAdminRequestErrorSelector,
  isFetchingSelector: isFetchingAdminRequestSelector,
} = makeFetchAction(GET_ADMIN_REQUEST_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/requests/${id}`,
  method: 'GET',
}));

export {
  GET_ADMIN_REQUEST_API_ID,
  fetchAdminRequestAC,
  fetchAdminRequestErrorSelector,
  isFetchingAdminRequestSelector,
  adminRequestDataSelector,
};
