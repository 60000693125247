import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { ENABLE_PROVIDER_API_ID } from '../apiCalls/adminProviders/enable';
import { DISABLE_PROVIDER_API_ID } from '../apiCalls/adminProviders/disable';
import { CREATE_PROVIDER_API_ID } from '../apiCalls/adminProviders/create';
import {
  fetchProvidersAC,
  currentPageSelector,
} from '../apiCalls/adminProviders/list';
import routerHistory from '../routerHistory';

const redirectOnCreateSuccess = action$ =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(CREATE_PROVIDER_API_ID)
      )
    ),
    map(() => {
      routerHistory.push(`/billings/providers`);
    })
  );

const refreshListViewOnEnable = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(ENABLE_PROVIDER_API_ID)
      )
    ),
    map(() => {
      const page = currentPageSelector(state$.value);
      return fetchProvidersAC({ page, per_page: 10 });
    })
  );

const refreshListViewOnDisable = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(DISABLE_PROVIDER_API_ID)
      )
    ),
    map(() => {
      const page = currentPageSelector(state$.value);
      return fetchProvidersAC({ page, per_page: 10 });
    })
  );

export default combineEpics(
  refreshListViewOnEnable,
  refreshListViewOnDisable,
  redirectOnCreateSuccess
);
