import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_TRANSACTIONS_API_ID = 'FETCH_TRANSACTIONS';

const {
  actionCreator: fetchTransactionsAC,
  dataSelector: transactionsRawSelector,
  errorSelector: fetchTransactionsErrorSelector,
  isFetchingSelector: isFetchingTransactionsSelector,
  updater: updateTransactionsAC,
} = makeFetchAction(FETCH_TRANSACTIONS_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/transactions`,
    params,
  }),
  method: 'GET',
}));

const totalTransactionsSelector = createSelector(
  transactionsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  transactionsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  transactionsRawSelector,
  get('paging.per_page')
);

const transactionsDataSelector = createSelector(
  transactionsRawSelector,
  get('transactions')
);

export {
  FETCH_TRANSACTIONS_API_ID,
  fetchTransactionsAC,
  transactionsDataSelector,
  fetchTransactionsErrorSelector,
  isFetchingTransactionsSelector,
  updateTransactionsAC,
  currentPageSelector,
  pageSizeSelector,
  totalTransactionsSelector,
};
