import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, startCase } from 'lodash/fp';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import formatDate from '../../utils/formatDate';
import {
  fetchAdminPhoneBillAC,
  adminPhoneBillDataSelector,
  fetchAdminPhoneBillErrorSelector,
  isFetchingAdminPhoneBillSelector,
} from '../../apiCalls/adminPhoneBill/get';
import {
  approvePhoneBillAC,
  approvePhoneBillErrorSelector,
  isApprovingPhoneBillSelector,
} from '../../apiCalls/adminPhoneBill/approve';
import {
  rejectPhoneBillAC,
  rejectPhoneBillErrorSelector,
  isRejectingPhoneBillSelector,
} from '../../apiCalls/adminPhoneBill/reject';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import Status from '../shared/Status';
import ApprovalButton from '../shared/ApprovalButton';

const AdminPhoneBillDetail = ({
  phoneBill,
  isApproving,
  isRejecting,
  match,
  approvePhoneBill,
  rejectPhoneBill,
}) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Phone Bills</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/billings/phone">Phone Bills</Link>
            </li>
            <li>#{match.params.id}</li>
          </ul>
        </div>
        {phoneBill && (
          <div className="bill">
            <div className="row">
              <div className="col">
                <div className="tags">
                  <span className="_item">Requester</span>
                </div>
                <br />
                <strong>{phoneBill.requester.name}</strong>
                <br />
                {phoneBill.requester.area}
              </div>
            </div>
            <br />

            <div className="bill__block">
              <h5 className="bill__label">ID</h5>
              <strong>{phoneBill.id}</strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Phone Number</h5>
              <strong className="font16">{phoneBill.phone_number}</strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Provider</h5>
              <strong className="font16">{phoneBill.provider}</strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Plan</h5>
              <strong className="font16">{startCase(phoneBill.plan)}</strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Amount</h5>
              <strong className="font16">RM{phoneBill.amount}</strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Requested</h5>
              {formatDate(phoneBill.created_at)}
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Invoice</h5>
              <strong className="font16">
                {phoneBill.invoice && phoneBill.invoice.origin_url ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={phoneBill.invoice.origin_url}
                  >
                    <img
                      height={500}
                      src={phoneBill.invoice.origin_url}
                      alt="invoice"
                    />
                  </a>
                ) : (
                  'N/A'
                )}
              </strong>
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Status</h5>
              <Status status={phoneBill.status} />
            </div>
            <div className="bill__block">
              <h5 className="bill__label">Notes</h5>
              <div>{phoneBill.note}</div>
            </div>
            <div className="bill__buttons row align-items-center">
              <div className="col-md-6" />
              <div className="col-md-6 order-md-2">
                <div className="_button-group text-right">
                  <Link
                    to={`/billings/phone/${phoneBill.id}/edit`}
                    className="button button--primary"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Edit
                  </Link>
                  {phoneBill.actions.map(action => (
                    <ApprovalButton
                      key={action}
                      action={action}
                      objectId={match.params.id}
                      isRejecting={isRejecting}
                      isApproving={isApproving}
                      approve={approvePhoneBill}
                      reject={rejectPhoneBill}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
);

const enhance = compose(
  connect(
    state => ({
      phoneBill: adminPhoneBillDataSelector(state),
      isFetching: isFetchingAdminPhoneBillSelector(state),
      isRejecting: isRejectingPhoneBillSelector(state),
      isApproving: isApprovingPhoneBillSelector(state),
      error: fetchAdminPhoneBillErrorSelector(state),
      approvedError: approvePhoneBillErrorSelector(state),
      rejectedError: rejectPhoneBillErrorSelector(state),
    }),
    {
      fetchAdminPhoneBillDetail: fetchAdminPhoneBillAC,
      approvePhoneBill: approvePhoneBillAC,
      rejectPhoneBill: rejectPhoneBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchAdminPhoneBillDetail(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(AdminPhoneBillDetail);
