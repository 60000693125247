import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import {
  fetchAdminUtilityBillAC,
  adminUtilityBillDataSelector,
  fetchAdminUtilityBillErrorSelector,
  isFetchingAdminUtilityBillSelector,
} from '../../apiCalls/adminUtilityBill/get';
import {
  updateUtilityBillAC,
  updateUtilityBillDataSelector,
  updateUtilityBillErrorSelector,
  isUpdatingUtilityBillSelector,
} from '../../apiCalls/adminUtilityBill/edit';
import { convertFormData } from '../../utils/data';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import UtilityBillForm, { buildFormData } from './UtilityBillForm';
import {
  fetchProvidersAC,
  providersDataSelector,
  isFetchingProvidersSelector,
} from '../../apiCalls/adminProviders/list';

const AdminUtilityBillEdit = ({ utilityBill, providers, handleSubmit, match }) => (
  <div id="content">
    <div className="container">
      <div className="dashboard-page">
        <h1 className="page-title">Utility Bills</h1>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/billings/utility">Utility Bills</Link>
            </li>
            <li>Edit #{match.params.id}</li>
          </ul>
        </div>

        {providers && utilityBill && (
          <UtilityBillForm
            onFinish={handleSubmit}
            initialValues={buildFormData(utilityBill, providers)}
            history
            pristine
            submitting
          />
        )}
      </div>
    </div>
  </div>
);

const prepareProps = mapProps(
  ({ updateUtilityBill, updateUtilityBillError, ...others }) => ({
    formError: updateUtilityBillError,
    handleSubmit: data => {
      const object_id = others.match.params.id;
      if (data.invoice_image && data.invoice_image instanceof File) {
        const formData = convertFormData(data);
        return updateUtilityBill(object_id, formData);
      }
      if (data.invoice_image || data.invoice_image === '') {
        delete data.invoice_image;
      }
      return updateUtilityBill(object_id, data);
    },
    ...others,
  })
);

const enhance = compose(
  connect(
    state => ({
      utilityBill: adminUtilityBillDataSelector(state),
      isFetching: isFetchingAdminUtilityBillSelector(state),
      isUpdating: isUpdatingUtilityBillSelector(state),
      error: fetchAdminUtilityBillErrorSelector(state),
      updatedError: updateUtilityBillErrorSelector(state),
      updatedUtilityBill: updateUtilityBillDataSelector(state),
      providers: providersDataSelector(state),
      isProvidersFetching: isFetchingProvidersSelector(state),
    }),
    {
      fetchAdminUtilityBillEdit: fetchAdminUtilityBillAC,
      fetchProviders: fetchProvidersAC,
      updateUtilityBill: updateUtilityBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      // eslint-disable-next-line
      this.props.fetchAdminUtilityBillEdit(id);
      // eslint-disable-next-line
      this.props.fetchProviders();

    },
  }),
  branch(get('isFetching') && get('isProvidersFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminUtilityBillEdit);
