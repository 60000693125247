import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import ProviderCategorySelect from '../shared/ProviderCategorySelect';
import Uploader from '../shared/form/Uploader';

const ProviderForm = ({ onFinish, submitting }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    onFinish={onFinish}
    labelAlign="left"
  >
    <TextField
      name="name"
      type="text"
      label="Name"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <ProviderCategorySelect
      name="category"
      label="Category"
      {...FORM_ITEM_LAYOUT}
      required
    />
    <Uploader name="logo" label="Logo" type="text" {...FORM_ITEM_LAYOUT} />

    <div className="bill__buttons row align-items-center">
      <div className="col-md-6" />
      <div className="col-md-6 order-md-2">
        <div className="_button-group text-right">
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting}
          >
            Submit
          </button>
          <Link
            className="button button--secondary"
            to="/billings/providers"
            style={{ marginLeft: 10 }}
          >
            Dismiss
          </Link>
        </div>
      </div>
    </div>
  </Form>
);

export default ProviderForm;
