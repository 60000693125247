import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_LOCATION_API_ID } from '../apiCalls/locations/create';
import { UPDATE_LOCATION_API_ID } from '../apiCalls/locations/update';
import { DELETE_LOCATION_API_ID } from '../apiCalls/locations/delete';
import routerHistory from '../routerHistory';

const handleCreateLocationSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_LOCATION_API_ID))),
    map(() => {
      routerHistory.push('/emarket/locations');
    })
  );

const handleEditLocationSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_LOCATION_API_ID))),
    map(() => {
      routerHistory.push('/emarket/locations');
    })
  );

const refreshListViewOnDelete = action$ =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(DELETE_LOCATION_API_ID))),
    map(() => {
      routerHistory.push('/emarket/locations');
    })
  );

export default combineEpics(
  handleCreateLocationSuccess,
  handleEditLocationSuccess,
  refreshListViewOnDelete
);
