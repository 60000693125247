import { ACTIONS } from 'redux-api-call';
import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { ACTIVATE_STAFF_API_ID } from '../../apiCalls/adminStaffs/activate';
import { updateStaffAC } from '../../apiCalls/adminStaffs/get';

const handleActivateStaffSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(ACTIVATE_STAFF_API_ID))),
    tap(action => {
      console.log(action);
    }),
    map(action => {
      const staff = get('payload.json.staff')(action);
      return updateStaffAC(staff, store$.value);
    })
  );

export default handleActivateStaffSuccess;
