import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_EFBANK_TRANSACTIONS_API_ID = 'FETCH_EFBANK_TRANSACTIONS';

const {
  actionCreator: fetchEfbankTransactionsAC,
  dataSelector: efbankTransactionsRawSelector,
  errorSelector: fetchEfbankTransactionsErrorSelector,
  isFetchingSelector: isFetchingEfbankTransactionsSelector,
} = makeFetchAction(FETCH_EFBANK_TRANSACTIONS_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/transactions/efbank`,
    params,
  }),
  method: 'GET',
}));

const totalEfbankTransactionsSelector = createSelector(
  efbankTransactionsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  efbankTransactionsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  efbankTransactionsRawSelector,
  get('paging.per_page')
);

const efbankTransactionsDataSelector = createSelector(
  efbankTransactionsRawSelector,
  get('transactions')
);

export {
  FETCH_EFBANK_TRANSACTIONS_API_ID,
  fetchEfbankTransactionsAC,
  efbankTransactionsDataSelector,
  fetchEfbankTransactionsErrorSelector,
  isFetchingEfbankTransactionsSelector,
  currentPageSelector,
  pageSizeSelector,
  totalEfbankTransactionsSelector,
};
