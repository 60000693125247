import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import React from 'react';

import { completeShippingRequestAC } from '../../apiCalls/shippingRequests/complete';

const CompleteButton = ({ id, completeShippingRequest }) => {
  return (
    <Button
      type="primary"
      onClick={() =>
        Modal.confirm({
          title: 'Are you sure?',
          onOk: () => completeShippingRequest(id),
        })
      }
    >
      Complete
    </Button>
  );
};

const connectWithRedux = connect(null, {
  completeShippingRequest: completeShippingRequestAC,
});

export default connectWithRedux(CompleteButton);
