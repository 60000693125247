import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Button } from 'antd';
import { formatDate } from '../../utils/formatDate';
import {
  fetchAdminWithdrawalsAC,
  adminWithdrawalsDataSelector,
  fetchAdminWithdrawalsErrorSelector,
  isFetchingAdminWithdrawalsSelector,
  totalWithdrawalSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/adminWithdrawals/list';
import ErrorMessage from '../shared/ErrorMessage';
import Status from '../shared/Status';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'User',
    dataIndex: ['user', 'name'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    align: 'center',
    render: ({ origin_url }) =>
      origin_url ? (
        <a href={origin_url} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-paperclip" />
        </a>
      ) : (
        '-'
      ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const queryString = new URLSearchParams(window.location.search);

      return (
        <Button.Group>
          <Button>
            <Link to={`/payment/withdrawals/${record.id}?${queryString}`}>
              Detail
            </Link>
          </Button>
          {/* <Button>
            <Link to={`/payment/withdrawals/${record.id}/edit?${queryString}`}>
              Edit
            </Link>
          </Button> */}
        </Button.Group>
      );
    },
  },
];

const AdminWithdrawals = ({
  withdrawalRequests,
  fetchAdminWithdrawals,
  loading,
  error,
  ...others
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search, { arrayFormat: 'bracket' });

  useEffect(() => {
    fetchAdminWithdrawals({ filter: params });
    // eslint-disable-next-line
  }, [location, fetchAdminWithdrawals]);

  return (
    <>
      <PageTitle content="Withdrawal Records" />
      {error && <ErrorMessage />}
      <DataTable
        dataSource={withdrawalRequests}
        dataFetcher={fetchAdminWithdrawals}
        loading={loading}
        filters={{ filter: params }}
        columns={columns}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    withdrawalRequests: adminWithdrawalsDataSelector(state),
    loading: isFetchingAdminWithdrawalsSelector(state),
    error: fetchAdminWithdrawalsErrorSelector(state),
    totalItems: totalWithdrawalSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchAdminWithdrawals: fetchAdminWithdrawalsAC,
  }
);

export default connectToRedux(AdminWithdrawals);
