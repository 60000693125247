import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const CREATE_TOPUP_RECORD_API_ID = 'CREATE_TOPUP_RECORD';

const {
  actionCreator: createTopupRecordAC,
  dataSelector: createTopupRecordDataSelector,
  errorSelector: createTopupRecordErrorSelector,
  isFetchingSelector: isCreatingTopupRecordSelector,
} = makeFetchAction(CREATE_TOPUP_RECORD_API_ID, params => ({
  endpoint: `${API_ADMIN_ENDPOINT}/topups`,
  method: 'POST',
  notify: true,
  body: params,
}));

export {
  CREATE_TOPUP_RECORD_API_ID,
  createTopupRecordAC,
  createTopupRecordDataSelector,
  createTopupRecordErrorSelector,
  isCreatingTopupRecordSelector,
};
