import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CANCEL_ORDER_API_ID = 'CANCEL_ORDER';

const {
  actionCreator: cancelOrderAC,
  dataSelector: cancelOrderDataSelector,
  errorSelector: cancelOrderErrorSelector,
  isFetchingSelector: isSubmittingOrderSelector,
} = makeFetchAction(CANCEL_ORDER_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/orders/${id}/actions`,
  method: 'PUT',
  notify: true,
  body: { action: 'cancel' },
}));

export {
  CANCEL_ORDER_API_ID,
  cancelOrderAC,
  cancelOrderDataSelector,
  cancelOrderErrorSelector,
  isSubmittingOrderSelector,
};
