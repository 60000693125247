import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const DELETE_USER_API_ID = 'DELETE_USER';

const {
  actionCreator: deleteUserAC,
  dataSelector: deleteUserDataSelector,
  errorSelector: deleteUserErrorSelector,
  isFetchingSelector: isDeactivatingSelector,
} = makeFetchAction(DELETE_USER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_USER_API_ID,
  deleteUserAC,
  deleteUserDataSelector,
  deleteUserErrorSelector,
  isDeactivatingSelector,
};
