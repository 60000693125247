import React from 'react';
import { Spin } from 'antd';

const LoadingSpinner = () => (
  <div id="content">
    <div className="container" style={{ textAlign: 'center', paddingTop: 100 }}>
      <Spin tip="Loading..." />
    </div>
  </div>
);

export default LoadingSpinner;
