import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createLocationAC,
  isCreatingLocationSelector,
  createLocationErrorSelector,
} from '../../apiCalls/locations/create';
import Form from './Form';
import PageTitle from '../shared/PageTitle';

const CreateLocation = ({ handleSubmit, submitting }) => (
  <>
    <PageTitle content="Locations" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/locations">Locations</Link>
        </li>
        <li>Add new location</li>
      </ul>
    </div>

    <div className="row align-items-center">
      <div className="col-sm-6">
        <h4 className="page-small-title">Add New Location</h4>
      </div>
    </div>

    <Form onFinish={handleSubmit} submitting={submitting} />
  </>
);

const enhance = connect(
  state => ({
    createError: createLocationErrorSelector(state),
    submitting: isCreatingLocationSelector(state),
  }),
  {
    handleSubmit: location => {
      return createLocationAC(location);
    },
  }
);

export default enhance(CreateLocation);
