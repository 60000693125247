import React from 'react';
import { Tag } from 'antd';

const shippingStatusClass = {
  pending: '#f1c40f',
  picking: '#f1c40f',
  picked: '#f1c40f',
  delivering: '#f1c40f',
  delivered: '#f1c40f',
  draft: 'grey',
  completed: 'green',
  cancelled: '#ed6b75',
};

const statusClass = {
  pending: '#f1c40f',
  refunded: '#ed6b75',
  rejected: '#ed6b75',
  inactive: '#ed6b75',
  unverify: '#f1c40f',
  approved: '#36c6d3',
  active: '#36c6d3',
  verified: '#36c6d3',
  done: 'green',
  success: 'green',
};

export const ShippingStatus = ({ status }) => (
  <Tag color={shippingStatusClass[status]}>{status}</Tag>
);
const Status = ({ status }) => <Tag color={statusClass[status]}>{status}</Tag>;

export default Status;
