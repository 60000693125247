import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 'electric', label: 'Electric' },
  { value: 'water', label: 'Water' },
];

const ProviderCategorySelect = props => (
  <SelectField placeholder="Select category" {...props} options={options} />
);

export default ProviderCategorySelect;
