import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from 'utils/envConfig';

const UPDATE_PRODUCT_API_ID = 'ECOM_UPDATE_PRODUCT';

const {
  actionCreator: updateProductAC,
  dataSelector: updateProductDataSelector,
  errorSelector: updateProductErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_PRODUCT_API_ID, (id, product) => ({
  endpoint: `${EMARKET_ADMIN_API}/ecomproducts/${id}`,
  method: 'PATCH',
  notify: true,
  body: product,
}));

export {
  UPDATE_PRODUCT_API_ID,
  updateProductAC,
  updateProductDataSelector,
  updateProductErrorSelector,
  isUpdatingSelector,
};
