import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_SHIPPING_REQUEST_API_ID = 'GET_SHIPPING_REQUEST';

const {
  actionCreator: fetchShippingRequestAC,
  dataSelector: shippingRequestRawDataSelector,
  errorSelector: fetchShippingRequestErrorSelector,
  isFetchingSelector: isFetchingShippingRequestSelector,
} = makeFetchAction(GET_SHIPPING_REQUEST_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingrequests/${id}`,
  method: 'GET',
}));

const shippingRequestDataSelector = shippingRequestRawDataSelector;

export {
  GET_SHIPPING_REQUEST_API_ID,
  fetchShippingRequestAC,
  fetchShippingRequestErrorSelector,
  isFetchingShippingRequestSelector,
  shippingRequestDataSelector,
};
