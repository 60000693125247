import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { APPROVE_CREDIT_REQUEST_API_ID } from '../../apiCalls/creditSystem/approve';
import { fetchAdminCreditHistoryAC } from '../../apiCalls/creditSystem/creditHistory';

const handleApproveCreditTransactionSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(APPROVE_CREDIT_REQUEST_API_ID)
      )
    ),
    map(action => {
      const response = action.payload.json;
      if (response.credit_record) {
        const { user } = response.credit_record;
        return fetchAdminCreditHistoryAC({ id: user.id });
      }

      return;
    })
  );

export default combineEpics(handleApproveCreditTransactionSuccess);
