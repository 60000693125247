import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_LOCATION_API_ID = 'GET_LOCATION';

const {
  actionCreator: fetchLocationAC,
  dataSelector: locationRawDataSelector,
  errorSelector: fetchLocationErrorSelector,
  isFetchingSelector: isFetchingLocationSelector,
} = makeFetchAction(GET_LOCATION_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/locations/${id}`,
  method: 'GET',
}));

const locationDataSelector = locationRawDataSelector;

export {
  GET_LOCATION_API_ID,
  fetchLocationAC,
  fetchLocationErrorSelector,
  isFetchingLocationSelector,
  locationDataSelector,
};
