import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DISABLE_POST_API_ID = 'DISABLE_POST';

const {
  actionCreator: disablePostAC,
  dataSelector: disablePostDataSelector,
  errorSelector: disablePostErrorSelector,
  isFetchingSelector: isDisablePostSelector,
} = makeFetchAction(DISABLE_POST_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/posts/${id}/disable`,
  method: 'POST',
  notify: true,
}));

export {
  DISABLE_POST_API_ID,
  disablePostAC,
  disablePostDataSelector,
  disablePostErrorSelector,
  isDisablePostSelector,
};
