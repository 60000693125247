import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const DEACTIVATE_STAFF_API_ID = 'DEACTIVATE_STAFF';

const {
  actionCreator: deactivateStaffAC,
  dataSelector: deactivateStaffDataSelector,
  errorSelector: deactivateStaffErrorSelector,
  isFetchingSelector: isDeactivatingStaffSelector,
} = makeFetchAction(DEACTIVATE_STAFF_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/staffs/${id}/deactivate`,
  method: 'PATCH',
  notify: true,
}));

export {
  DEACTIVATE_STAFF_API_ID,
  deactivateStaffAC,
  deactivateStaffDataSelector,
  deactivateStaffErrorSelector,
  isDeactivatingStaffSelector,
};
