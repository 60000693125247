import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_PRODUCT_API_ID } from '../apiCalls/products/create';
import { UPDATE_PRODUCT_API_ID } from '../apiCalls/products/update';
import { DELETE_PRODUCT_API_ID } from '../apiCalls/products/delete';
import routerHistory from '../routerHistory';
import {
  fetchProductsAC,
  currentPageSelector,
} from '../apiCalls/products/list';

const handleCreateProductSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_PRODUCT_API_ID))),
    map(() => {
      routerHistory.push('/emarket/products');
    })
  );

const handleEditProductSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_PRODUCT_API_ID))),
    map(() => {
      routerHistory.push('/emarket/products');
    })
  );

const refreshListViewOnDelete = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(DELETE_PRODUCT_API_ID))),
    map(() => {
      const page = currentPageSelector(state$.value);
      return fetchProductsAC({ page, per_page: 12 });
    })
  );

export default combineEpics(
  handleCreateProductSuccess,
  handleEditProductSuccess,
  refreshListViewOnDelete
);
