import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';

import { setAdminAuthTokenAC } from '../../state/adminSession/state';
import { setAdminSessionToLocalStorage } from '../../LocalStorage/admin';
import { CREATE_ADMIN_SESSION_API_ID } from '../../apiCalls/adminSessions/create';

const handleLogicSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_ADMIN_SESSION_API_ID))),
    tap(action => {
      const session = get('payload.json')(action);
      setAdminSessionToLocalStorage({ ...session });
      window.location = '/';
    }),
    map(action => {
      const session = get('payload.json')(action);
      return setAdminAuthTokenAC(session);
    })
  );

export default combineEpics(handleLogicSuccess);
