import React from 'react';
import SelectField from './form/SelectField';

const options = [
  { value: 'bank_transfer', label: 'Bank Transfer' },
  { value: 'cash', label: 'Cash' },
  { value: 'cheque', label: 'Cheque' },
];

const PaymentMethodSelect = props => (
  <SelectField
    placeholder="Select payment method"
    {...props}
    options={options}
  />
);

export default PaymentMethodSelect;
