import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DELETE_LOCATION_API_ID = 'DELETE_LOCATION';

const {
  actionCreator: deleteLocationAC,
  dataSelector: deleteLocationDataSelector,
  errorSelector: deleteLocationErrorSelector,
  isFetchingSelector: isDeletingLocationSelector,
} = makeFetchAction(DELETE_LOCATION_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/locations/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_LOCATION_API_ID,
  deleteLocationAC,
  deleteLocationDataSelector,
  deleteLocationErrorSelector,
  isDeletingLocationSelector,
};
