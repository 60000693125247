import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  createShippingPriceAC,
  isCreatingShippingPriceSelector,
  createShippingPriceErrorSelector,
} from '../../apiCalls/shippingPriceTables/create';
import Form from './Form';
import PageTitle from '../shared/PageTitle';

const CreateShippingPrice = ({ handleSubmit, submitting }) => (
  <>
    <PageTitle content="Shipping Price" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/shipping_prices">Shipping Price</Link>
        </li>
        <li>Add new shipping price</li>
      </ul>
    </div>

    <div className="row align-items-center">
      <div className="col-sm-6">
        <h4 className="page-small-title">Add New Shipping Price</h4>
      </div>
    </div>

    <Form
      layout="horizontal"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={handleSubmit}
      submitting={submitting}
    />
  </>
);

const enhance = connect(
  state => ({
    createError: createShippingPriceErrorSelector(state),
    submitting: isCreatingShippingPriceSelector(state),
  }),
  {
    handleSubmit: createShippingPriceAC,
  }
);

export default enhance(CreateShippingPrice);
