import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get } from 'lodash/fp';
import {
  updateLocationAC,
  isUpdatingSelector,
} from '../../apiCalls/locations/update';
import LocationForm from './Form';
import {
  fetchLocationAC,
  locationDataSelector,
  isFetchingLocationSelector,
} from '../../apiCalls/locations/get';
import { deleteLocationAC } from '../../apiCalls/locations/delete';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const AdminEditLocation = ({
  match,
  handleSubmit,
  formError,
  location,
  deleteLocation,
  submitting,
}) => (
  <>
    <PageTitle content="Locations" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/locations">Locations</Link>
        </li>
        <li>Edit location: {match.params.id}</li>
      </ul>
    </div>

    <div className="">
      <div className="">
        <div className="row align-items-center">
          <div className="error">{formError}</div>
        </div>
        {location && (
          <LocationForm
            onFinish={handleSubmit}
            deleteLocation={deleteLocation}
            initialValues={location}
            submitting={submitting}
          />
        )}
      </div>
    </div>
  </>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    location: locationDataSelector(state),
    isFetching: isFetchingLocationSelector(state),
  }),
  {
    updateLocation: updateLocationAC,
    fetchLocation: fetchLocationAC,
    deleteLocation: deleteLocationAC,
  }
);

const prepareProps = mapProps(
  ({ updateLocation, updateLocationError, ...others }) => ({
    formError: updateLocationError,
    handleSubmit: location => {
      const location_id = others.match.params.id;
      return updateLocation(location_id, location);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchLocation, fetchLocationImages, match } = this.props;
      const { id } = match.params;
      fetchLocation(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditLocation);
