import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const CREATE_SHIPPING_PRICE_API_ID = 'CREATE_SHIPPING_PRICE';

const {
  actionCreator: createShippingPriceAC,
  dataSelector: createdShippingPriceRawDataSelector,
  errorSelector: createShippingPriceErrorSelector,
  isFetchingSelector: isCreatingShippingPriceSelector,
} = makeFetchAction(CREATE_SHIPPING_PRICE_API_ID, shippingPrice => ({
  endpoint: `${EMARKET_ADMIN_API}/shippingprices`,
  method: 'POST',
  notify: true,
  body: shippingPrice,
}));

const createdShippingPriceDataSelector = createdShippingPriceRawDataSelector;

export {
  CREATE_SHIPPING_PRICE_API_ID,
  createShippingPriceAC,
  createdShippingPriceDataSelector,
  createShippingPriceErrorSelector,
  isCreatingShippingPriceSelector,
};
