import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_ADMIN_USER_API_ID = 'GET_ADMIN_USER';

const {
  actionCreator: fetchAdminUserAC,
  dataSelector: adminUserDataSelector,
  errorSelector: fetchAdminUserErrorSelector,
  isFetchingSelector: isFetchingAdminUserSelector,
  updater: updateAdminUserDataAC,
} = makeFetchAction(GET_ADMIN_USER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}`,
  method: 'GET',
}));

const updateAdminUserAC = (user, state) => {
  const adminUser = adminUserDataSelector(state);
  const data = {
    ...adminUser,
    user,
  };
  return updateAdminUserDataAC(data);
};

export {
  GET_ADMIN_USER_API_ID,
  fetchAdminUserAC,
  fetchAdminUserErrorSelector,
  isFetchingAdminUserSelector,
  adminUserDataSelector,
  updateAdminUserAC,
};
