import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tag, Button } from 'antd';
import queryString from 'query-string';
import {
  fetchOrdersAC,
  ordersDataSelector,
  fetchOrdersErrorSelector,
  isFetchingOrdersSelector,
  totalOrdersSelector,
  currentPageSelector,
  pageSizeSelector,
} from 'apiCalls/ecomOrders/list';
import { completeOrderAC } from 'apiCalls/ecomOrders/complete';
import { cancelOrderAC } from 'apiCalls/ecomOrders/cancel';
import {
  orderStatusConfig,
  orderStatusColors,
  shippingMethodConfig,
} from 'utils/constants';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import { formatDate } from 'utils/formatDate';

const columnsGenerator = () => [
  {
    title: 'Invoice ID',
    dataIndex: 'id',
    align: 'right',
    render: value => <Link to={`/ecommerce/orders/${value}`}>INV {value}</Link>,
  },
  {
    title: 'Created at',
    dataIndex: 'created',
    align: 'right',
    render: value => formatDate(value),
  },
  {
    title: 'Buyer',
    dataIndex: ['buyer', 'name'],
  },
  {
    title: 'Shipping Method',
    dataIndex: 'shipping_method',
    align: 'right',
    render: shipping_method => shippingMethodConfig[shipping_method],
  },
  {
    title: 'Total Amount',
    dataIndex: 'total_amount',
    render: total_amount => `RM${total_amount}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'right',
    render: status => (
      <Tag color={orderStatusColors[status]}>{orderStatusConfig[status]}</Tag>
    ),
  },
  {
    title: 'Action',
    dataIndex: 'id',
    align: 'center',
    render: id => (
      <Button>
        <Link to={`/ecommerce/orders/${id}`}>Detail</Link>
      </Button>
    ),
  },
];

const Orders = ({ fetchOrders, orders, loading, error, ...others }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    fetchOrders(params);
    // eslint-disable-next-line
  }, [location, fetchOrders]);

  return (
    <>
      <PageTitle content="Ecom Orders" />
      {error && <ErrorMessage />}
      <DataTable
        dataSource={orders}
        dataFetcher={fetchOrders}
        loading={loading}
        filters={params}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    orders: ordersDataSelector(state),
    loading: isFetchingOrdersSelector(state),
    error: fetchOrdersErrorSelector(state),
    totalItems: totalOrdersSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    columns: columnsGenerator(),
  }),
  {
    fetchOrders: fetchOrdersAC,
    completeOrder: completeOrderAC,
    cancelOrder: cancelOrderAC,
  }
);

export default connectToRedux(Orders);
