import { Button, Descriptions, Form } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  approveTopupAC,
  approveTopupErrorSelector,
  isApprovingTopupSelector,
} from '../../apiCalls/adminTopups/approve';
import {
  fetchAdminTopupAC,
  adminTopupDataSelector,
  fetchAdminTopupErrorSelector,
  isFetchingAdminTopupSelector,
} from '../../apiCalls/adminTopups/get';
import {
  rejectTopupAC,
  rejectTopupErrorSelector,
  isRejectingTopupSelector,
} from '../../apiCalls/adminTopups/reject';
import ApprovalButton from '../shared/ApprovalButton';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import Status from '../shared/Status';
import TextField from '../shared/form/TextField';
import formatDate from '../../utils/formatDate';

const Content = ({
  match,
  topupRequest,
  isApproving,
  isRejecting,
  approveTopup,
  rejectTopup,
}) => {
  const { search } = useLocation();
  const requestsQuery = new URLSearchParams(search);
  const [amount, setAmount] = React.useState(topupRequest.amount);
  const [adminNote, setAdminNote] = React.useState('');

  return (
    <div id="content">
      <div className="container">
        <div className="dashboard-page">
          <h1 className="page-title">Topup Records</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to={`/payment/topups?${requestsQuery}`}>
                  Topup Records
                </Link>
              </li>
              <li>#{match.params.id}</li>
            </ul>
          </div>
          <Descriptions
            title="Topup Record details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{topupRequest.id}</Descriptions.Item>
            <Descriptions.Item label="Created At">
              {formatDate(topupRequest.created_at)}
            </Descriptions.Item>
            <Descriptions.Item label="User">
              {topupRequest.user.name}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {topupRequest.user.contact_number}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Name">
              {topupRequest.user.bank_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Name">
              {topupRequest.user.bank_account_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Number">
              {topupRequest.user.bank_account_number}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              RM{topupRequest.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Invoice">
              {topupRequest.invoice && topupRequest.invoice.origin_url ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={topupRequest.invoice.origin_url}
                >
                  <img
                    height={500}
                    src={topupRequest.invoice.origin_url}
                    alt="invoice"
                  />
                </a>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Note">
              {topupRequest.description}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status status={topupRequest.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Admin Note">
              {topupRequest.admin_note}
            </Descriptions.Item>
          </Descriptions>
          {topupRequest.status === 'pending' && (
            <>
              <hr />
              <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                <TextField
                  {...FORM_ITEM_LAYOUT}
                  name="amount"
                  type="number"
                  label="Amount"
                  required
                  input={{
                    defaultValue: amount,
                    value: amount,
                    onChange: e => setAmount(e.target.value),
                  }}
                />
                <TextField
                  {...FORM_ITEM_LAYOUT}
                  name="admin_note"
                  multiline
                  type="text"
                  label="Admin Notes"
                  input={{
                    value: adminNote,
                    onChange: e => setAdminNote(e.target.value),
                  }}
                />
              </Form>
              <div className="text-right">
                <Button.Group>
                  <ApprovalButton
                    key="reject"
                    action="reject"
                    objectId={match.params.id}
                    data={{ admin_note: adminNote }}
                    isRejecting={isRejecting}
                    reject={rejectTopup}
                  />
                  <ApprovalButton
                    key="approve"
                    action="approve"
                    objectId={match.params.id}
                    data={{ amount, admin_note: adminNote }}
                    isApproving={isApproving}
                    approve={approveTopup}
                  />
                </Button.Group>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AdminTopupDetail = ({
  topupRequestData,
  fetchAdminTopupDetail,
  isFetching,
  errors,
  match,
  isApproving,
  isRejecting,
  approveTopup,
  rejectTopup,
}) => {
  const topupRequest = get('bank_record')(topupRequestData);

  React.useEffect(() => {
    fetchAdminTopupDetail(match.params.id);
  }, [fetchAdminTopupDetail, match.params.id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  if (!topupRequest) {
    return null;
  }

  return (
    <Content
      topupRequest={topupRequest}
      isApproving={isApproving}
      isRejecting={isRejecting}
      approveTopup={approveTopup}
      rejectTopup={rejectTopup}
      match={match}
    />
  );
};

const enhance = connect(
  state => ({
    topupRequestData: adminTopupDataSelector(state),
    isFetching: isFetchingAdminTopupSelector(state),
    isRejecting: isRejectingTopupSelector(state),
    isApproving: isApprovingTopupSelector(state),
    error: fetchAdminTopupErrorSelector(state),
    approvedError: approveTopupErrorSelector(state),
    rejectedError: rejectTopupErrorSelector(state),
  }),
  {
    fetchAdminTopupDetail: fetchAdminTopupAC,
    approveTopup: approveTopupAC,
    rejectTopup: rejectTopupAC,
  }
);

export default enhance(AdminTopupDetail);
