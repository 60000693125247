import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { updateAdminPaymentAC } from '../../apiCalls/adminPayments/get';
import { REJECT_PAYMENT_API_ID } from '../../apiCalls/adminPayments/reject';

const handleRejectPaymentSuccess = (actions$, store$) =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(REJECT_PAYMENT_API_ID)
      )
    ),
    tap(action => {
      console.log(action);
    }),
    map(action => {
      const payment = get('payload.json.payment')(action);
      return updateAdminPaymentAC(payment, store$.value);
    })
  );

export default combineEpics(handleRejectPaymentSuccess);
