import { Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { columnsGenerator } from '../Transactions';
import {
  fetchAdminPaymentAC,
  adminPaymentDataSelector,
  fetchAdminPaymentErrorSelector,
  isFetchingAdminPaymentSelector,
} from '../../apiCalls/adminPayments/get';
import {
  fetchTransactionsAC,
  transactionsDataSelector,
} from '../../apiCalls/transactions/list';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';
import Status from '../shared/Status';
import formatDate from '../../utils/formatDate';

const transactionColumns = columnsGenerator();

const AdminPaymentDetail = ({
  fetchTransactions,
  transactions,
  fetchAdminPaymentDetail,
  paymentRequestData,
  match,
  isFetching,
  errors,
}) => {
  React.useEffect(() => {
    fetchAdminPaymentDetail(match.params.id);
    fetchTransactions({ filter: { request_id: match.params.id } });
  }, []); // eslint-disable-line

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  const paymentRequest = get('payment')(paymentRequestData);

  return (
    <div className="">
      <PageTitle content="Orders" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/payment/payments">Payment Requests</Link>
          </li>
          <li>View payment request detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {paymentRequest && (
          <Descriptions
            title="Payment request details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">
              {paymentRequest.id}
            </Descriptions.Item>
            <Descriptions.Item label="Payer">
              {paymentRequest.requester.name}
            </Descriptions.Item>
            <Descriptions.Item label="Payer email">
              {paymentRequest.requester.email}
            </Descriptions.Item>
            <Descriptions.Item label="Payer phone">
              {paymentRequest.requester.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label="Payer area">
              {paymentRequest.requester.area}
            </Descriptions.Item>
            <Descriptions.Item label="Payee">
              {paymentRequest.receiver.name}
            </Descriptions.Item>
            <Descriptions.Item label="Payee email">
              {paymentRequest.receiver.email}
            </Descriptions.Item>
            <Descriptions.Item label="Payee phone">
              {paymentRequest.receiver.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label="Payee area">
              {paymentRequest.receiver.area}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              RM{paymentRequest.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status status={paymentRequest.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              {paymentRequest.note}
            </Descriptions.Item>
            <Descriptions.Item label="Invoices">
              {paymentRequest.invoice && paymentRequest.invoice.origin_url ? (
                <a
                  href={paymentRequest.invoice.origin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={paymentRequest.invoice.thumb_url} alt="" />
                </a>
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {formatDate(paymentRequest.created_at)}
            </Descriptions.Item>
          </Descriptions>
        )}
        <hr />
        <div className="">
          <div className="ant-descriptions-title">Transactions</div>
          <DataTable dataSource={transactions} columns={transactionColumns} />
        </div>
      </div>
    </div>
  );
};

const connectToRedux = connect(
  state => ({
    paymentRequestData: adminPaymentDataSelector(state),
    isFetching: isFetchingAdminPaymentSelector(state),
    errors: fetchAdminPaymentErrorSelector(state),
    transactions: transactionsDataSelector(state),
  }),
  {
    fetchAdminPaymentDetail: fetchAdminPaymentAC,
    fetchTransactions: fetchTransactionsAC,
  }
);

export default connectToRedux(AdminPaymentDetail);
