import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_PHONE_BILL_API_ID = 'GET_PHONE_BILL';

const {
  actionCreator: fetchAdminPhoneBillAC,
  dataSelector: adminPhoneBillRawDataSelector,
  errorSelector: fetchAdminPhoneBillErrorSelector,
  isFetchingSelector: isFetchingAdminPhoneBillSelector,
  updater: updateAdminPhoneBillDataAC,
} = makeFetchAction(GET_PHONE_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/phone_bills/${id}`,
  method: 'GET',
}));

const adminPhoneBillDataSelector = createSelector(
  adminPhoneBillRawDataSelector,
  get('phone_bill')
);

const updateAdminPhoneBillAC = (phone_bill, state) => {
  const existingData = adminPhoneBillRawDataSelector(state);
  const data = {
    ...existingData,
    phone_bill,
  };
  return updateAdminPhoneBillDataAC(data);
};

export {
  GET_PHONE_BILL_API_ID,
  fetchAdminPhoneBillAC,
  fetchAdminPhoneBillErrorSelector,
  isFetchingAdminPhoneBillSelector,
  adminPhoneBillDataSelector,
  updateAdminPhoneBillAC,
};
