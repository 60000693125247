import moment from 'moment';

export default date => moment.unix(date).format('MMMM Do, YYYY LT');

export const timeAgo = timeData => {
  // timeData type must be a string or number
  return timeData ? moment(timeData).fromNow() : undefined;
};

export const formatDateTime = timeData => {
  return timeData ? moment(timeData).format('MMM DD, YYYY h:mma') : undefined;
};

export const formatDate = timeData => {
  if (typeof timeData === 'string')
    return timeData ? moment(timeData).format('MMM DD, YYYY') : undefined;
  if (!Number.isNaN(timeData))
    return moment.unix(timeData).format('MMMM Do, YYYY LT');
  return timeData;
};
