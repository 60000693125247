import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_PHONE_BILL_API_ID = 'REJECT_PHONE_BILL';

const {
  actionCreator: rejectPhoneBillAC,
  dataSelector: rejectPhoneBillDataSelector,
  errorSelector: rejectPhoneBillErrorSelector,
  isFetchingSelector: isRejectingPhoneBillSelector,
} = makeFetchAction(REJECT_PHONE_BILL_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/phone_bills/${id}/reject`,
  method: 'POST',
  notify: true,
}));

export {
  REJECT_PHONE_BILL_API_ID,
  rejectPhoneBillAC,
  rejectPhoneBillDataSelector,
  rejectPhoneBillErrorSelector,
  isRejectingPhoneBillSelector,
};
