import { Link } from 'react-router-dom';
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';
import { formatDate } from '../../utils/formatDate';
import Status from '../shared/Status';
import ErrorMessage from '../shared/ErrorMessage';
import {
  fetchUtilityBillsAC,
  utilityBillsDataSelector,
  fetchUtilityBillsErrorSelector,
  isFetchingUtilityBillsSelector,
  currentPageSelector,
  totalItemSelector,
  pageSizeSelector,
} from '../../apiCalls/adminUtilityBill/list';
import {
  isApprovingUtilityBillSelector,
  approveUtilityBillAC,
} from '../../apiCalls/adminUtilityBill/approve';
import {
  isRejectingUtilityBillSelector,
  rejectUtilityBillAC,
} from '../../apiCalls/adminUtilityBill/reject';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    render: value => formatDate(value),
  },
  {
    title: 'Contract number',
    dataIndex: 'contract_id',
  },
  {
    title: 'Requester',
    dataIndex: ['requester', 'name'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: value => `RM${value}`,
    align: 'right',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => <Status status={value} />,
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (values, record) => (
      <>
        <Link
          to={`/billings/utility/${record.id}`}
          className="button button--small button--red-line"
        >
          Detail
        </Link>
        <Link
          to={`/billings/utility/${record.id}/edit`}
          className="button button--small button--red-line"
        >
          Edit
        </Link>
      </>
    ),
  },
];

const UtilityBills = ({ utilityBills, fetchUtilityBills, ...others }) => (
  <div id="content">
    <PageTitle content="Utility Bills" />
    <DataTable
      dataFetcher={fetchUtilityBills}
      dataSource={utilityBills}
      columns={columns}
      {...others}
    />
  </div>
);

const enhance = compose(
  connect(
    state => ({
      loading: isFetchingUtilityBillsSelector(state),
      errors: fetchUtilityBillsErrorSelector(state),
      utilityBills: utilityBillsDataSelector(state),
      totalItems: totalItemSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
      isRejecting: isRejectingUtilityBillSelector(state),
      isApproving: isApprovingUtilityBillSelector(state),
    }),
    {
      fetchUtilityBills: fetchUtilityBillsAC,
      approveUtilityBillRequest: approveUtilityBillAC,
      rejectUtilityBillRequest: rejectUtilityBillAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchUtilityBills();
    },
  }),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(UtilityBills);
