import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_PAYMENTS_API_ID = 'FETCH_ADMIN_PAYMENTS';

const {
  actionCreator: fetchAdminPaymentsAC,
  dataSelector: paymentsRawSelector,
  errorSelector: fetchAdminPaymentsErrorSelector,
  isFetchingSelector: isFetchingAdminPaymentsSelector,
  updater: updateAdminPaymentsAC,
} = makeFetchAction(FETCH_ADMIN_PAYMENTS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/payments`,
    params,
  }),
  method: 'GET',
}));

const totalPaymentItemSelector = createSelector(
  paymentsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  paymentsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  paymentsRawSelector,
  get('paging.per_page')
);

const adminPaymentsDataSelector = createSelector(
  paymentsRawSelector,
  get('payments')
);

export {
  FETCH_ADMIN_PAYMENTS_API_ID,
  fetchAdminPaymentsAC,
  paymentsRawSelector,
  totalPaymentItemSelector,
  fetchAdminPaymentsErrorSelector,
  isFetchingAdminPaymentsSelector,
  updateAdminPaymentsAC,
  currentPageSelector,
  pageSizeSelector,
  adminPaymentsDataSelector,
};
