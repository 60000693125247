import { Descriptions, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import React from 'react';

import {
  fetchTransactionAC,
  transactionDataSelector,
  isFetchingTransactionSelector,
} from '../../apiCalls/transactions/get';
import { formatDate } from '../../utils/formatDate';
import {
  transactionTypeColors,
  transactionTypeConfig,
} from '../../utils/constants';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PageTitle from '../shared/PageTitle';

const TransactionDetail = ({
  fetchTransaction,
  match,
  transaction,
  isFetching,
  errors,
}) => {
  React.useEffect(() => {
    fetchTransaction(match.params.id);
  }, [fetchTransaction, match.params.id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  return (
    <div className="">
      <PageTitle content="Transaction Management" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/payment/transactions">Transactions</Link>
          </li>
          <li>View detail: {match.params.id}</li>
        </ul>
      </div>
      {transaction && (
        <Descriptions
          title="Transaction details"
          bordered
          column={1}
          layout="horizontal"
        >
          <Descriptions.Item label="ID">{transaction.id}</Descriptions.Item>
          <Descriptions.Item label="Invoice ID">
            EF{transaction.invoice_number}
          </Descriptions.Item>
          <Descriptions.Item label="Created at">
            {formatDate(transaction.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="User">
            {transaction.user && transaction.user.name}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {transaction.user && transaction.user.contact_number}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            <Tag color={transactionTypeColors[transaction.type]}>
              {transactionTypeConfig(
                transaction.type,
                transaction.service_code,
                transaction.transaction_type
              )}
            </Tag>
            {transaction.parent_id ? <Tag color="red">Refund</Tag> : null}
          </Descriptions.Item>
          <Descriptions.Item label="Amount">
            {transaction.transaction_type === 'withdrawal' ? '-' : '+'}RM
            {transaction.amount}
          </Descriptions.Item>

          {transaction.request ? (
            <>
              <Descriptions.Item label="Note">
                {isNil(transaction.request.note)
                  ? '-'
                  : transaction.request.note}
              </Descriptions.Item>
              <Descriptions.Item label="Transfer Receipt">
                {transaction.request.invoice &&
                transaction.request.invoice.origin_url ? (
                  <a
                    href={transaction.request.invoice.origin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={transaction.request.invoice.thumb_url} alt="" />
                  </a>
                ) : (
                  '-'
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Request">
                <Link to={`/payment/requests/${transaction.request.id}`}>
                  {transaction.request.type} - {transaction.request.id}
                </Link>
              </Descriptions.Item>
            </>
          ) : null}
          {transaction.parent_id && (
            <Descriptions.Item label="Linked transaction">
              <Link to={`/payment/transactions/${transaction.parent_id}`}>
                [Refund] View parent transaction
              </Link>
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    transaction: transactionDataSelector(state),
    isFetching: isFetchingTransactionSelector(state),
  }),
  {
    fetchTransaction: fetchTransactionAC,
  }
);

export default connectWithRedux(TransactionDetail);
