import { eq, flow, get } from 'lodash/fp';
import { filter, tap, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';

import { setAuthTokenAC } from '../../state/session/state';
import { setSessionToLocalStorage } from '../../LocalStorage';
import { CREATE_SESSION_API_ID } from '../../apiCalls/sessions/create';

const handleLogicSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(
      flow(
        get('payload.name'),
        eq(CREATE_SESSION_API_ID)
      )
    ),
    tap(action => {
      const session = get('payload.json')(action);
      setSessionToLocalStorage({ ...session });
    }),
    map(action => {
      const authToken = get('payload.json.auth_token')(action);
      return setAuthTokenAC(authToken);
    })
  );

export default combineEpics(handleLogicSuccess);
