import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_STAFF_PASSWORD_API_ID = 'UPDATE_STAFF_PASSWORD';

const {
  actionCreator: updateStaffPasswordAC,
  dataSelector: updateStaffPasswordDataSelector,
  errorSelector: updateStaffPasswordErrorSelector,
  isFetchingSelector: isUpdatingStaffPasswordSelector,
} = makeFetchAction(UPDATE_STAFF_PASSWORD_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/staffs/${id}/password`,
  method: 'PUT',
  notify: true,
  body: data,
}));

export {
  UPDATE_STAFF_PASSWORD_API_ID,
  updateStaffPasswordAC,
  updateStaffPasswordDataSelector,
  updateStaffPasswordErrorSelector,
  isUpdatingStaffPasswordSelector,
};
