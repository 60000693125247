export const TAG_COLOR = {
  100: '#7cb305',
  101: '#1890ff',
};

export const postTypesConfig = {
  100: 'Buy',
  101: 'Sell',
};

export const POST_TYPES = [
  { value: 100, label: 'Buy' },
  { value: 101, label: 'Sell' },
];

export const creatorByType = {
  100: 'Buyer',
  101: 'Seller',
};

export const receiverByType = {
  100: 'Seller',
  101: 'Buyer',
};

export const orderStatusColors = {
  1000: '#fadb14',
  1001: '#52c41a',
  1002: '#f5222d',
  1003: 'gray',
};

export const postStatusConfig = {
  1000: 'PENDING',
  1001: 'COMPLETED',
  1002: 'CANCELLED',
};

export const ecomProductConfig = {
  1001: 'ACTIVE',
  1002: 'INACTIVE',
};

export const orderStatusConfig = {
  1000: 'PENDING',
  1001: 'COMPLETED',
  1002: 'CANCELLED',
};

export const postStatusColors = {
  1000: '#fadb14',
  1001: '#52c41a',
  1002: '#f5222d',
  1003: 'gray',
};

export const ecomProductColors = {
  1000: '#fadb14',
  1001: '#52c41a',
  1002: '#f5222d',
  1003: 'gray',
};

export const quoteStatusColors = {
  1000: '#fadb14',
  1001: '#52c41a',
  1002: '#f5222d',
  1003: 'gray',
};

export const quoteStatusConfig = {
  1000: 'PENDING',
  1001: 'CONFIRMED',
  1002: 'REJECTED',
  1003: 'CANCELLED',
};

export const methodColors = {
  1000: '#1890ff',
  1001: '#fa8c16',
};

export const methodConfig = {
  1000: 'Pick Up',
  1001: 'Door to Door',
};

export const userStatusColors = {
  unverify: '#fadb14',
  verified: '#52c41a',
};

export const unitColors = {
  100: 'blue',
  101: 'cyan',
  102: 'gray',
};

export const sizeUnitConfig = {
  100: 'Kg/Unit',
  101: 'Units/Kg',
  102: 'Other',
};

export const transactionTypeColors = {
  WithdrawalTransaction: '#9b32a8',
  TopupTransaction: '#7cb305',
  PaymentTransaction: '#1890ff',
  CreditTransaction: '#fa8c16',
};

export const transactionTypeConfig = (type, serviceCode, transactionType) => {
  switch (type) {
    case 'WithdrawalTransaction':
      return 'Withdrawal';
    case 'TopupTransaction':
      return 'Topup';
    case 'PaymentTransaction':
      switch (serviceCode) {
        case 'eflogistic':
          return 'Logistic';
        case 'efmarket':
          if (transactionType === 'withdrawal') {
            return 'Purchases';
          }

          return 'Sales';
        default:
          return 'Payment';
      }
    case 'CreditTransaction':
      return 'Credit';
    default:
      return '';
  }
};

export const typeConfig = {
  withdrawal: 'Withdrawal',
  topup: 'Topup',
};

export const METHOD_SELF_PICKUP = 1000;
export const METHOD_DOOR_TO_DOOR = 1001;
export const shippingMethodConfig = {
  1000: 'Self Pickup',
  1001: 'Door to door',
};

export default {};
