import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const CREATE_STAFF_API_ID = 'CREATE_STAFF';

const {
  actionCreator: createStaffAC,
  dataSelector: createStaffDataSelector,
  errorSelector: createStaffErrorSelector,
  isFetchingSelector: isCreatingStaffSelector,
} = makeFetchAction(CREATE_STAFF_API_ID, staff => ({
  endpoint: `${API_ADMIN_ENDPOINT}/staffs`,
  method: 'POST',
  notify: true,
  body: staff,
}));

const errorSelector = createSelector(
  createStaffErrorSelector,
  error => {
    if (isEmpty(error)) return null;
    if (!isEmpty(error.error)) return error.error;
    if (!isEmpty(error.errors)) return error.errors.join(',');
    return 'Oops, something went wrong, please take a screenshot of the current screen & contact your administrator';
  }
);

export {
  CREATE_STAFF_API_ID,
  createStaffAC,
  createStaffDataSelector,
  createStaffErrorSelector,
  errorSelector,
  isCreatingStaffSelector,
};
