import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const FETCH_TRANSACTION_API_ID = 'FETCH_TRANSACTION';

const {
  actionCreator: fetchTransactionAC,
  dataSelector: transactionRawSelector,
  isFetchingSelector: isFetchingTransactionSelector,
  errorSelector: fetchingTransactionErrorSelector,
} = makeFetchAction(FETCH_TRANSACTION_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/transactions/${id}`,
  method: 'GET',
}));

const transactionDataSelector = createSelector(
  transactionRawSelector,
  get('transaction')
);

export {
  FETCH_TRANSACTION_API_ID,
  fetchTransactionAC,
  transactionDataSelector,
  isFetchingTransactionSelector,
  fetchingTransactionErrorSelector,
};
