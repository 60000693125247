import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const APPROVE_TOPUP_API_ID = 'APPROVE_TOPUP';

const {
  actionCreator: approveTopupAC,
  dataSelector: approveTopupDataSelector,
  errorSelector: approveTopupErrorSelector,
  isFetchingSelector: isApprovingTopupSelector,
} = makeFetchAction(APPROVE_TOPUP_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/topups/${id}/approve`,
  method: 'POST',
  body: data,
  notify: true,
}));

export {
  APPROVE_TOPUP_API_ID,
  approveTopupAC,
  approveTopupDataSelector,
  approveTopupErrorSelector,
  isApprovingTopupSelector,
};
