import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const GET_PRICE_API_ID = 'GET_PRICE';

const {
  actionCreator: fetchPriceAC,
  dataSelector: priceRawDataSelector,
  errorSelector: fetchPriceErrorSelector,
  isFetchingSelector: isFetchingPriceSelector,
} = makeFetchAction(GET_PRICE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/prices/${id}`,
  method: 'GET',
}));

const priceDataSelector = priceRawDataSelector;

export {
  GET_PRICE_API_ID,
  fetchPriceAC,
  fetchPriceErrorSelector,
  isFetchingPriceSelector,
  priceDataSelector,
};
