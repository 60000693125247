import { isEmpty } from 'lodash/fp';
import { createSelector } from 'reselect';
import { makeFetchAction } from 'redux-api-call';

import { API_ENDPOINT } from '../../utils/envConfig';

const CREATE_SESSION_API_ID = 'LOGIN';

const {
  actionCreator: createSessionAC,
  dataSelector: sessionDataSelector,
  errorSelector: createSessionErrorSelector,
  isFetchingSelector: isLoggingInSelector,
} = makeFetchAction(CREATE_SESSION_API_ID, ({ email, password }) => ({
  endpoint: `${API_ENDPOINT}/session`,
  method: 'POST',
  body: { email, password },
}));

const loginErrorSelector = createSelector(
  createSessionErrorSelector,
  error => (isEmpty(error) ? null : 'Incorrect email or password.')
);

export {
  CREATE_SESSION_API_ID,
  createSessionAC,
  sessionDataSelector,
  loginErrorSelector,
  isLoggingInSelector,
};
