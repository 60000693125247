import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_PACKAGING_BOX_API_ID } from '../apiCalls/packagingBoxes/create';
import { UPDATE_PACKAGING_BOX_API_ID } from '../apiCalls/packagingBoxes/update';
import { DELETE_PACKAGING_BOX_API_ID } from '../apiCalls/packagingBoxes/delete';
import routerHistory from '../routerHistory';
import {
  fetchPackagingBoxesAC,
  currentPageSelector,
} from '../apiCalls/packagingBoxes/list';

const handleCreatePackagingBoxSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_PACKAGING_BOX_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/packaging_boxes`);
    })
  );

const refreshListViewOnDelete = (action$, state$) =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(DELETE_PACKAGING_BOX_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/packaging_boxes`);
      const page = currentPageSelector(state$.value);
      return fetchPackagingBoxesAC({ page, per_page: 12 });
    })
  );

const handleEditPackagingBoxSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_PACKAGING_BOX_API_ID))),
    map(() => {
      routerHistory.push(`/emarket/packaging_boxes`);
    })
  );

export default combineEpics(
  handleCreatePackagingBoxSuccess,
  refreshListViewOnDelete,
  handleEditPackagingBoxSuccess
);
