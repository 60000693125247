import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_PRICES_API_ID = 'FETCH_PRICES';

const {
  actionCreator: fetchPricesAC,
  dataSelector: pricesRawSelector,
  errorSelector: fetchPricesErrorSelector,
  isFetchingSelector: isFetchingPricesSelector,
  updater: updatePricesAC,
} = makeFetchAction(FETCH_PRICES_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/prices`,
    params,
  }),
  method: 'GET',
}));

const totalPricesSelector = createSelector(
  pricesRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  pricesRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  pricesRawSelector,
  get('paging.per_page')
);

const pricesDataSelector = createSelector(pricesRawSelector, get('results'));

export {
  FETCH_PRICES_API_ID,
  fetchPricesAC,
  pricesDataSelector,
  fetchPricesErrorSelector,
  isFetchingPricesSelector,
  updatePricesAC,
  currentPageSelector,
  pageSizeSelector,
  totalPricesSelector,
};
