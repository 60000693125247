import {
  DashboardOutlined,
  TransactionOutlined,
  ShoppingCartOutlined,
  CarOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';

import { GROWTH_REPORT_URL } from '../../../utils/envConfig';

export default [
  {
    key: 'dashboard',
    title: 'Dashboard',
    icon: DashboardOutlined,
    items: [
      { key: 'overview', label: 'Overview', location: '/overview/stats' },
      { key: 'staffs', label: 'Staffs', location: '/iam/staffs' },
      {
        key: 'users_verified',
        label: 'Users (Verified)',
        location: '/iam/users?status=verified',
      },
      {
        key: 'users_unverified',
        label: 'Users (Unverified)',
        location: '/iam/users?status=unverify',
      },
      {
        key: 'users_deactivated',
        label: 'Users (Deactivated)',
        location: '/iam/users?status=inactive',
      },
      { key: 'reports', label: 'Growth Report', location: GROWTH_REPORT_URL },
    ],
  },
  // Payment
  {
    key: 'efpay',
    title: 'EFPay',
    icon: TransactionOutlined,
    items: [
      {
        key: 'efpay_account',
        label: 'EFPay Account',
        location: '/payment/efpay_account',
      },
      {
        key: 'efbank_account',
        label: 'EFBank',
        location: '/payment/efbank_account',
      },
      {
        key: 'credit_system',
        label: 'Credit System',
        location: '/payment/credits',
      },
      {
        key: 'credit_records',
        label: 'Credit Records',
        location: '/payment/credits/records',
      },
      {
        key: 'credit_transactions',
        label: 'Credit Transactions',
        location: '/payment/credits/transactions',
      },
      {
        key: 'topups_pending',
        label: 'Topup Record (Pending)',
        location: '/payment/topups?status[]=pending',
      },
      {
        key: 'topups_approved_rejected',
        label: 'Topup (Approved and Rejected)',
        location: '/payment/topups?status[]=approved&status[]=rejected',
      },
      {
        key: 'withdrawals_pending',
        label: 'Withdrawal Record (Pending)',
        location: '/payment/withdrawals?status[]=pending',
      },
      {
        key: 'withdrawals_approved_rejected',
        label: 'Withdrawal (Approved and Rejected)',
        location: '/payment/withdrawals?status[]=approved&status[]=rejected',
      },
      {
        key: 'payments',
        label: 'Payment Requests',
        location: '/payment/payments',
      },
      {
        key: 'transactions',
        label: 'Transactions',
        location: '/payment/transactions',
      },
      // {
      // key: 'revenue',
      // label: 'Revenue Requests',
      // location: '/payment/revenue',
      // },
    ],
  },
  // Ecom
  {
    key: 'ecommerce',
    title: 'Ecommerce',
    icon: ShoppingCartOutlined,
    items: [
      {
        key: 'ecomproducts',
        label: 'Products',
        location: '/ecommerce/products',
      },
      {
        key: '/ecommerce/orders?status=1000',
        label: 'Ongoing',
        location: '/ecommerce/orders?status=1000',
      },
      {
        key: '/ecommerce/orders?status=1001',
        label: 'Completed',
        location: '/ecommerce/orders?status=1001',
      },
    ],
  },
  // Market
  {
    key: 'emarket',
    title: 'EFPlatform',
    icon: ShoppingCartOutlined,
    items: [
      {
        key: 'products',
        label: 'Products',
        location: '/emarket/products',
      },
      {
        key: 'features',
        label: 'Features',
        location: '/emarket/features',
      },
      // {
      //   key: 'size',
      //   label: 'Sizes',
      //   location: '/emarket/sizes',
      // },
      {
        key: 'posts_buy_pending',
        label: 'Buy Posts (Pending)',
        location: '/emarket/posts?post_type=100&status=1000',
      },
      {
        key: 'posts_sell_pending',
        label: 'Sell Posts (Pending)',
        location: '/emarket/posts?post_type=101&status=1000',
      },
      {
        key: 'posts_cancelled',
        label: 'Posts (Cancelled)',
        location: '/emarket/posts?status=1002',
      },
      {
        key: 'quotations_buy_pending',
        label: 'Buy Quotations (pending)',
        location: '/emarket/quotations?quote_type=100&status=1000',
      },
      {
        key: 'quotations_sell_pending',
        label: 'Sell Quotations (pending)',
        location: '/emarket/quotations?quote_type=101&status=1000',
      },
      {
        key: 'quotations_cancelled',
        label: 'Quotations (Rejected and Cancelled)',
        location: '/emarket/quotations?status=1002',
      },
      {
        key: 'orders_ongoing',
        label: 'Ongoing',
        location: '/emarket/orders?status=1000',
      },
      {
        key: 'orders_completed',
        label: 'Completed',
        location: '/emarket/orders?status=1001',
      },
      {
        key: 'efmarket_transactions',
        label: 'EFMarket Transactions',
        location: `/payment/efmarket_transactions`,
      },
    ],
  },
  // Transportation
  {
    key: 'logistic',
    title: 'Logistics',
    icon: CarOutlined,
    items: [
      {
        key: 'locations',
        label: 'Locations',
        location: '/emarket/locations',
      },
      {
        key: 'packagingboxes',
        label: 'Packaging Boxes',
        location: '/emarket/packaging_boxes',
      },
      {
        key: 'shippingprices',
        label: 'Shipping Prices',
        location: '/emarket/shipping_prices',
      },
      {
        key: 'shipments',
        label: 'Requests',
        location: '/emarket/shipments?status=1000',
      },
      {
        key: 'shipments_completed',
        label: 'Completed',
        location: '/emarket/shipments?status=1001',
      },
      {
        key: 'shipping_agent_transactions',
        label: 'Shipping Agent Transactions',
        location: '/payment/shipping_agent_transactions',
      },
    ],
  },
  // Billings
  {
    key: 'billings',
    title: 'Billings',
    icon: CreditCardOutlined,
    items: [
      {
        key: 'phone',
        label: 'Phone Bills',
        location: '/billings/phone',
      },
      {
        key: 'utility',
        label: 'Utility Bills',
        location: '/billings/utility',
      },
      {
        key: 'providers',
        label: 'Providers',
        location: '/billings/providers',
      },
    ],
  },
];
