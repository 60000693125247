import { Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { flow, map, filter } from 'lodash/fp';
import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useReactToPrint } from 'react-to-print';

import {
  fetchShippingRequestsAC,
  shippingRequestsDataSelector,
  fetchShippingRequestsErrorSelector,
  isFetchingShippingRequestsSelector,
  totalShippingRequestsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/shippingRequests/list';
import { formatDateTime } from '../../utils/formatDate';
import CompleteButton from './CompleteButton';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import { ShippingStatus } from '../shared/Status';

const statusLabels = {
  1000: 'pending',
  1001: 'completed',
  1002: 'cancelled',
  1003: 'picking',
  1004: 'picked',
  1005: 'delivering',
  1006: 'delivered',
  1007: 'draft',
};

const columnsGenerator = () => [
  // {
  // title: 'Invoice',
  // dataIndex: 'order',
  // render: order => (order ? `INV ${order.invoice_number}` : '-'),
  // },
  {
    title: 'Created at',
    dataIndex: 'created',
    render: value => formatDateTime(value),
  },
  {
    title: 'Invoice ID',
    dataIndex: 'invoice_number',
    render: (value, record) => (value ? `#${value}` : `#${record.id}`),
  },
  {
    title: 'From',
    dataIndex: 'pick_up_location_name',
    render: (value, record) => (
      <div>
        <span>{value}</span>
        <br />
        <span>{record.sender_address}</span>
        <br />
        <strong>{record.sender_phone_number}</strong>
        <br />
        <span>{record.sender_name}</span>
      </div>
    ),
  },
  {
    title: 'To',
    dataIndex: 'drop_off_location_name',
    render: (value, record) => (
      <div>
        <span>{value}</span>
        <br />
        <span>{record.receiver_address}</span>
        <br />
        <strong>{record.receiver_phone_number}</strong>
        <br />
        <span>{record.receiver_name}</span>
      </div>
    ),
  },
  {
    title: 'Packaging',
    dataIndex: 'shipping_boxes',
    render: boxes => (
      <div>
        {flow(
          filter(box => box.box_quantity > 0),
          map(box => (
            <>
              <span>{`${box.box_quantity} x ${box.box.title}`}</span>
              <br />
            </>
          ))
        )(boxes)}
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: value => {
      return <ShippingStatus status={statusLabels[value]} />;
    },
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (id, record) => (
      <Button.Group>
        <Button>
          <Link to={`/emarket/shipments/${id}`}>Detail</Link>
        </Button>
        <br />
        {record.status === 1000 ? <CompleteButton id={id} /> : null}
      </Button.Group>
    ),
  },
];

const ShippingRequests = ({
  fetchShippingRequests,
  shippingRequests,
  history,
  error,
  loading,
  ...others
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchShippingRequests(params);
    // eslint-disable-next-line
  }, [location, fetchShippingRequests]);

  const status = params.status === '1001' ? 'Completed' : 'Pending';

  return (
    <>
      <PageTitle content={`${status} Shipping Requests`} />
      <div className="dashboard-page__actions">
        <div className="dashboard-page__actions__buttons text-right">
          <button className="button button--primary" onClick={handlePrint}>
            Print
          </button>
        </div>
      </div>
      {error && <ErrorMessage />}
      <div ref={componentRef}>
        <DataTable
          dataSource={shippingRequests}
          dataFetcher={fetchShippingRequests}
          filters={params}
          loading={loading}
          {...others}
        />
      </div>
    </>
  );
};

const connectToRedux = connect(
  state => ({
    shippingRequests: shippingRequestsDataSelector(state),
    loading: isFetchingShippingRequestsSelector(state),
    error: fetchShippingRequestsErrorSelector(state),
    totalItems: totalShippingRequestsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
    columns: columnsGenerator(),
  }),
  {
    fetchShippingRequests: fetchShippingRequestsAC,
  }
);

export { columnsGenerator };
export default connectToRedux(ShippingRequests);
