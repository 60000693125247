import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_CREDIT_REQUEST_API_ID = 'credit/REJECT_CREDIT_REQUEST';

const {
  actionCreator: rejectCreditRequestAC,
  dataSelector: rejectCreditRequestDataSelector,
  errorSelector: rejectCreditRequestErrorSelector,
  isFetchingSelector: isRejectingCreditRequestSelector,
} = makeFetchAction(REJECT_CREDIT_REQUEST_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/credits/${id}/reject`,
  method: 'POST',
  notify: true,
}));

export {
  REJECT_CREDIT_REQUEST_API_ID,
  rejectCreditRequestAC,
  rejectCreditRequestDataSelector,
  rejectCreditRequestErrorSelector,
  isRejectingCreditRequestSelector,
};
