import React from 'react';
import { removeFromLocalStorage } from '../../../LocalStorage';

const ErrorMessage = props => {
  const { errors, history } = props;

  if (!errors) {
    return (
      <div id="content">
        <div className="container">Something went wrong. Please try again.</div>
      </div>
    );
  }

  const { error } = errors;

  // TODO: fix hack for removing token
  if (error && error.includes('Access token')) {
    removeFromLocalStorage('adminSession');
    // eslint-disable-next-line
    history.push('/');
  }

  return (
    <div id="content">
      <div className="container">{error}</div>
    </div>
  );
};

export default ErrorMessage;
