import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DELETE_FEATURE_API_ID = 'DELETE_FEATURE';

const {
  actionCreator: deleteFeatureAC,
  errorSelector: deleteFeatureErrorSelector,
  isFetchingSelector: isDeletingFeatureSelector,
} = makeFetchAction(DELETE_FEATURE_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/features/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_FEATURE_API_ID,
  deleteFeatureAC,
  deleteFeatureErrorSelector,
  isDeletingFeatureSelector,
};
