import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_PASSWORD_API_ID = 'UPDATE_PASSWORD';

const {
  actionCreator: updatePasswordAC,
  dataSelector: updatePasswordDataSelector,
  errorSelector: updatePasswordErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_PASSWORD_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/users/${id}/password`,
  method: 'PUT',
  notify: true,
  body: data,
}));

export {
  UPDATE_PASSWORD_API_ID,
  updatePasswordAC,
  updatePasswordDataSelector,
  updatePasswordErrorSelector,
  isUpdatingSelector,
};
