import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_FEATURES_API_ID = 'FETCH_FEATURES';

const {
  actionCreator: fetchFeaturesAC,
  dataSelector: featuresRawSelector,
  errorSelector: fetchFeaturesErrorSelector,
  isFetchingSelector: isFetchingFeaturesSelector,
  updater: updateFeaturesAC,
} = makeFetchAction(
  FETCH_FEATURES_API_ID,
  (params = { page: 1, per_page: 25 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/features`,
      params,
    }),
    method: 'GET',
  })
);

const totalFeaturesSelector = createSelector(
  featuresRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  featuresRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  featuresRawSelector,
  get('paging.per_page')
);

const featuresDataSelector = createSelector(
  featuresRawSelector,
  get('results')
);

export {
  FETCH_FEATURES_API_ID,
  fetchFeaturesAC,
  featuresDataSelector,
  fetchFeaturesErrorSelector,
  isFetchingFeaturesSelector,
  updateFeaturesAC,
  currentPageSelector,
  pageSizeSelector,
  totalFeaturesSelector,
};
