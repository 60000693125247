import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_USER_API_ID } from '../../apiCalls/adminUsers/create';
import routerHistory from '../../routerHistory';

const handleCreateUserSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_USER_API_ID))),
    map(() => {
      routerHistory.push(`/iam/users`);
    })
  );

export default combineEpics(handleCreateUserSuccess);
