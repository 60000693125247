import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  withProps,
} from 'recompose';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  fetchProvidersAC,
  providersDataSelector,
  fetchProvidersErrorSelector,
  isFetchingProvidersSelector,
  totalItemSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/adminProviders/list';
import { enableProviderAC } from '../../apiCalls/adminProviders/enable';
import { disableProviderAC } from '../../apiCalls/adminProviders/disable';
import ErrorMessage from '../shared/ErrorMessage';
import DataTable from '../shared/DataTable';
import PageTitle from '../shared/PageTitle';

const columnsGenerator = ({ enableProvider, disableProvider }) => [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Logo',
    dataIndex: ['logo', 'origin_url'],
    render: logo => logo && <img src={logo} alt="logo" height="60px" />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Enaled',
    dataIndex: 'enabled',
    render: value => value && <CheckCircleOutlined />,
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (text, record) =>
      record.enabled ? (
        <Button type="danger" onClick={() => disableProvider(record.id)}>
          Disable
        </Button>
      ) : (
        <Button type="primary" onClick={() => enableProvider(record.id)}>
          Enable
        </Button>
      ),
  },
];

const Providers = ({
  fetchProviders,
  providers,
  history,
  columns,
  ...others
}) => (
  <div className="">
    <PageTitle content="Providers" />
    <div className="">
      <div className="text-right">
        <button
          className="button button--primary"
          onClick={() => history.push(`/billings/providers/add`)}
        >
          Add New Provider &nbsp;
          <i className="fas fa-plus" />
        </button>
      </div>
    </div>
    <DataTable
      dataSource={providers}
      dataFetcher={fetchProviders}
      columns={columns}
      {...others}
    />
  </div>
);

const enhance = compose(
  connect(
    state => ({
      providers: providersDataSelector(state),
      loading: isFetchingProvidersSelector(state),
      error: fetchProvidersErrorSelector(state),
      totalItems: totalItemSelector(state),
      currentPage: currentPageSelector(state),
      pageSize: pageSizeSelector(state),
    }),
    {
      fetchProviders: fetchProvidersAC,
      enableProvider: id => enableProviderAC(id),
      disableProvider: id => disableProviderAC(id),
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      this.props.fetchProviders();
    },
  }),
  withProps(({ enableProvider, disableProvider }) => ({
    columns: columnsGenerator({ enableProvider, disableProvider }),
  })),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(Providers);
