import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import inverted_logo from '../../images/inverted_logo.png';

const Logo = ({ inverted = false, ...rest }) => (
  <Link to="/">
    <img {...rest} src={inverted ? inverted_logo : logo} alt="logo" />
  </Link>
);

export default Logo;
