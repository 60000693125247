import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const APPROVE_CREDIT_REQUEST_API_ID = 'credit/APPROVE_CREDIT_REQUEST';

const {
  actionCreator: approveCreditRequestAC,
  dataSelector: approveCreditRequestDataSelector,
  errorSelector: approveCreditRequestErrorSelector,
  isFetchingSelector: isApprovingCreditRequestSelector,
} = makeFetchAction(APPROVE_CREDIT_REQUEST_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/credits/${id}/approve`,
  method: 'POST',
  notify: true,
}));

export {
  APPROVE_CREDIT_REQUEST_API_ID,
  approveCreditRequestAC,
  approveCreditRequestDataSelector,
  approveCreditRequestErrorSelector,
  isApprovingCreditRequestSelector,
};
