import { Link } from 'react-router-dom';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { deletePackagingBoxAC } from '../../apiCalls/packagingBoxes/delete';
import {
  fetchPackagingBoxAC,
  packagingBoxDataSelector,
  isFetchingPackagingBoxSelector,
} from '../../apiCalls/packagingBoxes/get';
import {
  updatePackagingBoxAC,
  isUpdatingSelector,
} from '../../apiCalls/packagingBoxes/update';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import PackagingBoxForm from './Form';
import PageTitle from '../shared/PageTitle';

const AdminEditPackagingBox = ({
  match,
  handleSubmit,
  submitting,
  formError,
  packagingBox,
  deletePackagingBox,
}) => (
  <>
    <PageTitle content="Packaging Boxes" />
    <div className="breadcrumb">
      <ul>
        <li>
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/emarket/packaging_boxes">Packaging Boxes</Link>
        </li>
        <li>Edit packaging box: {match.params.id}</li>
      </ul>
    </div>

    <div className="">
      <div className="">
        <div className="row align-items-center">
          <div className="error">{formError}</div>
        </div>
        {packagingBox && (
          <PackagingBoxForm
            onFinish={handleSubmit}
            deletePackagingBox={deletePackagingBox}
            initialValues={packagingBox}
            submitting={submitting}
          />
        )}
      </div>
    </div>
  </>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    packagingBox: packagingBoxDataSelector(state),
    isFetching: isFetchingPackagingBoxSelector(state),
  }),
  {
    updatePackagingBox: updatePackagingBoxAC,
    fetchPackagingBox: fetchPackagingBoxAC,
    deletePackagingBox: deletePackagingBoxAC,
  }
);

const prepareProps = mapProps(
  ({ updatePackagingBox, updatePackagingBoxError, ...others }) => ({
    formError: updatePackagingBoxError,
    handleSubmit: packagingBox => {
      const packagingBox_id = others.match.params.id;
      return updatePackagingBox(packagingBox_id, packagingBox);
    },
    ...others,
  })
);

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchPackagingBox, match } = this.props;
      const { id } = match.params;
      fetchPackagingBox(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminEditPackagingBox);
