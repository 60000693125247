import { Link, useLocation } from 'react-router-dom';
import { Tag, Button } from 'antd';
import { capitalize, debounce } from 'lodash/fp';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import queryString from 'query-string';

import {
  fetchAdminUsersAC,
  adminUsersDataSelector,
  fetchAdminUsersErrorSelector,
  isFetchingAdminUsersSelector,
  totalUsersSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/adminUsers/list';
import { userStatusColors } from '../../utils/constants';
import DataTable from '../shared/DataTable';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  // {
  //   title: '',
  //   dataIndex: 'avatar',
  //   render: value =>
  //     value &&
  //     value.thumb_url && <img alt="" width={100} src={value.thumb_url} />,
  // },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  // {
  //   title: 'Email',
  //   dataIndex: 'email',
  // },
  // {
  //   title: 'Person in charge',
  //   dataIndex: 'person_in_charge',
  // },
  // {
  //   title: 'Roles',
  //   dataIndex: 'roles',
  //   render: roles =>
  //     map(role => (
  //       <Tag key={role} color="geekblue" style={{ margin: 2 }}>{role}</Tag>
  //     ))(roles),
  // },
  {
    title: 'Phone',
    dataIndex: 'contact_number',
    render: (contact_number, user) =>
      `+(${user.country_code}) ${contact_number}`,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => <Tag color={userStatusColors[status]}>{status}</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => {
      const queryString = new URLSearchParams(window.location.search);

      return (
        <Button.Group>
          <Button>
            <Link to={`/iam/users/${record.id}?${queryString}`}>Detail</Link>
          </Button>

          <Button>
            <Link to={`/iam/users/${record.id}/edit?${queryString}`}>Edit</Link>
          </Button>
        </Button.Group>
      );
    },
  },
];

const AdminUsers = ({
  fetchAdminUsers,
  users,
  history,
  loading,
  error,
  ...others
}) => {
  const searchUsers = debounce(500, fetchAdminUsers);
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    fetchAdminUsers({ filter: params });
    // eslint-disable-next-line
  }, [location, fetchAdminUsers]);

  return (
    <>
      <PageTitle
        content={`${params.status ? capitalize(params.status) : ''} Users`}
      />
      <div className="row">
        <div className="col-sm-6">
          <div className="search-box">
            <input
              type="text"
              placeholder="Find users by name or contact number"
              onChange={e =>
                searchUsers({ filter: params, query: e.target.value })
              }
            />
            <button className="button button--primary" type="button">
              🔎
            </button>
          </div>
        </div>
      </div>
      {/* <div className="dashboard-page__actions">
        <div className="dashboard-page__actions__buttons text-right">
          <button className="button button--primary" onClick={() => history.push(`/iam/users/add`)}>
            Add New User &nbsp;<i className="fas fa-plus" />
          </button>
        </div>
      </div> */}
      {error && <ErrorMessage />}
      <DataTable
        dataSource={users}
        dataFetcher={fetchAdminUsers}
        loading={loading}
        filters={{ filter: params }}
        columns={columns}
        {...others}
      />
    </>
  );
};

const connectToRedux = connect(
  state => ({
    users: adminUsersDataSelector(state),
    loading: isFetchingAdminUsersSelector(state),
    error: fetchAdminUsersErrorSelector(state),
    totalItems: totalUsersSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchAdminUsers: fetchAdminUsersAC,
  }
);

export default connectToRedux(AdminUsers);
