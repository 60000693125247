import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateFeatureAC,
  isUpdatingSelector,
  updateFeatureErrorSelector,
} from '../../apiCalls/features/update';
import FeatureForm from './FeatureForm';
import {
  fetchFeatureAC,
  featureDataSelector,
  isFetchingFeatureSelector,
} from '../../apiCalls/features/get';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';

const AdminEditFeature = ({
  match,
  feature,
  submitting,
  fetchFeature,
  isFetching,
  errors,
  updateFeature,
}) => {
  React.useEffect(() => {
    fetchFeature(match.params.id);
  }, [fetchFeature, match.params.id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  if (!feature) {
    return null;
  }

  const handleSubmit = feature => {
    const user_id = match.params.id;
    return updateFeature(user_id, feature);
  };

  console.log('feature', feature);

  return (
    <div id="content">
      <div className="container">
        <div className="dashboard-page">
          <h1 className="page-title">Dashboard</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to="/emarket/features">Features</Link>
              </li>
              <li>Edit feature {match.params.id}</li>
            </ul>
          </div>

          <div className="">
            <div className="">
              <FeatureForm
                onFinish={handleSubmit}
                initialValues={feature}
                submitting={submitting}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingSelector(state),
    feature: featureDataSelector(state),
    isFetching: isFetchingFeatureSelector(state),
    errors: updateFeatureErrorSelector(state),
  }),
  {
    updateFeature: updateFeatureAC,
    fetchFeature: fetchFeatureAC,
  }
);

export default connectWithRedux(AdminEditFeature);
