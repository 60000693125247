import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_TRANSFERS_API_ID = 'FETCH_TRANSFERS';

const {
  actionCreator: fetchTransfersAC,
  dataSelector: transfersRawSelector,
  errorSelector: fetchTransfersErrorSelector,
  isFetchingSelector: isFetchingTransfersSelector,
  updater: updateTransfersAC,
} = makeFetchAction(FETCH_TRANSFERS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/revenue_transfers`,
    params,
  }),
  method: 'GET',
}));

const totalPaymentItemSelector = createSelector(
  transfersRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  transfersRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  transfersRawSelector,
  get('paging.per_page')
);

const transfersDataSelector = createSelector(
  transfersRawSelector,
  get('transfer_requests')
);

export {
  FETCH_TRANSFERS_API_ID,
  fetchTransfersAC,
  transfersRawSelector,
  totalPaymentItemSelector,
  fetchTransfersErrorSelector,
  isFetchingTransfersSelector,
  updateTransfersAC,
  currentPageSelector,
  pageSizeSelector,
  transfersDataSelector,
};
