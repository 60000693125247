import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const ENABLE_POST_API_ID = 'ENABLE_POST';

const {
  actionCreator: enablePostAC,
  dataSelector: enablePostDataSelector,
  errorSelector: enablePostErrorSelector,
  isFetchingSelector: isEnablePostSelector,
} = makeFetchAction(ENABLE_POST_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/posts/${id}/enable`,
  method: 'POST',
  notify: true,
}));

export {
  ENABLE_POST_API_ID,
  enablePostAC,
  enablePostDataSelector,
  enablePostErrorSelector,
  isEnablePostSelector,
};
