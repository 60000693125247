import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_WITHDRAWALS_API_ID = 'FETCH_ADMIN_WITHDRAWALS';

const {
  actionCreator: fetchAdminWithdrawalsAC,
  dataSelector: withdrawalsRawSelector,
  errorSelector: fetchAdminWithdrawalsErrorSelector,
  isFetchingSelector: isFetchingAdminWithdrawalsSelector,
  updater: updateAdminWithdrawalsAC,
} = makeFetchAction(FETCH_ADMIN_WITHDRAWALS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/withdrawals`,
    params,
  }),
  method: 'GET',
}));

const totalWithdrawalSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.per_page')
);

const adminWithdrawalsDataSelector = createSelector(
  withdrawalsRawSelector,
  get('bank_records')
);

export {
  FETCH_ADMIN_WITHDRAWALS_API_ID,
  fetchAdminWithdrawalsAC,
  adminWithdrawalsDataSelector,
  fetchAdminWithdrawalsErrorSelector,
  isFetchingAdminWithdrawalsSelector,
  updateAdminWithdrawalsAC,
  currentPageSelector,
  pageSizeSelector,
  totalWithdrawalSelector,
};
