import { ACTIONS } from 'redux-api-call';
import { combineEpics, ofType } from 'redux-observable';
import { eq, flow, get } from 'lodash/fp';
import { filter, mapTo, tap } from 'rxjs/operators';

import { openNotificationWithIcon } from './notificationEpics';
import { removeFromLocalStorage } from '../LocalStorage';
import { resetAdminAuthTokenAC } from '../state/adminSession/state';

const is401Status = flow(get('payload.statusCode'), eq(401));

const handle401Error = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.FAILURE),
    filter(is401Status),
    tap(() => {
      removeFromLocalStorage('adminSession');
      openNotificationWithIcon({
        type: 'error',
        message: 'Session expired!',
        description: 'Please login again to continue',
      });
    }),
    mapTo(resetAdminAuthTokenAC())
  );

export default combineEpics(handle401Error);
