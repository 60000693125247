import { makeFetchAction } from 'redux-api-call';

import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const DELETE_PACKAGING_BOX_API_ID = 'DELETE_PACKAGING_BOX';

const {
  actionCreator: deletePackagingBoxAC,
  dataSelector: deletePackagingBoxDataSelector,
  errorSelector: deletePackagingBoxErrorSelector,
  isFetchingSelector: isDeletingPackagingBoxSelector,
} = makeFetchAction(DELETE_PACKAGING_BOX_API_ID, id => ({
  endpoint: `${EMARKET_ADMIN_API}/packagingboxes/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_PACKAGING_BOX_API_ID,
  deletePackagingBoxAC,
  deletePackagingBoxDataSelector,
  deletePackagingBoxErrorSelector,
  isDeletingPackagingBoxSelector,
};
