import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const DISABLE_PROVIDER_API_ID = 'DISABLE_PROVIDER';

const {
  actionCreator: disableProviderAC,
  dataSelector: disableProviderDataSelector,
  errorSelector: disableProviderErrorSelector,
  isFetchingSelector: isDisableProviderSelector,
} = makeFetchAction(DISABLE_PROVIDER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/providers/${id}/disable`,
  method: 'POST',
  notify: true,
}));

export {
  DISABLE_PROVIDER_API_ID,
  disableProviderAC,
  disableProviderDataSelector,
  disableProviderErrorSelector,
  isDisableProviderSelector,
};
