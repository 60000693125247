import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_ADMIN_TOP_UPS_API_ID = 'FETCH_ADMIN_TOP_UPS';

const {
  actionCreator: fetchAdminTopupsAC,
  dataSelector: withdrawalsRawSelector,
  errorSelector: fetchAdminTopupsErrorSelector,
  isFetchingSelector: isFetchingAdminTopupsSelector,
  updater: updateAdminTopupsAC,
} = makeFetchAction(FETCH_ADMIN_TOP_UPS_API_ID, (params = { page: 1 }) => ({
  endpoint: generateEndpoint({
    host: `${API_ADMIN_ENDPOINT}/topups`,
    params,
  }),
  method: 'GET',
}));

const totalTopupSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  withdrawalsRawSelector,
  get('paging.per_page')
);

const adminTopupsDataSelector = createSelector(
  withdrawalsRawSelector,
  get('bank_records')
);

export {
  FETCH_ADMIN_TOP_UPS_API_ID,
  fetchAdminTopupsAC,
  adminTopupsDataSelector,
  fetchAdminTopupsErrorSelector,
  isFetchingAdminTopupsSelector,
  updateAdminTopupsAC,
  currentPageSelector,
  pageSizeSelector,
  totalTopupSelector,
};
