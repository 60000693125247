import { createAction, handleActions } from 'redux-actions';
import { get } from 'lodash/fp';

const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
const RESET_AUTH_TOKEN = 'RESET_AUTH_TOKEN';

const setAuthTokenAC = createAction(SET_AUTH_TOKEN);
const resetAuthTokenAC = createAction(RESET_AUTH_TOKEN);

const authTokenSelector = get('session.access_token');
const userSelector = get('session.user');

const authTokenReducer = handleActions(
  {
    [SET_AUTH_TOKEN]: (state, { payload }) => payload,
    [RESET_AUTH_TOKEN]: () => null,
  },
  null
);

export { setAuthTokenAC, resetAuthTokenAC, authTokenSelector, userSelector };

export default {
  session: authTokenReducer,
};
