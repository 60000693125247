import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  mapProps,
} from 'recompose';
import { get, map } from 'lodash/fp';
import { Descriptions, Tag } from 'antd';
import {
  fetchQuotationAC,
  quotationDataSelector,
  isFetchingQuotationSelector,
} from '../../apiCalls/quotations/get';
import {
  fetchShipmentsAC,
  shipmentsDataSelector,
  fetchShipmentsErrorSelector,
  isFetchingShipmentsSelector,
  totalShipmentsSelector,
  currentPageSelector,
  pageSizeSelector,
} from '../../apiCalls/shipments/list';
import { columnsGenerator } from '../Shipment/index';
import LoadingSpinner from '../shared/LoadingSpinner';
import ErrorMessage from '../shared/ErrorMessage';
import PageTitle from '../shared/PageTitle';
import { formatDate, timeAgo } from '../../utils/formatDate';
import DataTable from '../shared/DataTable';
import {
  postTypesConfig,
  TAG_COLOR,
  quoteStatusColors,
  quoteStatusConfig,
  sizeUnitConfig,
  unitColors,
  shippingMethodConfig,
  METHOD_DOOR_TO_DOOR,
} from '../../utils/constants';

const AdminQuoteDetail = ({
  match,
  quotation,
  shipments,
  fetchShipments,
  columns,
  others,
}) => {
  const { search } = useLocation();
  const quotationsQuery = new URLSearchParams(search);

  return (
    <div className="">
      <PageTitle content="Quotations" />
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to={`/emarket/quotations?${quotationsQuery}`}>
              Quotations
            </Link>
          </li>
          <li>View quotation detail: {match.params.id}</li>
        </ul>
      </div>

      <div className="">
        {quotation && (
          <Descriptions
            title="Quotation details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{quotation.id}</Descriptions.Item>
            <Descriptions.Item label="Post Type">
              <Tag color={TAG_COLOR[quotation.quote_type]}>
                {postTypesConfig[quotation.quote_type]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Product">
              {quotation.product && quotation.product.name}
            </Descriptions.Item>
            <Descriptions.Item label="Created by">
              {quotation.created_by && quotation.created_by.name}
            </Descriptions.Item>
            <Descriptions.Item label="Size unit">
              <Tag color={unitColors[quotation.size_unit]}>
                {sizeUnitConfig[quotation.size_unit]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">
              {quotation.quantity}
            </Descriptions.Item>
            <Descriptions.Item label="Size">{quotation.size}</Descriptions.Item>
            <Descriptions.Item label="Price">
              RM{quotation.price}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Method">
              {shippingMethodConfig[quotation.shipping_method]}
            </Descriptions.Item>
            <Descriptions.Item label="Shipping Amount">
              RM{quotation.shipping_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount">
              RM{quotation.total_amount}
            </Descriptions.Item>
            <Descriptions.Item label="Post">
              {quotation.post && (
                <Link to={`/emarket/posts/${quotation.post.id}`}>
                  View Post
                </Link>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              <Tag color={quoteStatusColors[quotation.status]}>
                {quoteStatusConfig[quotation.status]}
              </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              {quotation.description}
            </Descriptions.Item>
            <Descriptions.Item label="Features">
              {map(feature => (
                <Tag key={feature.id} color="geekblue" style={{ margin: 2 }}>
                  {feature.name}
                </Tag>
              ))(quotation.feature_data)}
            </Descriptions.Item>
            <Descriptions.Item label="Featured Image">
              {quotation.post && quotation.post.image && (
                <img src={quotation.post.image} alt={quotation.product.name} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Created" span={2}>
              {formatDate(quotation.created)}
            </Descriptions.Item>
            <Descriptions.Item label="Last updated" span={2}>
              {timeAgo(quotation.modified)}
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
      <hr />
      <div className="">
        <div className="ant-descriptions-title">Shipments</div>
        {quotation && quotation.shipping_method === METHOD_DOOR_TO_DOOR ? (
          <DataTable
            dataSource={shipments}
            dataFetcher={fetchShipments}
            columns={columns}
            {...others}
          />
        ) : (
          'Self pickup'
        )}
      </div>
    </div>
  );
};

const connectWithRedux = connect(
  state => ({
    quotation: quotationDataSelector(state),
    isFetching: isFetchingQuotationSelector(state),
    shipments: shipmentsDataSelector(state),
    isShipmentsFetching: isFetchingShipmentsSelector(state),
    error: fetchShipmentsErrorSelector(state),
    totalItems: totalShipmentsSelector(state),
    currentPage: currentPageSelector(state),
    pageSize: pageSizeSelector(state),
  }),
  {
    fetchQuotation: fetchQuotationAC,
    fetchShipments: fetchShipmentsAC,
  }
);

const prepareProps = mapProps(({ ...others }) => ({
  columns: columnsGenerator(),
  ...others,
}));

const enhance = compose(
  connectWithRedux,
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { fetchQuotation, fetchShipments, match } = this.props;
      const { id, page = 1 } = match.params;
      fetchQuotation(id);
      fetchShipments({ page, quotation: id });
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage)),
  prepareProps
);

export default enhance(AdminQuoteDetail);
