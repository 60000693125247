import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const REJECT_TOPUP_API_ID = 'REJECT_TOPUP';

const {
  actionCreator: rejectTopupAC,
  dataSelector: rejectTopupDataSelector,
  errorSelector: rejectTopupErrorSelector,
  isFetchingSelector: isRejectingTopupSelector,
} = makeFetchAction(REJECT_TOPUP_API_ID, (id, data) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/topups/${id}/reject`,
  method: 'POST',
  body: data,
  notify: true,
}));

export {
  REJECT_TOPUP_API_ID,
  rejectTopupAC,
  rejectTopupDataSelector,
  rejectTopupErrorSelector,
  isRejectingTopupSelector,
};
