import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_POSTS_API_ID = 'FETCH_POSTS';

const {
  actionCreator: fetchPostsAC,
  dataSelector: postsRawSelector,
  errorSelector: fetchPostsErrorSelector,
  isFetchingSelector: isFetchingPostsSelector,
  updater: updatePostsAC,
} = makeFetchAction(FETCH_POSTS_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${EMARKET_ADMIN_API}/posts`,
    params,
  }),
  method: 'GET',
}));

const totalPostsSelector = createSelector(
  postsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  postsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  postsRawSelector,
  get('paging.per_page')
);

const postsDataSelector = createSelector(
  postsRawSelector,
  get('results')
);

export {
  FETCH_POSTS_API_ID,
  fetchPostsAC,
  postsDataSelector,
  fetchPostsErrorSelector,
  isFetchingPostsSelector,
  updatePostsAC,
  currentPageSelector,
  pageSizeSelector,
  totalPostsSelector,
};
