import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { EMARKET_ADMIN_API } from 'utils/envConfig';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_ORDER_ITEMS_API_ID = 'ECOM_FETCH_ORDER_ITEMS';

const {
  actionCreator: fetchOrderItemsAC,
  dataSelector: orderItemsRawSelector,
  errorSelector: fetchOrderItemsErrorSelector,
  isFetchingSelector: isFetchingOrderItemsSelector,
  updater: updateOrderItemsAC,
} = makeFetchAction(
  FETCH_ORDER_ITEMS_API_ID,
  (orderId, params = { page: 1 }) => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/ecomorders/${orderId}/ecomorderitems`,
      params,
    }),
    method: 'GET',
  })
);

const totalOrderItemsSelector = createSelector(
  orderItemsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  orderItemsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  orderItemsRawSelector,
  get('paging.per_page')
);

const orderItemsDataSelector = createSelector(
  orderItemsRawSelector,
  get('results')
);

export {
  FETCH_ORDER_ITEMS_API_ID,
  fetchOrderItemsAC,
  orderItemsDataSelector,
  fetchOrderItemsErrorSelector,
  isFetchingOrderItemsSelector,
  updateOrderItemsAC,
  currentPageSelector,
  pageSizeSelector,
  totalOrderItemsSelector,
};
