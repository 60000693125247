import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const ENABLE_PROVIDER_API_ID = 'ENABLE_PROVIDER';

const {
  actionCreator: enableProviderAC,
  dataSelector: enableProviderDataSelector,
  errorSelector: enableProviderErrorSelector,
  isFetchingSelector: isEnableProviderSelector,
} = makeFetchAction(ENABLE_PROVIDER_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/providers/${id}/enable`,
  method: 'POST',
  notify: true,
}));

export {
  ENABLE_PROVIDER_API_ID,
  enableProviderAC,
  enableProviderDataSelector,
  enableProviderErrorSelector,
  isEnableProviderSelector,
};
