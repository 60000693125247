import { combineEpics } from 'redux-observable';

import adminActivateStaffSuccessEpic from './adminActivateStaffSuccessEpic';
import adminCreateStaffSuccessEpic from './adminCreateStaffSuccessEpic';
import adminDeactivateStaffSuccessEpic from './adminDeactivateStaffSuccessEpic';
import adminDeleteStaffSuccessEpic from './adminDeleteStaffSuccessEpic';
import adminEditStaffSuccessEpic from './adminEditStaffSuccessEpic';

export default combineEpics(
  adminActivateStaffSuccessEpic,
  adminCreateStaffSuccessEpic,
  adminDeactivateStaffSuccessEpic,
  adminDeleteStaffSuccessEpic,
  adminEditStaffSuccessEpic
);
