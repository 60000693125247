import { eq, flow, get } from 'lodash/fp';
import { filter, map } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { ACTIONS } from 'redux-api-call';
import { CREATE_PRICE_API_ID } from '../apiCalls/locationPrices/create';
import { UPDATE_PRICE_API_ID } from '../apiCalls/locationPrices/update';
import { DELETE_PRICE_API_ID } from '../apiCalls/locationPrices/delete';
import routerHistory from '../routerHistory';

const handleCreatePriceSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(CREATE_PRICE_API_ID))),
    map(() => {
      routerHistory.push('/emarket/routes-and-pricing');
    })
  );

const handleEditPriceSuccess = actions$ =>
  actions$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(UPDATE_PRICE_API_ID))),
    map(() => {
      routerHistory.push('/emarket/routes-and-pricing');
    })
  );

const refreshListViewOnDelete = action$ =>
  action$.pipe(
    ofType(ACTIONS.COMPLETE),
    filter(flow(get('payload.name'), eq(DELETE_PRICE_API_ID))),
    map(() => {
      routerHistory.push('/emarket/routes-and-pricing');
    })
  );

export default combineEpics(
  handleCreatePriceSuccess,
  handleEditPriceSuccess,
  refreshListViewOnDelete
);
