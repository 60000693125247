import { makeFetchAction } from 'redux-api-call';
import { get } from 'lodash/fp';
import { createSelector } from 'reselect';

import { EMARKET_ADMIN_API } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_SHIPPING_REQUEST_PAYMENTS_API_ID =
  'FETCH_SHIPPING_REQUEST_PAYMENTS';

const {
  actionCreator: fetchShippingRequestPaymentsAC,
  dataSelector: shippingRequestPaymentsRawSelector,
  errorSelector: fetchShippingRequestPaymentsErrorSelector,
  isFetchingSelector: isFetchingShippingRequestPaymentsSelector,
  updater: updateShippingRequestPaymentsAC,
} = makeFetchAction(
  FETCH_SHIPPING_REQUEST_PAYMENTS_API_ID,
  shippingRequestId => ({
    endpoint: generateEndpoint({
      host: `${EMARKET_ADMIN_API}/shippingrequests/${shippingRequestId}/payments`,
    }),
    method: 'GET',
  })
);

const shippingRequestPaymentsDataSelector = createSelector(
  shippingRequestPaymentsRawSelector,
  get('results')
);

export {
  FETCH_SHIPPING_REQUEST_PAYMENTS_API_ID,
  fetchShippingRequestPaymentsAC,
  shippingRequestPaymentsDataSelector,
  fetchShippingRequestPaymentsErrorSelector,
  isFetchingShippingRequestPaymentsSelector,
  updateShippingRequestPaymentsAC,
};
