import { Button, Descriptions, Form, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { FORM_ITEM_LAYOUT } from '../shared/form/config';
import {
  approveWithdrawalAC,
  approveWithdrawalErrorSelector,
  isApprovingWithdrawalSelector,
} from '../../apiCalls/adminWithdrawals/approve';
import { convertFormData } from '../../utils/data';
import {
  fetchAdminWithdrawalAC,
  adminWithdrawalDataSelector,
  fetchAdminWithdrawalErrorSelector,
  isFetchingAdminWithdrawalSelector,
} from '../../apiCalls/adminWithdrawals/get';
import {
  rejectWithdrawalAC,
  rejectWithdrawalErrorSelector,
  isRejectingWithdrawalSelector,
} from '../../apiCalls/adminWithdrawals/reject';
import ActionButton from '../shared/ActionButton';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';
import Status from '../shared/Status';
import TextField from '../shared/form/TextField';
import Uploader from '../shared/form/Uploader';
import formatDate from '../../utils/formatDate';

const Content = ({
  withdrawalRequest,
  isApproving,
  isRejecting,
  match,
  approveWithdrawal,
  rejectWithdrawal,
}) => {
  const { search } = useLocation();
  const requestsQuery = new URLSearchParams(search);
  const [invoice, setInvoice] = React.useState(null);
  const [adminNote, setAdminNote] = React.useState('');

  const data = invoice
    ? convertFormData({ invoice_image: invoice, admin_note: adminNote })
    : { admin_note: adminNote };

  return (
    <div id="content">
      <div className="container">
        <div className="dashboard-page">
          <h1 className="page-title">Withdrawal Records</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li>
                <Link to={`/payment/withdrawals?${requestsQuery}`}>
                  Withdrawal Records
                </Link>
              </li>
              <li>#{match.params.id}</li>
            </ul>
          </div>
          <Descriptions
            title="Withdrawal Record details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">
              {withdrawalRequest.id}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              {formatDate(withdrawalRequest.created_at)}
            </Descriptions.Item>
            <Descriptions.Item label="User">
              {withdrawalRequest.user.name}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">
              {withdrawalRequest.user.contact_number}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Name">
              {withdrawalRequest.user.bank_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Name">
              {withdrawalRequest.user.bank_account_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Number">
              {withdrawalRequest.user.bank_account_number}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              RM{withdrawalRequest.amount}
            </Descriptions.Item>
            <Descriptions.Item label="Note">
              {withdrawalRequest.description}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              <Status status={withdrawalRequest.status} />
            </Descriptions.Item>
            <Descriptions.Item label="Admin Note">
              {withdrawalRequest.admin_note}
            </Descriptions.Item>
            <Descriptions.Item label="Receipt">
              {withdrawalRequest.invoice &&
              withdrawalRequest.invoice.origin_url ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={withdrawalRequest.invoice.origin_url}
                >
                  <img
                    height={500}
                    src={withdrawalRequest.invoice.origin_url}
                    alt="invoice"
                  />
                </a>
              ) : (
                'N/A'
              )}
            </Descriptions.Item>
          </Descriptions>
          {withdrawalRequest.status === 'pending' && (
            <>
              <hr />
              <Form labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                <Uploader
                  name="invoice_image"
                  type="text"
                  label="Receipt"
                  input={{
                    value: invoice,
                    onChange: file => {
                      setInvoice(file);
                    },
                  }}
                  {...FORM_ITEM_LAYOUT}
                />
                <TextField
                  name="admin_note"
                  multiline
                  type="text"
                  label="Admin Notes"
                  input={{
                    value: adminNote,
                    onChange: e => setAdminNote(e.target.value),
                  }}
                  {...FORM_ITEM_LAYOUT}
                />
              </Form>
              <div className="text-right">
                <Button.Group>
                  <ActionButton
                    action={() => rejectWithdrawal(match.params.id)}
                    loading={isRejecting}
                    disabled={isRejecting}
                    className="button button--danger"
                    text="Reject"
                  />
                  <ActionButton
                    action={() => {
                      if (invoice !== null) {
                        approveWithdrawal(match.params.id, data);
                      } else {
                        message.error('Please attach a receipt!');
                      }
                    }}
                    requireConfirmation={invoice !== null}
                    loading={isApproving}
                    disabled={isApproving}
                    className="button button--primary"
                    text="Approve"
                  />
                </Button.Group>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const AdminWithdrawalDetail = ({
  withdrawalRequestData,
  fetchAdminWithdrawalDetail,
  isFetching,
  errors,
  isApproving,
  isRejecting,
  match,
  approveWithdrawal,
  rejectWithdrawal,
}) => {
  const withdrawalRequest = get('bank_record')(withdrawalRequestData);

  React.useEffect(() => {
    fetchAdminWithdrawalDetail(match.params.id);
  }, [fetchAdminWithdrawalDetail, match.params.id]);

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (errors) {
    return <ErrorMessage />;
  }

  if (!withdrawalRequest) {
    return null;
  }

  return (
    <Content
      withdrawalRequest={withdrawalRequest}
      isApproving={isApproving}
      isRejecting={isRejecting}
      match={match}
      approveWithdrawal={approveWithdrawal}
      rejectWithdrawal={rejectWithdrawal}
    />
  );
};

const enhance = compose(
  connect(
    state => ({
      withdrawalRequestData: adminWithdrawalDataSelector(state),
      isFetching: isFetchingAdminWithdrawalSelector(state),
      isRejecting: isRejectingWithdrawalSelector(state),
      isApproving: isApprovingWithdrawalSelector(state),
      error: fetchAdminWithdrawalErrorSelector(state),
      approvedError: approveWithdrawalErrorSelector(state),
      rejectedError: rejectWithdrawalErrorSelector(state),
    }),
    {
      fetchAdminWithdrawalDetail: fetchAdminWithdrawalAC,
      approveWithdrawal: approveWithdrawalAC,
      rejectWithdrawal: rejectWithdrawalAC,
    }
  )
);

export default enhance(AdminWithdrawalDetail);
