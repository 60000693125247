export default [
  // Overview
  {
    key: 'dashboard',
    title: 'Dashboard',
    icon: 'transaction',
    items: [
      { key: 'overview', label: 'Overview', location: '/overview/stats' },
      {
        key: 'users_verified',
        label: 'Users (Verified)',
        location: '/iam/users?status=verified',
      },
      {
        key: 'users_unverified',
        label: 'Users (Unverified)',
        location: '/iam/users?status=unverify',
      },
      {
        key: 'users_deactivated',
        label: 'Users (Deactivated)',
        location: '/iam/users?status=inactive',
      },
      { key: 'reports', label: 'Growth Report', location: '/overview/reports' },
    ],
  },

  // Payment
  {
    key: 'efpay',
    title: 'EFPay',
    icon: 'transaction',
    items: [
      {
        key: 'withdrawals_pending',
        label: 'Withdrawal Request (Pending)',
        location: '/payment/withdrawals?status=pending',
      },
      {
        key: 'withdrawals_approved_rejected',
        label: 'Withdrawal (Approved and Rejected)',
        location: '/payment/withdrawals?status=approved',
      },
    ],
  },

  // Market
  {
    key: 'emarket',
    title: 'EMarket',
    icon: 'shopping-cart',
    items: [
      {
        key: 'orders_ongoing',
        label: 'Ongoing',
        location: '/emarket/orders?status=1000',
      },
      {
        key: 'products',
        label: 'Products',
        location: '/emarket/products',
      },
      {
        key: 'features',
        label: 'Features',
        location: '/emarket/features',
      },
    ],
  },
  // Logistics
  {
    key: 'logistic',
    title: 'Logistics',
    icon: 'shopping-cart',
    items: [
      {
        key: 'locations',
        label: 'Locations',
        location: '/emarket/locations',
      },
      {
        key: 'packagingboxes',
        label: 'Packaging Boxes',
        location: '/emarket/packaging_boxes',
      },
      {
        key: 'shippingprices',
        label: 'Shipping Prices',
        location: '/emarket/shipping_prices',
      },
      {
        key: 'shipments',
        label: 'Requests',
        location: '/emarket/shipments?status=1000',
      },
      {
        key: 'shipments_completed',
        label: 'Completed',
        location: '/emarket/shipments?status=1001',
      },
    ],
  },
];
