import { makeFetchAction } from 'redux-api-call';
import { EMARKET_ADMIN_API } from '../../utils/envConfig';

const UPDATE_PRICE_API_ID = 'UPDATE_PRICE';

const {
  actionCreator: updatePriceAC,
  dataSelector: updatePriceDataSelector,
  errorSelector: updatePriceErrorSelector,
  isFetchingSelector: isUpdatingSelector,
} = makeFetchAction(UPDATE_PRICE_API_ID, (id, price) => ({
  endpoint: `${EMARKET_ADMIN_API}/prices/${id}`,
  method: 'PATCH',
  notify: true,
  body: price,
}));

export {
  UPDATE_PRICE_API_ID,
  updatePriceAC,
  updatePriceDataSelector,
  updatePriceErrorSelector,
  isUpdatingSelector,
};
