import { makeFetchAction } from 'redux-api-call';

import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_ADMIN_TOPUP_API_ID = 'GET_ADMIN_TOPUP';

const {
  actionCreator: fetchAdminTopupAC,
  dataSelector: adminTopupDataSelector,
  errorSelector: fetchAdminTopupErrorSelector,
  isFetchingSelector: isFetchingAdminTopupSelector,
  updater: updateAdminTopupDataAC,
} = makeFetchAction(GET_ADMIN_TOPUP_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/topups/${id}`,
  method: 'GET',
}));

const updateAdminTopupAC = (topup_request, state) => {
  const adminTopup = adminTopupDataSelector(state);
  const data = {
    ...adminTopup,
    topup_request,
  };
  return updateAdminTopupDataAC(data);
};

export {
  GET_ADMIN_TOPUP_API_ID,
  fetchAdminTopupAC,
  fetchAdminTopupErrorSelector,
  isFetchingAdminTopupSelector,
  adminTopupDataSelector,
  updateAdminTopupAC,
};
